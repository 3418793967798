import React, {Component} from "react"
import UserService from '../services/user.service'
import NavBar from "./nav-bar.component";

export default class Home extends Component {
  
    render(){
        return (
          <>
          <div className="menu">
             <NavBar></NavBar>
             </div>
             <div className="content">
            
             </div>
            
           </>
         
        );
    }
}