import React, {Component, useState} from 'react'
import NavBar from '../nav-bar.component'
import { Form, Table, Button } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import mcService from '../../services/mc.service'
import PATIENT_STATUS from '../../constants/patient-status.constant'
import history from '../../history'
import { Redirect} from 'react-router-dom'


export default class PrintReceipt extends Component {
    constructor(props){
        super(props)
        this.state = {
            mcs: [],
        }

    } 

    componentDidMount() {
        this.setState({
            blocking: true
        })
        const mc = {
            printed: 0
        }
        mcService.getMcToPrint(mc).then((response) => {
            this.setState({
                mcs: response.data
            })
        })

    }

    handleDetails = (event) =>{
        var check = event.target.checked
        this.setState({
            details: check
        })
    }



    handleAction(event){
        this.setState({action :event.target.value});
    }
  

   edit = (e) => {
    const id = e.target.id
    this.setState({ redirect: `/mc/${id}`});
   }
   
    
    render(){
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
             <div className="menu">
                <NavBar></NavBar>
            </div>                   
            <div className="content" style={{marginTop:"100px"}}>
                <div className="formMc">
                <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            <tr>
                                <th style={{width:"20%"}}>Ic Number</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                   
                    { 
                        this.state.mcs &&
                        this.state.mcs.map(x => {
                            return (
                            <tr>
                                <td>{x.icno}</td>
                                <td>{x.name}</td>
                                <td>
                                    <i id={x.visitId} className="fa fa-print" aria-hidden="true" onClick={this.edit} title="print"></i>&nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>                            
                            )
                        }
                        )                        
                    }
                    </tbody>
                    </Table>
                </div>
              
            </div>
            </>
        )
    }
}