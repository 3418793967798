import React from 'react';


export default  class Footer extends React.Component {
    render(){
        return(
            <div className="footer">
            Privacy Policy | Use Terms and Contition Apply
            </div>
            
        )
    }
}
