
import React, {useState} from 'react'
import {Toast, Button, Row, Col} from 'react-bootstrap'

function ClinicToast(props) {
    const [show, setShow] = useState(false);

    function handleClose(){
        props.close()
    };
    return (
      <Row>
        <Col xs={6}>
          <Toast style={{zIndex: '1000',position: 'absolute', minWidth: "300px", bottom: '100px', left: '10px'}}  className={ props.bgSucces ? 'bgSuccess' : 'bgWarning' } onClose={() => handleClose()} show={props.show} delay={3000} autohide>
            <Toast.Header >
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">Clinic 2020</strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body>{props.content}</Toast.Body>
          </Toast>
        </Col>
      </Row>
    );
  }

  export default ClinicToast