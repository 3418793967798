import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class JournalDescriptionService {

  add(data) {
    return axios.post(API_URL.journalDescription.add, data,{ headers: authHeader() });
  }
  update(data) {
    return axios.post(API_URL.journalDescription.update, data,{ headers: authHeader() });
  }
  delete(data) {
    return axios.post(API_URL.journalDescription.delete, data,{ headers: authHeader() });
  }
  getJournal(data) {
    return axios.post(API_URL.journalDescription.getJournal, data, { headers: authHeader() });
  }

}

export default new JournalDescriptionService();
