import React, {Component} from 'react'
import {Table} from 'react-bootstrap'
import Authorization from '../../helpers/auth'
import DatePicker from "react-datepicker";
import { Modal, Button, Form} from 'react-bootstrap';
import LookupAccountService from '../../services/lookup-account.service'
import JournalService from '../../services/journal.service'
import CounterService from '../../services/counter.service'
import JournalDescriptionService from '../../services/journal-description.service'
import { toDate } from '../../helpers/utility'

class AddTransactionModal extends Component {
    constructor(props){
        super(props)
        this.state= {
            startDate : new Date(),
            debit:[0,0],
            credit:[0,0],
            journalData:[0,1],
            accId:[],
            accLookup: null
        }
    
    }
    componentDidMount = () =>{
        LookupAccountService.getAllAccount().then(result => {
            if(result){
                result.data.splice(0,0,{ id:0,accountname: ""})        
                this.setState({
                    accLookup: result.data
                })
            }
        })
     
       
        
    }
    clearData = () => {
        this.setState({
            debit:[0,0],
            credit:[0,0],
            journalData:[0,1],
            description: '',
            accId:[]
        })
    }
    saveAll = () => {
       let startDate = toDate(this.state.startDate)
       let desc = this.state.description
       let accId = this.state.accId
       let len = accId.length
       if(len==0){
           alert('Please select acccount to continue')
           return
       }  
        CounterService.getJournalId().then(result => {
            if(result){
                let journalId = result.data
                for(var i=0;i<len;i++){
                    let journal = {
                        journal_id: journalId,
                        date: startDate,
                        accountId: 0,
                        debit: 0,
                        credit: 0
                    }

                    journal.accountId = accId[i]
                    journal.debit = this.state.debit[i]
                    journal.credit = this.state.credit[i]
                    const cloneJ = { ...journal };
                    JournalService.add(cloneJ).then(result => {
                        console.log('add transaction to journal')
                    })
                }
                let description = {
                    journal_id: journalId,
                    description: desc
                }
                JournalDescriptionService.add(description).then(result => {
                    this.clearData()
                    this.props.onSave()
                    console.log('add description to journaldescription')
                })
            }
    
       })
    }

    handleStartDateChange = (date) => {
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            startDate: new Date(updDate)
        });
    }

    handleInputChange = (event) =>{
        let index = event.target.id
        let name = event.target.name
        let value = event.target.value

        if(name=='debit'){
            let debit = this.state.debit
            debit[index] = value
            this.setState({
                debit
            })
        }
        else if(name=='credit'){
            let credit = this.state.credit
            credit[index] = value
            this.setState({
                credit
            })
        }
        else if(name=='accId'){
            let accId = this.state.accId
            accId[index] = value
            this.setState({
                accId
            })
        }
        else{
            this.setState({
                [name]:value
            })
        }
       
    }

    renderLookup = () => {
        if(this.state.accLookup == null) return                                     
        return this.state.accLookup.map(x =>{
              return (
                  <option value={x.id}>{x.accountname}</option>
                 
                  )
              })
    }

    addMoreTrans = () => {
        let debit = this.state.debit
        debit.push(0)
        let credit = this.state.credit
        credit.push(0)
        let j = this.state.journalData
        j.push('x')

        this.setState({
            journalData:j,
            debit,
            credit
        })
    }

    render(){
        return (
            <Modal  size="lg" backdrop="static" show={this.props.show} onHide={this.props.onHide} style={{width:"1000"}} >
                <Modal.Header closeButton>
                    <Modal.Title>
                    <h5 style={{padding:"10px", backgroundColor:"black", color:"#FFFFFF"}}>Add Transactions</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{backgroundColor: "#e9edf3"}}>

              
                    <Form className = "formBig" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Group controlId="formStartDate">
                                    <Form.Label>Date: </Form.Label>
                                    <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    dateFormat="dd-MM-yyyy"
                                    />
                                 </Form.Group>
                            </div>
                            <div className="col-lg-12">
                                <Form.Group controlId="formDesc">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="text" value={this.state.description} name="description" placeholder="description" onChange={this.handleInputChange} required />
                                </Form.Group>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Account</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    
                                        this.state.journalData && (
                                            this.state.journalData.map((x,index) => {
                                                //const { debit, credit } = x //destructuring
                                                    return (
                                                            <tr key={index}>
                                                            <td>
                                                            <select name="accId" id={index} onChange={this.handleInputChange}>
                                                             {this.renderLookup()}
                                                             </select>
                                                            </td>
                                                            <td><input type="text" id={index} value={this.state.debit[index]} name="debit" onChange={this.handleInputChange}></input></td>
                                                            <td><input type="text" id={index} value={this.state.credit[index]} name="credit" onChange={this.handleInputChange}></input></td>
                                                            
                                                        </tr>
                                                    )    
                                            })
                                        )
                                    }
                                    </tbody>
                                </Table>
                      
                                </div>
                            </div>
                            
                            <div className="col-lg-5">
                                <Button style={{marginTop: "30px"}} variant="primary" onClick={this.addMoreTrans}>
                                    Add More Transactions
                                </Button>
                            </div>
                        </div>
                    </Form>
            
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.saveAll} >Save</Button>
                    <Button variant="secondary" onClick={this.props.onHide} >Close</Button>
                </Modal.Footer>
            </Modal>
        
    )
       
    }
}

export default Authorization(AddTransactionModal, ['superadmin','doctor'])