import React, {Component} from 'react'
import Authorization from '../../helpers/auth'
import DoctorAddConsultation from '../doctor/consultation/consultation-add.component'
import { Modal, Button, Form} from 'react-bootstrap';


class AddConsultationModal extends Component {
    constructor(props){
        super(props)
    
    }
    

    render(){
        return (
            <Modal  size="lg" backdrop="static" show={this.props.show} onHide={this.props.onHide}>

                <Modal.Header closeButton>
                    <Modal.Title>
                    <h5>Consultation </h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{backgroundColor: "#e9edf3", width:"100%"}}>
                    <DoctorAddConsultation visitId={this.props.visitId} icNo={this.props.icNo} close={this.props.onHide}></DoctorAddConsultation>                   
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide} >Close</Button>
                </Modal.Footer>
            </Modal>
    )
       
    }
}

export default Authorization(AddConsultationModal, ['superadmin','doctor','staff'])