import React, {Component} from 'react'
import NoAccess from '../components/shared/no-access'
import {getNameAndRole} from '../helpers/utility'
import { Redirect } from 'react-router-dom'
import Login from '../components/login.component'

const Authorization = (WrappedComponent, allowedRoles) => {
  return class WithAuthorization extends Component {
    constructor(props){
      super(props)
      let user = getNameAndRole()
      this.state = {
        user 
      }
    }

    render() {
      if(this.state.user){
        const { role} = this.state.user
        if(allowedRoles.includes(role)){
          return <WrappedComponent {...this.props}/>

        }else {
          return <NoAccess></NoAccess>
        }
      }else{
        return <Login></Login>
      }
    }
  }
}

export default Authorization