import React, {Component} from 'react'
import {Form, Button, Label, Table} from 'react-bootstrap'
import ClinicToast from '../../shared/toast'
import Authorization from '../../../helpers/auth'
import NavBar from '../../nav-bar.component'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JournalService from '../../../services/journal.service'
import JournalDesriptionService from '../../../services/journal-description.service'
import { toDate } from '../../../helpers/utility'
import ClinicPagination from '../../shared/clinic-pagination.component'
import PAGINATION from '../../../constants/pagination.constant'
import BlockUI from '../../shared/BlockUI/blockUI'
import { Redirect } from 'react-router-dom';
import history from '../../../history'
import LookupAccountService from '../../../services/lookup-account.service'

class IncomeStatement extends Component {
    constructor(props){
        super(props)

        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            showToast: false,
            journalData: [],
            cloneJ:[],
            accountTypeId: 1,
            accountName: '',
            show: false,
            journalId: null,
            editJournal: null,
            totalRecords: 0,
            credit: false,
            debit: false
        }      
    }
   
    componentDidMount() {
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate)
        }

       
    }

    handleClose = () => {
        this.setState({
            show: false
          });
  
    }
    handleSave = () =>{
        this.setState({
            show: false,
            showToast: true,
            message:'Successfully save transaction into journal',
            bgSucces: true
          });
    }


    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
     
    }
    handleSubmit = (event) => {
        event.preventDefault()
        this.setState({
            blocking: true
        })
        
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate)
        }

        JournalService.getIncomeStatement(condition).then(result => {
            if(result){                                  
                    this.setState({
                        blocking: false,
                        journalData: result.data
                    })       
            }
        })
        
    }

    addNew = () => {
        this.setState({
            show: true,
            action: "Add",
            accountName: ''
        })
    }
    delete = (e) => {
        const journalId = e.target.id
        let status = window.confirm("Are you sure to delete this transaction?")
        if(status){
           
            JournalService.delete({journalId}).then(result => {
                if(result){
                    JournalDesriptionService.delete({journalId}).then(result => {
                        let data = this.state.cloneJ
                        
                        data = data.filter(x=>{
                            return x.journal_Id != journalId
                        })
                        
                        this.setState({
                            journalData: this.groupResult(data),
                            cloneJ: data
                        })
                        
                    })
                }   
            })
        }
    }
    edit = (e) => {
        const id = e.target.id
        this.setState({
            editJournal: `/accounting/journal/edit/` + id
          });
    }

    handleStartDateChange = (date) => {
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            startDate: new Date(updDate)
        });
    }

    handleEndDateChange = (date) =>{
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            endDate: new Date(updDate)
        });
    }
    handleAccountType = (event) => {
        let value = event.target.value
        this.setState({
            accountTypeId: value
        })
    }

    handleChange = (event) => {
        let value = event.target.value
        this.setState({
            search: value
        })
    }

     handlePageChange = (pageNumber) => {
        
        //console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber,  blocking: true});
        
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate),
            accountId: this.state.accId,
            pagination: {
                page: pageNumber,
                records: PAGINATION.records
            }
        }
        JournalService.getLedgerByAccount(condition).then(result => {
            if(result){
                this.setState({
                    blocking:false,
                    journalData: result.data.records
                })
            }
        })


      }
      handleAddTransactions = () => {
          alert('sdas')
      }

      handleShow = (e) => {

        e.preventDefault()
    
        this.setState({
          show: true
        });
      };

      renderLookup = () => {
        if(this.state.accLookup == null) return                                     
        return this.state.accLookup.map(x =>{
              return (
                  <option value={x.id}>{x.accountname}</option>
                 
                  )
              })
     }
     handleInputChange = (event) =>{
    
        let name = event.target.name
        let value = event.target.value
    
        this.setState({
            [name]:value
        })
    }

    
    render(){
        let totRevenue = 0
        let totExpense = 0
        if(this.state.editJournal){
            history.push(this.state.editJournal)
            return <Redirect to={this.state.editJournal} />
        }
        return(
           
            <div>
                <div className="menu">
                    <div className="mainToast">
                        <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                    </div>
                    <NavBar></NavBar>
                </div> 
                <div className="docMobile">
                <Form style={{width:"80%",margin: "0% 10%", padding:"40px 30px", backgroundColor:"#e9edf3"}} onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-xs-12 col-lg-12">
                        <h5>Income Statement</h5>
                    </div>
                </div>
                <br></br><br></br>
                <div className="row">
                    <div className="col-xs-12 col-lg-12">
                        <div className="row">
                            <div className="col-xs-3 col-lg-3">
                                <Form.Label>Date From:</Form.Label>
                                <DatePicker
                                   
                                   selected={this.state.startDate}
                                   onChange={this.handleStartDateChange}
                                   dateFormat="dd-MM-yyyy"
                               />
                            </div>
                            <div className="col-xs-3 col-lg-3">
                            <Form.Label>To:&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                                <DatePicker
                                   
                                   selected={this.state.endDate}
                                   onChange={this.handleEndDateChange}
                                   dateFormat="dd-MM-yyyy"
                               />
                            </div>
                            <div className="col col-lg-2">
                                <Button type="submit" onSubmit={this.handleSubmit}>Select</Button>
                            </div>
                        </div>
                    </div>
               </div>
            
               <hr></hr>
    
               <div className="row">
                    <div className="col-xs-12 col-lg-12">
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Account</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.journalData && (
                                        this.state.journalData.map((x,index) => {
                                            const { accountName, type, total } = x //destructuring
                                               
                                                if(type == "Revenue"){
                                                    totRevenue = totRevenue + total
                                                    return (
                                                        <tr key={index}>
                                                            <td>{accountName}</td>
                                                            <td></td>
                                                            <td>{parseFloat(total).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                }else{
                                                    totExpense = totExpense + total
                                                    return (
                                                        <tr key={index}>
                                                            <td>{accountName}</td>
                                                            <td>{parseFloat(total).toFixed(2)}</td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            })
                                    )
                                }
                                <hr></hr>
                                <div className="col-xs-12 col-lg-12">
                                    <span><h2>{(totRevenue>totExpense) ? 'NET INCOME' : ' NET LOSS'} {(totRevenue - totExpense).toFixed(2)}</h2></span>
                                </div>
                                
                                       
                                    
                                        
                                    
                                     
                                
                               
                            </tbody>
                           
                        </Table>
                      
                    </div>
               </div>
              
               <br></br><br></br>
            
               </Form>
               <BlockUI blocking={this.state.blocking}></BlockUI>
              
               </div>
             
            </div>
        );
    }
}

export default Authorization(IncomeStatement, ['superadmin','doctor'])