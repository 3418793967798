import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class MedicineService {

  add(data) {
    return axios.post(API_URL.medicine.add, data,{ headers: authHeader() });
  }
  update(data){
    return axios.post(`${API_URL.medicine.update}`, data, { headers: authHeader() });
  }
  delete(data){
    return axios.post(`${API_URL.medicine.delete}`, data, { headers: authHeader() });
  }
  getMedicines() {
    return axios.get(API_URL.medicine.getMedicines, { headers: authHeader() });
  }

  getMedicineById(data) {
    return axios.post(API_URL.medicine.getMedicineById, data, { headers: authHeader() });
  }

  search(data){
    return axios.post(API_URL.medicine.search, data, { headers: authHeader() });
  }

}

export default new MedicineService();
