import React, {Component} from 'react'
import NavBar from '../nav-bar.component';
import {Form, Button, Table} from 'react-bootstrap'
import SearchPatient from '../shared/search-patient.component';
import PatientService from '../../services/patient.service'
import history from '../../history'
import { Redirect} from 'react-router-dom'

export default class PatientList extends Component {
    constructor(props){
        super(props)
        this.state = {
            searchType: 1,
            search: '',
            items: []
        }
    }
    handleChange = (event) => {

        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }
    findPatient = () =>{
        if(this.state.search === undefined || this.state.search.trim().length === 0 ) {
            alert('Please type in search')
            return
        }
        let condition = {
            nama: '',
            icno: ''
        }
        if(this.state.searchType == 1){
            condition.nama = this.state.search;
        }
        if(this.state.searchType == 2){
            condition.icno = this.state.search;
        }
        this.setState({
            blocking: true
        })

        PatientService.searchPatient(condition).then(response => {
      
               this.setState({
                   items: response.data,
                   isLoaded: true,
                   blocking: false
               })
          
        })
    }
    edit = (e) => {
        const id = e.target.id
        this.setState({ redirect: `/patient/edit/${id}`});
    }

    delete = (e) => {
        const id = e.target.id
        let status = window.confirm("Are you sure to delete this user?")
        if(status){
            PatientService.delete({id}).then(result => {
                if(result){
                let newItems =  this.state.items.filter(x => {
                    return x.id != id
                    })
                    this.setState({
                        items : newItems
                    })
                }
            })
        }
    }

    render(){
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return(
            <>   
            <div className="menu">
            <NavBar></NavBar>        
            </div>
            <div className="content">
                    <h2>Patient Listing</h2>
                    <Form className = "formClinic">
                    <SearchPatient findPatient={this.findPatient} handleChange={this.handleChange}></SearchPatient>
                    <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            <tr>
                                <th>Ic Number</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Telephone</th>
                                <th>Loyalty</th>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                   
                    { 
                        this.state.items &&
                        this.state.items.map(x => {
                            return (
                            <tr>
                                <td>{x.icNo}</td>
                                <td>{x.nama}</td>
                                <td>{x.emel}</td>
                                <td>{x.telepon}</td>
                                <td>{x.loyalty}</td>
                                <td>{x.points}</td>
                                <td>
                                    <i id={x.icNo} className="fa fa-pencil-square-o" aria-hidden="true" onClick={this.edit} title="edit"></i>&nbsp;&nbsp;&nbsp;
                                    <i  id={x.icNo} className="fa fa-times" aria-hidden="true" onClick={this.delete} title="delete"></i>
                                </td>
                            </tr>
                            
                            )
                        }
                        )
                        
                    }
                    </tbody>
                    </Table>
                </Form>
            </div>
           
            </>
        );
    }
}