import React, {Component} from 'react'
import {Form, Button, Table} from 'react-bootstrap'
import NavBar from '../nav-bar.component'
import ConsultationService from '../../services/consultation.service'
import McService from '../../services/mc.service'
import paymentService from '../../services/payment.service'
import axios from 'axios';
import ClinicToast from '../shared/toast'
import history from '../../history'
import { Redirect} from 'react-router-dom'
import CARD_TYPE from '../../constants/card-type.constant';
import PAYMENT_METHOD from '../../constants/payment-method.constant';
import {renderCardDeduct} from '../../helpers/utility'
import CounterService from '../../services/counter.service'
import PatientService from '../../services/patient.service'
import JournalService from '../../services/journal.service'
import journalDescriptionService from '../../services/journal-description.service'
import {isNotPanel, toDate} from '../../helpers/utility'

export default class PatientPharmacyDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            dispensary: [],
            medications:[],
            mcs: [],
            payment: '',
            showToast: false,
            redirect: null,
            details: false,
            cardtype: '',
            readOnly: false
        }
    }

    componentDidMount = () => {
        var visitId = this.props.match.params.id;
        //var paymentId = this.props.match.params.paymentId

        this.setState({
            visitId
        })

        let conditions = {
            visitId
        }

        const consultation =ConsultationService.getPatientConsultation(conditions)
        const medicines = ConsultationService.getPatientMedicines(conditions)
        const mc = McService.getMcs(conditions)
        const paymentMethod = paymentService.getPayments();

        axios.all([consultation, medicines, mc, paymentMethod]).then(axios.spread((...responses) => {
            const lsConsult = responses[0].data
            const lsMed = responses[1].data
            const lsMc = responses[2].data
            const lsPayment = responses[3].data

            const { visitId, name, patientId, loyalty, points, doctor, drfee, discount, paymentId, cardType } = lsConsult[0]
            
            this.setState({
                 visitId,
                 patientId,
                 name,
                 doctor,
                 payment : paymentId,
                 drfee,
                 discount,
                 cardType,
                 points,
                 loyalty,              
            })

            var notPanel = isNotPanel(lsPayment, paymentId)

            var pays = lsPayment.map((data, index)=>
                <option key={index} value={data.id}>{data.name}</option>
            )

            this.setState({
                payments: pays
            })

            let med = lsMed.map((data, index) =>{
                return {
                    drugName: data.name,
                    drugId: data.medicineId,
                    selectQty: data.quantity,
                    cost: data.cost,
                    amount: notPanel ? data.price : data.panelprice,
                    prescription: data.prescription,
                    notes: data.notes
                }
            })

            this.setState({
                medications: med
            })

            this.setState({
                mcs: lsMc
            })

            this.handleNetAndTotal(drfee, discount)

        })).catch(errors => {
            // react on errors.
            console.log(errors)
          })


    }

    handleChange = (event) => {

        const value =  event.target.value;
        const name = event.target.name;
    
        this.setState({
          [name]: value
        });

        // this.setState({
        //     cardtype: event.target.value
        // })
        if(name == 'cardType'){
            let info = {
                visitId: this.state.visitId,
                cardType: parseInt(event.target.value)
            }
            ConsultationService.updCardType(info).then(result => {
              
            })

        }else if(name == 'payment'){
            let info = {
                visitId: this.state.visitId,
                paymentMethod: parseInt(event.target.value)
            }
            ConsultationService.updPaymentMethod(info).then(result => {
                if(result){
                    window.location.reload(false)
                }
            })
        }
        
    }
    handleReceipt = () => {

        let receiptNo = ""
        let info = {
            visitId: parseInt(this.state.visitId)
        }

        ConsultationService.getInvoiceNoByVisitId(info).then(result => {
            if(result){
                receiptNo = result.data.receiptNo
                if(receiptNo != null){
                    this.setState({
                        redirect: `/receipt/${this.state.visitId}/${receiptNo}/${this.state.details}`
                    })
                }
                if(receiptNo == null){
                    ConsultationService.getInvoiceNo().then(result => {
                        if(result){
                            receiptNo = result.data
                            this.setState({
                                redirect: `/receipt/${this.state.visitId}/${receiptNo}/${this.state.details}`
                            })
                        }
                       
                    })
                }
            }
        })
       
    }

    getTotalCost=() => {
        if(this.state.medications!=null){
            return this.state.medications.map(x => { return x.cost*x.selectQty }).reduce(function(a, b){
                return a + b;
                }, 0);
    
        }
    }

    addReceipt = (receiptNo)=>{
        this.setState({
            invoiceNo: receiptNo
        })
        let info = {
            visitId: parseInt(this.state.visitId),
            receiptNo: receiptNo
        }
        ConsultationService.addReceipt(info).then(result => {
            if(result){
                ConsultationService.updInvoiceCounter().then(result => {

                })
            }
        })
    }


    handleComplete= () => {

        let info = {
            visitId: parseInt(this.state.visitId)
        }

        ConsultationService.getInvoiceNoByVisitId(info).then(result => {
            if(result){
                var receipt = result.data.receiptNo
                if(receipt == null || receipt == undefined){
                    ConsultationService.getInvoiceNo().then(result => {
                        if(result){
                            this.addReceipt(result.data)
                        }
                    })
                }
            }
        })

        ConsultationService.complete({visitId: this.state.visitId}).then(result => {
            if(result){
                //only true add transaction
                if(result.data.status){
                    let data = {};

                    data.IcNo = this.state.patientId;
                   
                    if (this.state.loyalty) {
                        //add total to points
                        data.points = Math.round(this.state.total + this.state.points);              
                        PatientService.updatePoints(data)                        
                    }

                    this.setState({
                        readOnly: true
                    })
                //add #1 transaction
                CounterService.getJournalId().then(result => {
                    if(result){
                        let journalId = result.data
                        let journalCash = {
                            journal_id: journalId,
                            date: toDate(new Date()),
                            accountId: 0,
                            debit: 0,
                            credit: 0
                        }

                        journalCash.accountId = 1 //cash account
                        journalCash.debit = this.state.total
                        JournalService.add(journalCash).then(result => {
                            console.log('add cash')
                        })
                        
                        let journalRevenue = {
                            journal_id: journalId,
                            date: toDate(new Date()),
                            accountId: 0,
                            debit: 0,
                            credit: 0
                        }
                        journalRevenue.accountId = 2 //consultation revenue account
                        journalRevenue.credit = this.state.total
                        JournalService.add(journalRevenue).then(result => {
                            console.log('add consultation revenue')
                            let desc = {
                                journal_id: journalId,
                                description: 'Consultation Revenue'
                            }
                            journalDescriptionService.add(desc).then()
                        })

                        //add #2 transaction
                        CounterService.getJournalId().then(result => {
                            if(result){
                                let journalId = result.data
                                let journalInventory = {
                                    journal_id: journalId,
                                    date: toDate(new Date()),
                                    accountId: 0,
                                    debit: 0,
                                    credit: 0
                                }

                                let cost = this.getTotalCost()
                                journalInventory.accountId = 37 //Inventory account
                                journalInventory.credit = cost
                                JournalService.add(journalInventory).then(result => {
                                    console.log('add Inventory')
                                })

                                let journalCOGS = {
                                    journal_id: journalId,
                                    date: toDate(new Date()),
                                    accountId: 0,
                                    debit: 0,
                                    credit: 0
                                }

                                journalCOGS.accountId = 38 //Cost Of Good Sold
                                journalCOGS.debit = cost
                                JournalService.add(journalCOGS).then(result => {
                                    console.log('add cogs')
                                    let desc = {
                                        journal_id: journalId,
                                        description: 'Cost Of Good Sold'
                                    }
                                    journalDescriptionService.add(desc).then()
                                })

                            }

                        })


                    }

                })
                this.setState({
                    showToast: true,
                    message:'Complete...',
                    bgSucces: true
                })  
            }      
            }
        })
        
    }

    handleNetAndTotal = (drFee, discount) => {
    
        var total = 0;

        // Getting sum of numbers
        var medications = this.state.medications.filter(x => {
            return x.status != 'delete'
        })
        var sum = medications.map(x => { return x.amount*x.selectQty }).reduce(function(a, b){
        return a + b;
        }, 0);

        var cost = medications.map(x => { return x.cost*x.selectQty }).reduce(function(a, b){
            return a + b;
            }, 0);

        if(discount != undefined){
            var i = (100 - discount)/100
            if(drFee == ""){
                total = sum  * i
            }
            else{
                total = (sum + parseInt(drFee)) * i
            }
           
        }
        else
        {
            if(drFee == undefined){
                total = sum;
            }
            else{
                total = sum + parseInt(drFee)
            }
            
        }
        this.setState({
            total,
            net: total - cost
        })
       
    }

    handleCloseToast = () => {
        this.setState({
            showToast: false
        })
    }
    // isNotPanel = (lsPayment, paymentId) => {
    //     let paymentMethod = lsPayment.filter(x => {
    //         return x.id == paymentId
    //     })
    //     // this.setState({
    //     //     payment: paymentMethod[0].name
    //     // })
    //     const payment = ['Cash','CreditCard']
    //     let status = payment.includes(paymentMethod[0].name)
    //     return status
    // }

    renderTableHeader() {
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">Drug Name</th>
                <th key="2">Qty</th>
                {/* <th key="3">Cost</th> */}
                <th key="4">Price</th>
                <th key="5">Amount</th>
                <th key="6">Prescription</th>
                <th key="7">Notes</th>
            </tr>
        );
    }
    
    renderTableData = () => {
      
        if(this.state.medications!=null){
            return this.state.medications.map((data,index) => {
                return (
                    <tr key={index}>
                    <td>{index}</td>
                    <td>{data.drugName}</td>
                    <td>{data.selectQty}</td>
                    {/* <td>{data.cost}</td> */}
                    <td>{data.amount}</td>
                    <td>{data.amount*data.selectQty}</td>
                    <td>{data.prescription}</td>
                    <td>{data.notes}</td>
                </tr>
                )
                
            })  
        }    
    }

    renderCertHeader = () => {
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">From</th>
                <th key="2">To</th>
                <th key="3">Days</th>
            </tr>
        );
    }
    
    renderCertData = () =>{
       
            return this.state.mcs.map((data,index) => {
                return (
                    <tr key={index}>
                        <td>{index}</td>
                        <td>{this.formatDate(data.datefrom)}</td>
                        <td>{this.formatDate(data.dateto)}</td>
                        <td>{this.totalMcDay(data.datefrom, data.dateto)}</td>
                    </tr>
                    )
                    
                })  
    }

    formatDate = (today) => {
        if(today == undefined) return
        var pDate = new Date(today)
        let dd = pDate.getDate();
        let mm = pDate.getMonth()+1; 
        const yyyy = pDate.getFullYear();
        
        if(dd<10) 
        {
            dd=`0${dd}`;
        } 

        if(mm<10) 
        {
            mm=`0${mm}`;
        }

        return `${dd}-${mm}-${yyyy}`;
    }

    totalMcDay = (startDate, toDate) => {
        var sDate = new Date(startDate)
        var eDate = new Date(toDate)
        // To calculate the time difference of two dates 
       return (eDate.getDate() - sDate.getDate() + 1 ); 
    }

    handleDetails = (event) =>{
        var check = event.target.checked
        this.setState({
            details: check
        })
    }


    render(){
        if (this.state.redirect != null) {
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
            <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
            </div>
            
            <div className="content">
            <h5>Dispensary of {this.state.name}</h5>
               
                <Form className = "formBig" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3">
                                <Form.Group controlId="formIcNo">
                                    <Form.Label>Identification No</Form.Label>
                                    <Form.Control type="text" value={this.state.patientId || ''} name="icno" placeholder="Ic No" readOnly  />
                                </Form.Group>
                            </div>
                            <div className="col-lg-5">
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" value={this.state.name || ''} name="name" readOnly  />
                                </Form.Group>
                            </div>
                            <div className="col-lg-2">
                                <Form.Group controlId="formPayment">
                                    <Form.Label>Payment</Form.Label>
                                    {/* <Form.Control type="text" value={this.state.payment} name="payment" readOnly  /> */}
                                    <Form.Control name="payment" as="select" value={this.state.payment} onChange={this.handleChange}>
                                        <option value=""></option>
                                        {this.state.payments}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-lg-2">
                            {this.state.payment==PAYMENT_METHOD.CreditCard ? (
                                <Form.Group controlId="formCardType">
                                <Form.Label>CardType</Form.Label>
                                <Form.Control name="cardType" as="select" value={this.state.cardType} onChange={this.handleChange} required>
                                    <option value=""></option>
                                    <option value={CARD_TYPE.LocalDebit}>Local Debit</option>
                                    <option value={CARD_TYPE.LocalCredit}>Local Credit</option>
                                    <option value={CARD_TYPE.ForeignCard}>Foreign Card</option>
                                    <option value={CARD_TYPE.AmericanExpress}>American Express</option>
                                    <option value={CARD_TYPE.UnionPay}>Union Pay</option>
                                    <option value={CARD_TYPE.JCB}>JCB</option>
                                    
                                </Form.Control>
                                </Form.Group>
                            ) : <></>}
                            </div>
                        </div>
                      
                        {/* <div className="row table-wrapper-scroll-y my-custom-scrollbar"> */}
                        <br></br>Medicines<br></br>
                            <Table responsive striped bordered hover size="sm" variant="dark">
                                <thead>
                                    {this.renderTableHeader()}
                                </thead>
                                <tbody>
                                
                                    {this.renderTableData()}
                                </tbody>
                            </Table>
                        {/* </div> */}
                        <br></br>
                        <div className="row">
                            <div className="col-lg-2">
                                <Form.Group controlId="formDrFee">
                                    <Form.Label>Dr fee</Form.Label>
                                    <Form.Control type="text" value={this.state.drfee || ''} name="drfee" readOnly/>
                                </Form.Group>
                            </div>
                            <div className="col-lg-2">
                                <Form.Group controlId="formDiscount">
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control type="text" value={this.state.discount || ''} name="discount" readOnly/>
                                </Form.Group>
                            </div>                    
                            <div className="col-lg-2">
                                <Form.Group controlId="formCardDeduct">
                                    <Form.Label>Card Deduction</Form.Label>
                                    <Form.Control type="text" value={renderCardDeduct(this.state.cardType,this.state.total) || ''} name="discount" readOnly  />
                                </Form.Group>
                            </div>
                            <div className="col-lg-2">
                                <Form.Group controlId="formTotal" style={{fontWeight:"bold", color: "red"}}>
                                    <Form.Label>Total</Form.Label>
                                    <Form.Control type="text" value={'RM ' + this.state.total || ''} name="total" style={{fontWeight:"bold", color: "red"}} readOnly  />
                                </Form.Group>
                            </div>
                        </div>


                        <br></br>Medical Certificate(MC)<br></br>
                        <Table responsive striped bordered hover size="sm" variant="dark">
                            <thead>
                                {this.renderCertHeader()}
                            </thead>
                            <tbody>
                                {this.renderCertData()}
                            </tbody>
                        </Table>
                       
                </Form>
                </div>

                <div className="consultationFooter">
                    <div className="row">
                    <Form.Group controlId="x">
                        <div className="col-lg-2">
                            <Button type="button" onClick={this.handleComplete} disabled={this.state.readOnly}>Complete</Button>
                        </div>
                        </Form.Group>
                        <Form.Group controlId="y">
                        <div className="col-lg-2">
                            <Button type="button" onClick={this.handleReceipt}>Receipt</Button>
                        </div>
                        </Form.Group>
                        <Form.Group controlId="z">
                        <div className="col-lg-1" style={{marginTop:"10px"}}>
                        
                            <Form.Check type="checkbox" label="details?" name="detail" onClick={this.handleDetails} />
                      
                        </div>
                        </Form.Group>
                       
                    </div>
                </div>
            </>
        )
    }

}