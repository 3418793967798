import React, { Component, Fragment } from "react";
import {Button} from 'react-bootstrap'
import PropTypes from "prop-types";
import './dropdown.css'
import MedicineService from '../../services/medicine.service'

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: null,
      drugs: []
    };
  }

  componentDidMount() {
    MedicineService.getMedicines().then(result =>{
        if(result){
          let drugs = result.data.filter(x => {
            return x.inactive == 0
          })
            
          this.setState({
              drugs
          })

        }
    })

  }

  // Event fired when the input value is changed
  onChange = e => {

    // const { suggestions } = this.props;
    // const userInput = e.currentTarget.value;

    // // Filter our suggestions that don't contain the user's input
    // const filteredSuggestions = suggestions.filter(
    //   suggestion =>
    //     suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    // );

    const drugs = this.state.drugs;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = drugs.filter(
      suggestion =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    e.preventDefault()
    //alert(e.currentTarget.id)
    this.props.handleDrug(this.state.filteredSuggestions[e.currentTarget.id].id)
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };


  clearInputValue = () =>{
    this.setState({
      userInput: ''
    })
  }

  // Event fired when the user presses a key down
  onKeyDown = e => {
  
    // const { activeSuggestion, filteredSuggestions } = this.state;

    // // User pressed the enter key, update the input and close the
    // // suggestions
    // if (e.keyCode === 13) {
    //   e.preventDefault()
    //   let sc = filteredSuggestions[activeSuggestion]
    //   this.setState({
    //     activeSuggestion: 0,
    //     showSuggestions: false,
    //     userInput: sc.name
    //   });
    // }
    // // User pressed the up arrow, decrement the index
    // else if (e.keyCode === 38) {
    //   if (activeSuggestion === 0) {
    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion - 1 });
    // }
    // // User pressed the down arrow, increment the index
    // else if (e.keyCode === 40) {
    //   if (activeSuggestion - 1 === filteredSuggestions.length) {
    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion + 1 });
    // }
  };

  render() {
  
    const {activeSuggestion, filteredSuggestions, showSuggestions,userInput  } = this.state

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
                 
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li id={index}
                  className={className}
                  key={suggestion.id}
                  onClick={this.onClick}
                >
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        
          
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input style={{width:"60%"}}
          type="text"
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          value={this.state.userInput}
        /><span>  <Button variant="primary" onClick={this.clearInputValue}>
                  Clear
                  </Button></span>
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;