import React, {Component} from 'react'
import Authorization from '../../helpers/auth'
import PatientVisitHistory from '../shared/patient-visit-history.component'
import { Modal, Button, Form} from 'react-bootstrap';
import LookupAccountService from '../../services/lookup-account.service'


class AccountEditModal extends Component {
    constructor(props){
        super(props)
        this.state= {
            accType : this.props.accountTypeId,
            accountName: this.props.accountName,
        }
    
    }
    componentDidMount = () =>{
        this.setState({
            accType : this.props.accountTypeId,
            accountName: this.props.accountName,
        })
    }
    handleChange = (event) => {
 
        let name = event.target.name
        let value = event.target.value

        this.setState({
            [name]:value
        })
    }
    addNew = () => {

        let acc = {
            accountTypeId: this.state.accType,
            accountName: this.state.accountName
        }

        LookupAccountService.add(acc).then(result => {
            if(result){
                this.setState({
                    accountName: ''
                })
                alert('success')
            }
        })
    }

    update = () => {

        let acc = {
            accountTypeId: this.state.accType,
            accountName: this.state.accountName,
            id: this.props.id
        }

        LookupAccountService.update(acc).then(result => {
            if(result){
                alert('success')
            }
        })
    }

    render(){
        return (
            <Modal  size="lg" backdrop="static" show={this.props.show} onHide={this.props.onHide}>

                <Modal.Header closeButton>
                    <Modal.Title>
                    <h5 style={{padding:"10px", backgroundColor:"black", color:"#FFFFFF"}}>{this.props.action} Account</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{backgroundColor: "#e9edf3"}}>

                <div className="row" >
                    <div className="modalClinic">
                    <Form>
                       <div className="row">
                           <div className="col-lg-6">
                                <Form.Group controlId="formAccountType">
                                    <Form.Label>Account Type</Form.Label>
                                    <Form.Control as="select" onChange={this.handleChange} value={ this.state.accType ||  ' '} name="accType">
                                        <option value="1">Asset</option>
                                        <option value="2">Liability</option>
                                        <option value="3">Equity</option>
                                        <option value="4">Revenue</option>
                                        <option value="5">Expense</option>
                                    </Form.Control>
                                </Form.Group>
                           </div>
                           <div className="col-lg-6">
                                <Form.Group controlId="formAccountName">
                                    <Form.Label>Account Name</Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange} value={ this.state.accountName || ' '} name="accountName"></Form.Control> 
                                </Form.Group>
                           </div>
                       </div>
                       { this.props.action == 'Add' && (
                           <Button type="button" onClick={this.addNew}>Save</Button>
                       )}
                        { this.props.action == 'Edit' && (
                           <Button type="button" onClick={this.update}>Update</Button>
                       )}
                    </Form>
                    </div>
                    
                </div>
      
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide} >Close</Button>
                </Modal.Footer>
            </Modal>
        
    )
       
    }
}

export default Authorization(AccountEditModal, ['superadmin','doctor'])