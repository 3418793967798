import Navbar from 'react-bootstrap/Navbar'
import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import { Nav, NavDropdown } from 'react-bootstrap'
import { getNameAndRole } from '../helpers/utility'
import history from '../history'

export default class NavBar extends Component {
  
   constructor(props){
     super(props)
     this.state = {
      redirect : null
     }
   } 

   logOut = () => {
 
    if(localStorage.getItem('user') != null)
    {
      localStorage.removeItem('user')
    }

    this.setState({
       redirect: '/login'
    })
   
   
   }
    render(){
       let user = getNameAndRole()
       if (this.state.redirect != null) {
            
        history.push(this.state.redirect)
        return <Redirect to={this.state.redirect} />
       }
        return (
          <>
           
<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Navbar.Brand href="#home">DURIAN-CliniSys</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <NavDropdown title="Patient" id="collasible-patient-dropdown">
            <Link to="/patient/register" className="dropdown-item">Register</Link>
            <Link to="/patient/add" className="dropdown-item">Add Patient</Link>
            <Link to="/patient/list" className="dropdown-item">Patient Listing</Link>
            <Link to="/patient/loyalty" className="dropdown-item">Register Loyalty</Link>        
            <Link to="/patient/buymedicine" className="dropdown-item">Patient Buy Medicine</Link>
          
      </NavDropdown>
      <NavDropdown title="Doctor" id="collasible-doctor-dropdown">
            <Link to="/doctor/consultation" className="dropdown-item">Consultation</Link>
            <Link to="/patient/history" className="dropdown-item">Patient History</Link>
          
      </NavDropdown> 
      <Nav>
      <Link to="/patient/pharmacy" className="nav-link">Dispensary</Link>
      <NavDropdown title="Maintenance" id="collasible-maintenance-dropdown">
            <Link to="/maintenance/medicine" className="dropdown-item">Medicine</Link>
            <Link to="/maintenance/user" className="dropdown-item">User</Link> 
            <Link to="/maintenance/accounting" className="dropdown-item">Accounting</Link> 
      </NavDropdown>
      <NavDropdown title="Print" id="collasible-print-dropdown">
            <Link to="/print/receipt" className="dropdown-item">Receipt</Link>
            <Link to="/print/mc" className="dropdown-item">Mc</Link>
      </NavDropdown>
      <NavDropdown title="Accounting" id="collasible-accounting-dropdown">
            <Link to="/accounting/journal" className="dropdown-item">Journal</Link>
            <Link to="/accounting/ledger" className="dropdown-item">Ledger</Link>
            <Link to="/accounting/incomestatement" className="dropdown-item">Income Statement</Link>
      </NavDropdown>      
      </Nav>   
      <a id="collasible-logout-dropdown" onClick={this.logOut} style={{cursor: "pointer", textDecoration:"underline", color:"white"}}>Logout</a>
    
      {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown> */}
    </Nav>
  </Navbar.Collapse>
</Navbar>
    <div id="content-desktop" className="pull-right" style={{color:"white", top: "5px", position: "absolute", padding:"10px", right: "0px"}}>Welcome { user ? user.name : 'xxx'} &nbsp;&nbsp; <a onClick={this.logOut} style={{cursor: "pointer", textDecoration:"underline"}}>Logout</a></div>
    </>
    )
    }
}