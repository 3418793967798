import React, {Component} from 'react'
import {Form} from 'react-bootstrap'

export default class ConsultationDocNotes extends Component {
    constructor(props){
        super(props)
       
        this.handleChange = this.handleChange.bind(this);
       
    }
    handleChange(event){
        //this.setState({payment :event.target.value});
        this.props.handleChange(event)
    }
    render(){
        return(
            <div>
                <div className="row">
                <div className="col-lg-6">
                    <Form.Group controlId="formChiefComplaint">
                        <Form.Label>Chief Complaint</Form.Label><span className="errorMsg">*</span>
                        <textarea name="cheifcomplaint" value={this.props.notes} onChange={this.handleChange}
                            className="form-control"
                            rows="5"
                            required
                        />
                        </Form.Group>
                        <div className="errorMsg">{this.props.errors.cheifcomplaint}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                    <Form.Group controlId="formDiagnosis">
                        <Form.Label>Diagnosis</Form.Label><span className="errorMsg">*</span>
                        <textarea name="diagnosis" value={this.props.diagnosis} onChange={this.handleChange}
                            className="form-control"
                            rows="5"
                        />
                        </Form.Group>
                        <div className="errorMsg">{this.props.errors.diagnosis}</div>
                    </div>
                    <div className="col-lg-6">
                    <Form.Group controlId="formAllergy">
                        <Form.Label>Allergy</Form.Label>
                        <textarea
                            className="form-control"
                            rows="5"
                            />
                    </Form.Group>
                
                    </div>

                </div>
            </div>
               
        );
    }
}