import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class ConsultationService {

  add(visit) {
    return axios.post(`${API_URL.consultation.add}`, visit ,{ headers: authHeader() });
  }
  getPatientConsultation(conditions) {
    return axios.post(`${API_URL.consultation.getConsultation}`, conditions ,{ headers: authHeader() });
  }
  getPatientMedicines(condition){
    return axios.post(`${API_URL.consultation.getMedicines}`, condition ,{ headers: authHeader() });
  }
  confirm(condition){
    return axios.post(`${API_URL.consultation.confirm}`, condition ,{ headers: authHeader() });
  }

  complete(condition){
    return axios.post(`${API_URL.consultation.complete}`, condition ,{ headers: authHeader() });
  }

  getStatus(){
    return axios.get(`${API_URL.consultation.getStatus}`, { headers: authHeader() });
  }

  getStatusByVisitId(info){
    return axios.post(`${API_URL.consultation.getStatusByVisitId}`, info, { headers: authHeader() });
  }

  setStatus(info){
    return axios.post(`${API_URL.consultation.setStatus}`, info, { headers: authHeader() });
  }

  getReceipt(info){
    return axios.post(`${API_URL.receipt.getReceipt}`, info, { headers: authHeader() });
  }
  getInvoiceNoByVisitId(info){
    return axios.post(`${API_URL.receipt.getInvoiceNoByVisitId}`, info, { headers: authHeader() });
  }

  getInvoiceNo(){
    return axios.get(`${API_URL.receipt.getInvoiceNo}`, { headers: authHeader() });
  }

  updInvoiceCounter(){
    return axios.post(`${API_URL.receipt.updInvoiceCounter}`, { headers: authHeader() });
  }

  addReceipt(info){
    return axios.post(`${API_URL.receipt.add}`, info, { headers: authHeader() });
  }


  updCardType(info) {
    return axios.post(`${API_URL.consultation.updCardType}`, info, { headers: authHeader() });
  }

  updPaymentMethod(info) {
    return axios.post(`${API_URL.consultation.updPaymentMethod}`, info, { headers: authHeader() });
  }
  updateMedicineOverCounter(info){
    return axios.post(`${API_URL.consultation.updateMedicineOverCounter}`, info, { headers: authHeader() });
  }

}

export default new ConsultationService();
