import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class UserService {

  add(data) {
    return axios.post(API_URL.user.add, data,{ headers: authHeader() });
  }

  getPatient() {
    return axios.get(`${API_URL.patient.getAll}`, { headers: authHeader() });
  }

  getDoctors() {
    return axios.post(`${API_URL.user.getDoctors}`, { headers: authHeader() });
  }

  search(condition) {
    return axios.post(`${API_URL.user.getUsers}`, condition, { headers: authHeader() });
  }

  getRoles() {
    return axios.post(`${API_URL.user.getRoles}`, { headers: authHeader() });
  }
  getUserType() {
    return axios.post(`${API_URL.user.getUserType}`, { headers: authHeader() });
  }
  getUserById(condition) {
    return axios.post(`${API_URL.user.getUserById}`, condition, { headers: authHeader() });
  }

  update(user){
    return axios.post(`${API_URL.user.update}`, user, { headers: authHeader() });
  }
  delete(user){
    return axios.post(`${API_URL.user.delete}`, user, { headers: authHeader() });
  }
  getProfile(user){
    return axios.post(`${API_URL.user.getProfile}`, user, { headers: authHeader() });
  }
}

export default new UserService();
