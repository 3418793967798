import React, {Component} from 'react'
import Pagination from "react-js-pagination";
import PAGINATION from '../../constants/pagination.constant'

export default class ClinicPagination extends Component {
      constructor(props){
          super(props)
          this.state = {
            activePage: 1
          }
      }
      handlePageChange = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber});
        this.props.onChange(pageNumber)
      }
     
      render() {
        return (
          <div>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={this.state.activePage}
              itemsCountPerPage={PAGINATION.records}
              totalItemsCount={parseInt(this.props.totalItemsCount)}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        );
      }


}