import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class VisitHistoryService {

  register(visit) {
    return axios.post(`${API_URL.visithistory.register}`, visit ,{ headers: authHeader() });
  }

  getDispensaryList(){
    return axios.post(`${API_URL.visithistory.getDispensaryList}`, { headers: authHeader() });
  }

  getVisitByIc(visit){
    return axios.post(`${API_URL.visithistory.getVisitByIc}`, visit ,{ headers: authHeader() });
  }
  getVisit(visit){
    return axios.post(`${API_URL.visithistory.getVisit}`, visit ,{ headers: authHeader() });
  }
  getDispensaryListForPatientBuyOverCounter(){
    return axios.post(`${API_URL.visithistory.getDispensaryListForPatientBuyOverCounter}`, { headers: authHeader() });
  }
  remove(visit){
    return axios.post(`${API_URL.visithistory.remove}`, visit, { headers: authHeader() });
  }

}

export default new VisitHistoryService();
