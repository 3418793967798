import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class PaymentService {

  getPayments() {
    return axios.get(`${API_URL.payment.getPayments}`, { headers: authHeader() });
  }

}

export default new PaymentService();
