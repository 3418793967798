
const CARD_TYPE = {
    LocalDebit:1,
    LocalCredit:2,
    ForeignCard:3,
    AmericanExpress:4,
    UnionPay:5,
    JCB:6
};


export default CARD_TYPE