import React, {Component} from 'react'
import {Form, Button, Table} from 'react-bootstrap'
import medicineService from '../../services/medicine.service'
import paymentService from '../../services/payment.service'
import {isNotPanel} from '../../helpers/utility'
import Autocomplete from '../shared/dropdown.component'
import {isRoleAuthorize} from '../../helpers/utility'

export default class MedicationAndCharges extends Component {
    discount = 0;
    constructor(props){
        super(props)

        this.state = {
            drugs: [],
            drugId: 0,
            drugName: '',
            qty:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
            selectQty:1,
            amount: 0,
            prescription: '',
            notes: '',
            baseCost: 0,
            cost:0,
            basePrice:0,
            price:0,
            medications: [],
            net:0,
            total:0,
            // selectDrFee: 0,
            // selectDiscount: 0,
            index: 0,
            payments : [],
            points: false,
            loyalty: 0,
        }

        this.renderTableHeader = this.renderTableHeader.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
        this.handlePrescription = this.handlePrescription.bind(this)
        this.handleDrug = this.handleDrug.bind(this)
        this.handleQty = this.handleQty.bind(this)
        this.remove = this.remove.bind(this)
        this.handleAddMedications = this.handleAddMedications.bind(this)
        this.handleDrFee = this.handleDrFee.bind(this)
        this.handleDiscount = this.handleDiscount.bind(this)
        this.renderPercent = this.renderPercent.bind(this)
    }

   
    componentDidMount(){

        var quantity = this.state.qty.map((x, index) => {
            return <option key={index} value={x}>{x}</option>
        })

        this.setState({
            qty: quantity
        })

        medicineService.getMedicines().then(result =>{
            if(result){
                let drugs = result.data.filter(x => {
                    return x.inactive == 0
                })
                drugs = drugs.map((data, index) =>{
                    return <option key={index} value={data.id}>{data.name}</option>
                })
                this.setState({
                    drugs
                })

            }
        })

        paymentService.getPayments().then(result => {
            if(result){
                this.setState({
                    payments: result.data
                })
            }
        })

        let percent = []
        for(var i=0;i<100;i++){
            percent.push()
        }
    }
    remove(event){
        var id = event.target.id;
        if(this.state.medications.length == 0){
            this.props.medications[id].status = "delete"
            this.setState({
                medications: this.props.medications
            },() => {
                this.props.handleNetAndTotal(this.props.drfee, this.props.discount)
            })
        }
        else{
       
            this.state.medications[id].status = "delete"
            this.setState({
                medications: this.state.medications
            },()=> {
                this.props.handleNetAndTotal(this.props.drfee, this.props.discount)
            })
        }

       
    
    }
    renderTableHeader() {
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">Drug Name</th>
                <th key="2">Qty</th>
                {
                     isRoleAuthorize(['superadmin','doctor']) ? (
                        <th key="3">Cost</th>
                     ) : <></>
                }
                <th key="4">Price</th>
                <th key="5">Total Amount</th>
                <th key="6">Prescription</th>
                <th key="7">Notes</th>
            </tr>
        );
    }
    
    renderTableData(medications) {
      
        if(medications!=null){
            return medications.map((data,index) => {
                let status = data.status != "delete" == true
                if(status){
                    return (
                        <tr key={index}>
                        <td>{index}</td>
                        <td>{data.drugName}</td>
                        <td>{data.selectQty}</td>
                        {
                            isRoleAuthorize(['superadmin','doctor']) ? (
                                <td>{data.cost}</td>
                            ) : <></> 
                        }
                        <td>{data.amount}</td>
                        <td>{data.amount*data.selectQty}</td>
                        <td>{data.prescription}</td>
                        <td>{data.notes}</td>
                        <td><Button id={index} onClick={this.remove}>Remove</Button></td>
                    </tr>
                    )
                }
            })  
        }
        else {

    
        return this.state.medications.map((data,index) => {
            let status = data.status != "delete" == true
            if(status){
                return (
                    <tr key={index}>
                    <td>{index}</td>
                    <td>{data.drugName}</td>
                    <td>{data.selectQty}</td>
                    <td>{data.selectQty}</td>
                    {
                        isRoleAuthorize(['superadmin','doctor']) ? (
                            <td>{data.cost}</td>
                        ) : <></> 
                    }
                    <td>{data.amount}</td>
                    <td>{data.amount*data.selectQty}</td>
                    <td>{data.prescription}</td>
                    <td>{data.notes}</td>
                    <td><Button id={index} onClick={this.remove}>Remove</Button></td>
                </tr>
                )
            }
        })  

    }
           
        
    }

    renderPercent() {
        let data =[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,
        29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,
        58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,
        88,89,90,91,92,93,94,95,96,97,98,99,100]
        return data.map((x,index) => {
            return (
                <option key={index} value={x}>{x}%</option>
            )
        })
    }

    handleDiscount(event) {
        let discount = event.target.value
        // this.setState({
        //     selectDiscount: discount
        // })
        this.props.handleChange(event)
        this.props.handleNetAndTotal(this.props.drfee, discount)
    }
    handleDrFee(event) {
        // this.setState({selectDrFee:event.target.value})
        this.props.handleChange(event)
        this.props.handleNetAndTotal(event.target.value,this.discount )
    }
    handleQty(event) {
        let qty = event.target.value
        //const {cost, price} = this.state
        this.setState({
            selectQty: qty,
            cost: this.state.cost,
            amount: (isNotPanel(this.state.payments, this.props.payment) ? this.state.price : this.state.panelprice) * qty
        })
    }
    handleDrug(event) {
        let id = event.target.value
        let condition = {
            id
        }
        medicineService.getMedicineById(condition).then(result => {
            if(result)
            {
                const { id, cost, name, price, panelprice} = result.data
                this.setState({
                    drugId: id,
                    drugName: name,
                    price,
                    panelprice,
                    cost,
                    price,
                    selectQty:1,
                    amount: isNotPanel(this.state.payments, this.props.payment) ? price : panelprice
                })
            }
        })
    }
    handleDrugMobile = (id) => {
        let condition = {
            id
        }
        medicineService.getMedicineById(condition).then(result => {
            if(result)
            {
                const { id, cost, name, price, panelprice} = result.data
                this.setState({
                    drugId: id,
                    drugName: name,
                    price,
                    panelprice,
                    cost,
                    price,
                    selectQty:1,
                    amount: isNotPanel(this.state.payments, this.props.payment) ? price : panelprice
                })
            }
        })
    }

    // isNotPanel = (lsPayment, paymentId) => {
    //     let paymentMethod = lsPayment.filter(x => {
    //         return x.id == paymentId
    //     })
    //     if(paymentMethod.length == 0) return true
    //     const payment = ['Cash','CreditCard']
    //     let status = payment.includes(paymentMethod[0].name)
    //     return status
    // }

    handleAddMedications(){
        var medic = this.props.medications;
        let paymentId = this.props.payment
        const { index, drugId, drugName, selectQty, cost, amount, prescription, notes, price, panelprice, status} = this.state;
        var count = index;
        medic.push({index: count, drugId, drugName, selectQty , cost, amount: isNotPanel(this.state.payments, paymentId) ? price : panelprice, prescription, notes, status: "new"})
        count++
        this.setState({
            medications: medic,
            index: count
        })
        this.props.handleAddMedications(medic)
        this.props.handleNetAndTotal(this.props.drfee, this.props.discount)
    }

    handlePrescription(event){
        this.setState({
            prescription: event.target.value
        })
    }

  
    render(){

        if (this.props.loyalty && this.props.discount == null) {
            if (this.props.points > 1000) {
                this.discount = 5;
            }
            else if (this.props.points > 5000) {
                this.discount = 10;
            }
        }
        else {
            this.discount = this.props.discount;
        }
       
        return(
            <>
            <div>
             <div className="row">
                <div className="col-lg-4">
                    <Form.Group controlId="formPayment">
                        <Form.Label>Drug</Form.Label>
                        <div className="drugDesktop">
                        <Form.Control as="select" onChange={this.handleDrug} required>
                            <option value=""></option>
                            {this.state.drugs}
                        </Form.Control>
                        </div>
                        <div className="drugMobile">
                            <Autocomplete handleDrug={this.handleDrugMobile}/>
                        </div>
                        
                       
                    </Form.Group>
                </div>
                <div className="col-lg-4">
                    <Form.Group controlId="formPrescription">
                        <Form.Label>Prescription</Form.Label>
                        <Form.Control type="text"  name="prescription" placeholder="prescription" value={this.state.prescription || ''} onChange={this.handlePrescription} />
                    </Form.Group>
                </div>
                <div className="col-lg-1">
                    <Form.Group controlId="formQty">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control as="select" value={this.state.selectQty} onChange={this.handleQty} required>
                            <option value=""></option>
                            {this.state.qty}
                        </Form.Control>
                    </Form.Group>
                </div>
                {
                     isRoleAuthorize(['superadmin','doctor']) ?  (
                        <div className="col-lg-1">
                        <Form.Group controlId="formCost">
                            <Form.Label>Cost</Form.Label>
                            <Form.Control type="text"  name="cost" readOnly placeholder="cost" value={this.state.cost || ''} />
                        </Form.Group>
                    </div>
                    ) : <></>
                }
               
                <div className="col-lg-1">
                    <Form.Group controlId="formPrice">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="text"  name="amount" readOnly placeholder="price" value={ isNotPanel(this.state.payments,  this.props.payment) ? this.state.price : this.state.panelprice} />
                    </Form.Group>
                </div>
               
                <div className="col-lg-1">
                    <Form.Group controlId="formAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control type="text"  name="amount" readOnly placeholder="0" value={  this.state.amount } />
                    </Form.Group>
                </div>
                
               
              </div>
              <div className="row">
               
                <div className="col-lg-3">
                    <Button variant="primary" onClick={this.handleAddMedications}>
                        Add
                    </Button>
                </div>
              </div>
           
             <br></br>
              <hr></hr>
              <div className="row table-wrapper-scroll-y my-custom-scrollbar">
            
                    <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.props.medications.length > 0 ? this.renderTableData(this.props.medications):
                            this.renderTableData(null)
                            }
                         
                        </tbody>
                    </Table>
              
                </div>
                <hr></hr>
                <br></br>
                <div className="row">
                <div className="col-lg-4">
                    <Form.Group controlId="formFee">
                        <Form.Label>Doctor Fees</Form.Label><span className="errorMsg">*</span>
                        <Form.Control name="drfee" as="select" value={this.props.drfee} onChange={this.handleDrFee} required>
                            <option value=""></option>
                            <option value="0">RM0</option>
                            <option value="5">RM5</option>
                            <option value="10">RM10</option>
                            <option value="15">RM15</option>
                            <option value="20">RM20</option>
                            <option value="25">RM25</option>
                            <option value="30">RM30</option>
                            <option value="35">RM35</option>
                            <option value="40">RM40</option>
                            <option value="45">RM45</option>
                            <option value="50">RM50</option>
                            <option value="55">RM55</option>
                            <option value="60">RM60</option>
                            <option value="65">RM65</option>
                            <option value="70">RM70</option>
                        </Form.Control>
                        <div className="errorMsg">{this.props.errors.drfee}</div>
                    </Form.Group>
                </div>
                <div className="col-lg-1">
                    <Form.Group controlId="formDiscount">
                        <Form.Label>Discount</Form.Label>
                        <Form.Control name ="discount" as="select" value={this.discount || ' '} onChange={this.handleDiscount} >
                            <option value=""></option>
                            {this.renderPercent()}
                        </Form.Control>
                    </Form.Group>
                </div>
              </div>
              <div className="row">
              {
                isRoleAuthorize(['superadmin','doctor']) ? (
                    <div className="col-lg-4">
                        <Form.Group controlId="formNet">
                            <Form.Label>Net</Form.Label>
                            <Form.Control type="text"  name="net" readOnly placeholder="net" value={this.props.net || ''} />
                        </Form.Group>
                    </div>
                ) : <></> 
              }

               
                <div className="col-lg-4">
                    <Form.Group controlId="formTotal">
                        <Form.Label>Total</Form.Label>
                        <Form.Control type="text"  name="total" readOnly placeholder="total" value={this.props.total || ''} />
                    </Form.Group>
                </div>
              </div>
            
              

            </div>
           
            </>
        );
    }
}