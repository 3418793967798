import React, {Component} from 'react'
import {Form, Button, Table} from 'react-bootstrap'
import NavBar from '../nav-bar.component'
import ConsultationService from '../../services/consultation.service'
import McService from '../../services/mc.service'
import paymentService from '../../services/payment.service'
import axios from 'axios';
import ClinicToast from '../shared/toast'
import history from '../../history'
import { Redirect} from 'react-router-dom'
import CARD_TYPE from '../../constants/card-type.constant';
import PAYMENT_METHOD from '../../constants/payment-method.constant';
import {renderCardDeduct} from '../../helpers/utility'
import CounterService from '../../services/counter.service'
import JournalService from '../../services/journal.service'
import journalDescriptionService from '../../services/journal-description.service'
import medicineService from '../../services/medicine.service'
import {isNotPanel, toDate} from '../../helpers/utility'

export default class PatientBuyMedicineDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            qty:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
            selectQty: 1,
            dispensary: [],
            medications:[],
            lsPayment: [],
            mcs: [],
            payment: '',
            showToast: false,
            redirect: null,
            details: false,
            cardtype: '',
            cardDeductTotal: 0
        }
    }

    componentDidMount = () => {
        var visitId = this.props.match.params.id;
        //var paymentId = this.props.match.params.paymentId

        this.setState({
            visitId
        })

        let conditions = {
            visitId
        }

        var quantity = this.state.qty.map((x, index) => {
            return <option key={index} value={x}>{x}</option>
        })

        this.setState({
            qty: quantity
        })

        const consultation =ConsultationService.getPatientConsultation(conditions)
        const medicines = ConsultationService.getPatientMedicines(conditions)
        const mc = McService.getMcs(conditions)
        const paymentMethod = paymentService.getPayments()
        const drugs =  medicineService.getMedicines()

        axios.all([consultation, medicines, mc, paymentMethod, drugs]).then(axios.spread((...responses) => {
            const lsConsult = responses[0].data
            const lsMed = responses[1].data
            const lsMc = responses[2].data
            const lsPayment = responses[3].data
            const lsDrugs = responses[4].data.filter(x => {
                return x.inactive == 0
            })
            const { visitId, name, patientId, doctor, drfee, discount, paymentId, cardType } = lsConsult[0]
            
            let drugs = lsDrugs.map((data, index) =>{
               return <option key={index} value={data.id}>{data.name}</option>
            })

            this.setState({
                 visitId,
                 patientId,
                 name,
                 doctor,
                 payment : paymentId,
                 drfee,
                 discount,
                 cardType,
                 drugs
                 
            })

            var notPanel = isNotPanel(lsPayment, paymentId)

            // var pays = lsPayment.filter( x=> {
            //     return (x.name == 'Cash' || x.name == 'CreditCard')
            // })


            var pays = lsPayment.map((data, index)=>
            <option key={index} value={data.id}>{data.name}</option>
            )

            this.setState({
                payments: pays,
                lsPayment
            })


            let med = lsMed.map((data, index) =>{
                return {
                    drugName: data.name,
                    drugId: data.medicineId,
                    selectQty: data.quantity,
                    cost: data.cost,
                    amount: notPanel ? data.price : data.panelprice,
                    prescription: data.prescription,
                    notes: data.notes
                }
            })

            this.setState({
                medications: med
            })

            this.setState({
                mcs: lsMc
            })

            this.handleNetAndTotal(drfee, discount)

        })).catch(errors => {
            // react on errors.
            console.log(errors)
          })


    }

    handleChange = (event) => {

        const value =  event.target.value;
        const name = event.target.name;
    
        this.setState({
          [name]: value
        });

        // this.setState({
        //     cardtype: event.target.value
        // })
        if(name == 'cardType'){
            let info = {
                visitId: this.state.visitId,
                cardType: parseInt(event.target.value)
            }
            ConsultationService.updCardType(info).then(result => {
              
            })

        }else if(name == 'payment'){
            let info = {
                visitId: this.state.visitId,
                paymentMethod: parseInt(event.target.value)
            }
            ConsultationService.updPaymentMethod(info).then(result => {
                if(result){
                    window.location.reload(false)
                }
            })
        }
        
    }
    handleReceipt = () => {

        let receiptNo = ""
        let info = {
            visitId: parseInt(this.state.visitId)
        }

        ConsultationService.getInvoiceNoByVisitId(info).then(result => {
            if(result){
                receiptNo = result.data.receiptNo
                if(receiptNo != null){
                    this.setState({
                        redirect: `/receipt/${this.state.visitId}/${receiptNo}/${this.state.details}`
                    })
                }
                if(receiptNo == null){
                    ConsultationService.getInvoiceNo().then(result => {
                        if(result){
                            receiptNo = result.data
                            this.setState({
                                redirect: `/receipt/${this.state.visitId}/${receiptNo}/${this.state.details}`
                            })
                        }
                       
                    })
                }
            }
        })
       
    }

    getTotalCost=() => {
        if(this.state.medications!=null){
            return this.state.medications.map(x => { return x.cost*x.selectQty }).reduce(function(a, b){
                return a + b;
                }, 0);
    
        }
    }

    addReceipt = (receiptNo)=>{
        this.setState({
            invoiceNo: receiptNo
        })
        let info = {
            visitId: parseInt(this.state.visitId),
            receiptNo: receiptNo
        }
        ConsultationService.addReceipt(info).then(result => {
            if(result){
                ConsultationService.updInvoiceCounter().then(result => {

                })
            }
        })
    }


    handleComplete= () => {

        let info = {
            visitId: parseInt(this.state.visitId)
        }

        ConsultationService.getInvoiceNoByVisitId(info).then(result => {
            if(result){
                var receipt = result.data.receiptNo
                if(receipt == null){
                    ConsultationService.getInvoiceNo().then(result => {
                        if(result){
                            this.addReceipt(result.data)
                        }
                    })
                }
            }
        })

        let med = {}
        med["medicine"] = this.state.medications.map(x =>{
            return {
                visitId: this.state.visitId,
                medicineId: x.drugId,
                quantity: x.selectQty,
                status: x.status == null ? "" : x.status,
                cost: x.cost,
                price: x.amount,
                name: x.drugName
            }
        })
    
        ConsultationService.updateMedicineOverCounter(med).then(result => {
            if(result){
                ConsultationService.complete({visitId: this.state.visitId}).then(result => {
                    if(result){
                        //add #1 transaction
                        CounterService.getJournalId().then(result => {
                            if(result){
                                let journalId = result.data
                                let journalCash = {
                                    journal_id: journalId,
                                    date: toDate(new Date()),
                                    accountId: 0,
                                    debit: 0,
                                    credit: 0
                                }
        
                                journalCash.accountId = 1 //cash account
                                journalCash.debit = this.state.total
                                JournalService.add(journalCash).then(result => {
                                    console.log('add cash')
                                })
                                
                                let journalRevenue = {
                                    journal_id: journalId,
                                    date: toDate(new Date()),
                                    accountId: 0,
                                    debit: 0,
                                    credit: 0
                                }
                                journalRevenue.accountId = 2 //consultation revenue account
                                journalRevenue.credit = this.state.total
                                JournalService.add(journalRevenue).then(result => {
                                    console.log('add consultation revenue')
                                    let desc = {
                                        journal_id: journalId,
                                        description: 'Consultation Revenue'
                                    }
                                    journalDescriptionService.add(desc).then()
                                })
        
                                //add #2 transaction
                                CounterService.getJournalId().then(result => {
                                    if(result){
                                        let journalId = result.data
                                        let journalInventory = {
                                            journal_id: journalId,
                                            date: toDate(new Date()),
                                            accountId: 0,
                                            debit: 0,
                                            credit: 0
                                        }
        
                                        let cost = this.getTotalCost()
                                        journalInventory.accountId = 37 //Inventory account
                                        journalInventory.credit = cost
                                        JournalService.add(journalInventory).then(result => {
                                            console.log('add Inventory')
                                        })
        
                                        let journalCOGS = {
                                            journal_id: journalId,
                                            date: toDate(new Date()),
                                            accountId: 0,
                                            debit: 0,
                                            credit: 0
                                        }
        
                                        journalCOGS.accountId = 38 //Cost Of Good Sold
                                        journalCOGS.debit = cost
                                        JournalService.add(journalCOGS).then(result => {
                                            console.log('add cogs')
                                            let desc = {
                                                journal_id: journalId,
                                                description: 'Cost Of Good Sold'
                                            }
                                            journalDescriptionService.add(desc).then(result => {
                                                if(result){
                                                    this.setState({
                                                        redirect: '/patient/buymedicine'
                                                    })
                                                }
                                            })
                                        })
        
                                    }
        
                                })
        
        
                            }
        
                        })
        
                        this.setState({
                            showToast: true,
                            message:'Complete...',
                            bgSucces: true
                        })        
                    }
                })



            }

        }

        
        )

    }

    handleNetAndTotal = (drFee, discount) => {
    
        var total = 0;

        // Getting sum of numbers
        var medications = this.state.medications.filter(x => {
            return x.status != 'delete'
        })
        var sum = medications.map(x => { return x.amount*x.selectQty }).reduce(function(a, b){
        return a + b;
        }, 0);

        var cost = medications.map(x => { return x.cost*x.selectQty }).reduce(function(a, b){
            return a + b;
            }, 0);

        if(discount != undefined){
            var i = (100 - discount)/100
            if(drFee == ""){
                total = sum  * i
            }
            else{
                total = (sum + parseInt(drFee)) * i
            }
           
        }
        else
        {
            if(drFee == undefined){
                total = sum;
            }
            else{
                total = sum + parseInt(drFee)
            }
            
        }
        this.setState({
            total,
            net: total - cost
        })
       
    }

    handleCloseToast = () => {
        this.setState({
            showToast: false
        })
    }
    // isNotPanel = (lsPayment, paymentId) => {
    //     let paymentMethod = lsPayment.filter(x => {
    //         return x.id == paymentId
    //     })
    //     if(paymentMethod.length == 0) return true
    //     const payment = ['Cash','CreditCard']
    //     let status = payment.includes(paymentMethod[0].name)
    //     return status
    // }

    handleQty = (event)=> {
        let qty = event.target.value
        //const {cost, price} = this.state
        this.setState({
            selectQty: qty,
            cost: this.state.cost ,
            amount: (isNotPanel(this.state.lsPayment, this.state.payment) ? this.state.price : this.state.panelprice) * qty
        })
    }
    handleDrug = (event) => {
        let id = event.target.value
        let condition = {
            id
        }
        medicineService.getMedicineById(condition).then(result => {
            let panel = isNotPanel(this.state.lsPayment, this.state.payment)
            if(result)
            {
                const { id, cost, name, price, panelprice} = result.data
                this.setState({
                    drugId: id,
                    drugName: name,
                    price,
                    panelprice,
                    cost,
                    price,
                    selectQty:1,
                    amount: (panel ? price : panelprice)*this.state.selectQty
                })
            }
        })
    }
    handleAddMedications = () =>{
        let total = this.state.total
        let medic = this.state.medications
        const { index, drugId, drugName, selectQty, cost, amount, prescription, notes, price, panelprice, payment, status} = this.state;
        let panel = isNotPanel(this.state.lsPayment, payment)
        total = total + ((panel ? price : panelprice) * selectQty)
        let cardDeductTotal=renderCardDeduct(this.state.cardType, total)
        var count = index;
        medic.push({index: count, drugId, drugName, selectQty , cost, price: panel ? price : panelprice, prescription, notes, status: "new"})
        count++
        this.setState({
            medications: medic,
            index: count,
            cardDeductTotal,
            total
        })
       
    }

    remove = (event) =>{
        let total = this.state.total
        var id = event.target.id;
        this.state.medications[id].status = "delete"
        let amount = total - (this.state.medications[id].price * this.state.medications[id].selectQty)
        let cardDeductTotal=renderCardDeduct(this.state.cardType, amount)
        this.setState({
            medications: this.state.medications,
            total: amount,
            cardDeductTotal
        })

    }

    renderTableHeader() {
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">Drug Name</th>
                <th key="2">Qty</th>
                {/* <th key="3">Cost</th> */}
                <th key="4">Price</th>
                <th key="5">Amount</th>
            </tr>
        );
    }
    
    renderTableData = () => {
      
        return this.state.medications.map((data,index) => {
            let status = data.status != "delete" == true
            if(status){
                return (
                    <tr key={index}>
                    <td>{index}</td>
                    <td>{data.drugName}</td>
                    <td>{data.selectQty}</td>
                    {/* <td>{data.cost}</td> */}
                    <td>{data.price}</td>
                    <td>{data.price*data.selectQty}</td>
                    <td><Button id={index} onClick={this.remove}>Remove</Button></td>
                </tr>
                )
            }
        })  
       
    }

   

    formatDate = (today) => {
        if(today == undefined) return
        var pDate = new Date(today)
        let dd = pDate.getDate();
        let mm = pDate.getMonth()+1; 
        const yyyy = pDate.getFullYear();
        
        if(dd<10) 
        {
            dd=`0${dd}`;
        } 

        if(mm<10) 
        {
            mm=`0${mm}`;
        }

        return `${dd}-${mm}-${yyyy}`;
    }

   
    handleDetails = (event) =>{
        var check = event.target.checked
        this.setState({
            details: check
        })
    }


    render(){
        if (this.state.redirect != null) {
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
            <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
            </div>
            
            <div className="content">
            <h5>Dispensary of {this.state.name}</h5>
               
                <Form className = "formBig" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3">
                                <Form.Group controlId="formIcNo">
                                    <Form.Label>Identification No</Form.Label>
                                    <Form.Control type="text" value={this.state.patientId || ''} name="icno" placeholder="Ic No" readOnly  />
                                </Form.Group>
                            </div>
                            <div className="col-lg-5">
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" value={this.state.name || ''} name="name" readOnly  />
                                </Form.Group>
                            </div>
                            <div className="col-lg-2">
                                <Form.Group controlId="formPayment">
                                    <Form.Label>Payment</Form.Label>
                                    {/* <Form.Control type="text" value={this.state.payment} name="payment" readOnly  /> */}
                                    <Form.Control name="payment" as="select" value={this.state.payment} onChange={this.handleChange}>
                                        <option value=""></option>
                                        {this.state.payments}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-lg-2">
                            {this.state.payment==PAYMENT_METHOD.CreditCard ? (
                                <Form.Group controlId="formCardType">
                                <Form.Label>CardType</Form.Label>
                                <Form.Control name="cardType" as="select" value={this.state.cardType} onChange={this.handleChange} required>
                                    <option value=""></option>
                                    <option value={CARD_TYPE.LocalDebit}>Local Debit</option>
                                    <option value={CARD_TYPE.LocalCredit}>Local Credit</option>
                                    <option value={CARD_TYPE.ForeignCard}>Foreign Card</option>
                                    <option value={CARD_TYPE.AmericanExpress}>American Express</option>
                                    <option value={CARD_TYPE.UnionPay}>Union Pay</option>
                                    <option value={CARD_TYPE.JCB}>JCB</option>
                                    
                                </Form.Control>
                                </Form.Group>
                            ) : <></>}
                            </div>
                        </div>
                      
                        {/* <div className="row table-wrapper-scroll-y my-custom-scrollbar"> */}
                        <br></br>Medicines<br></br>
                        <div className="row">
                            <div className="col-lg-4">
                                <Form.Group controlId="formPayment">
                                    <Form.Label>Drug</Form.Label>
                                    <Form.Control as="select" onChange={this.handleDrug} required>
                                        <option value=""></option>
                                        {this.state.drugs}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-lg-1">
                                <Form.Group controlId="formQty">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control as="select" value={this.state.selectQty} onChange={this.handleQty} required>
                                        <option value=""></option>
                                        {this.state.qty}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            {/* <div className="col-lg-1">
                                <Form.Group controlId="formCost">
                                    <Form.Label>Cost</Form.Label>
                                    <Form.Control type="text"  name="cost" readOnly placeholder="cost" value={this.state.cost || ''} />
                                </Form.Group>
                            </div> */}
                            <div className="col-lg-1">
                                <Form.Group controlId="formPrice">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="text"  name="price" readOnly placeholder="price" value={  isNotPanel(this.state.lsPayment, this.state.payment) ? this.state.price : this.state.panelprice} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-1">
                                <Form.Group controlId="formAmount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control type="text"  name="amount" readOnly placeholder="0" value={  this.state.amount } />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
               
                            <div className="col-lg-3">
                                <Button variant="primary" onClick={this.handleAddMedications}>
                                    Add
                                </Button>
                            </div>
                        </div>
                        {/* </div> */}
                        <br></br>
                        <hr></hr>
                    <div className="row table-wrapper-scroll-y my-custom-scrollbar">
            
                    <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {
                            this.renderTableData(null)
                            }
                         
                        </tbody>
                    </Table>
              
                </div>
                <hr></hr>


                        <div className="row">
                           
                            <div className="col-lg-3">
                                <Form.Group controlId="formCardDeduct">
                                    <Form.Label>Card Deduction</Form.Label>
                                    <Form.Control type="text" value={this.state.cardDeductTotal || ''} name="discount" readOnly  />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3">
                                <Form.Group controlId="formTotal" style={{fontWeight:"bold", color: "red"}}>
                                    <Form.Label>Total</Form.Label>
                                    <Form.Control type="text" value={'RM ' + this.state.total || ''} name="total" style={{fontWeight:"bold", color: "red"}} readOnly  />
                                </Form.Group>
                            </div>
                        </div>


                       
                </Form>
                </div>
                <br></br><br></br>
                <div className="consultationFooter">
                    <div className="row">
                    <Form.Group controlId="x">
                        <div className="col-lg-2">
                            <Button type="button" onClick={this.handleComplete}>Complete</Button>
                        </div>
                        </Form.Group>
                        {/* <Form.Group controlId="y">
                        <div className="col-lg-2">
                            <Button type="button" onClick={this.handleReceipt}>Receipt</Button>
                        </div>
                        </Form.Group>
                        <Form.Group controlId="z">
                        <div className="col-lg-1" style={{marginTop:"10px"}}>
                        
                            <Form.Check type="checkbox" label="details?" name="detail" onClick={this.handleDetails} />
                      
                        </div>
                        </Form.Group> */}
                       
                    </div>
                </div>
            </>
        )
    }

}