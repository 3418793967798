import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class JournalService {

  add(data) {
    return axios.post(API_URL.journal.add, data,{ headers: authHeader() });
  }

  update(data) {
    return axios.post(API_URL.journal.update, data,{ headers: authHeader() });
  }

  delete(data) {
    return axios.post(API_URL.journal.delete, data,{ headers: authHeader() });
  }

  getJournal(data) {
    return axios.post(API_URL.journal.getJournal, data, { headers: authHeader() });
  }
  
  getJournalByJournalId(data) {
    return axios.post(API_URL.journal.getJournalByJournalId, data, { headers: authHeader() });
  }

  getLedgerByAccount(data){
    return axios.post(API_URL.journal.getLedgerByAccount, data, { headers: authHeader() });
  }

  getLedgerBalance(data){
    return axios.post(API_URL.journal.getLedgerBalance, data, { headers: authHeader() });
  }
  
  getIncomeStatement(data){
    return axios.post(API_URL.journal.getIncomeStatement, data, { headers: authHeader() });
  }

  test() {

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    let data = {
      client_id: 'ipss',
      grant_type: 'password',
      username: 'epicshira_prod',
      password: 'Prod@20200907'
    }
    const params = new URLSearchParams();
    params.append('client_id', 'ipss');
    params.append('grant_type', 'password');
    params.append('username', 'epicshira_prod');
    params.append('password', 'Prod@20200907');

    return axios.post(API_URL.test.getToken, params);
  }

}

export default new JournalService();
