import React, {Component} from 'react'
import NavBar from '../../nav-bar.component'
import {Form, Button} from 'react-bootstrap'
import UserService from '../../../services/user.service'
import { Redirect} from 'react-router-dom'
import history from '../../../history'
import axios from 'axios';
import ClinicToast from '../../shared/toast'
import Authorization from '../../../helpers/auth'

class UserAdd extends Component {
    constructor(props){
        super(props)
        this.state = {
            name: '',
            username: '',
            password: '',
            redirect: null,
            userType: [],
            userRole: [],
            showToast: false,
            user: {},
            title: 'Add New User',
            editMode: false
        }
    }

    componentDidMount = () =>{
        var id = this.props.match.params.id
        if(id > 0){
            UserService.getUserById({id}).then(result => {
                if(result){
                    const {name, username, password, userrole, usertype} = result.data
                    this.setState({
                        userId: id,
                        name,
                        username,
                        password,
                        type: usertype,
                        role: userrole,
                        title: "edit user",
                        editMode: true
                    })
                }
            })
        }
        const userType =UserService.getUserType()
        const roles = UserService.getRoles()
       
        axios.all([userType, roles]).then(axios.spread((...responses) => {
            const lsType = responses[0].data
            const lsRoles = responses[1].data

            var type =  lsType.map((user, index) => {
                const { id, typename} = user //destructuring
                return (
                    <option  key={index} value={id}>{typename}</option>
                )
            })

            var roles = lsRoles.map((user, index) => {
                const { id, name} = user //destructuring
                return (
                    <option  key={index} value={id}>{name}</option>
                )
            })

            this.setState({
                userType: type,
                roles
            })

        })).catch(errors => {
            // react on errors.
            console.log(errors)
        })
    }

    handleSelect = (event) => {
    
        var value= event.target.value
        var name = event.target.name

        this.setState({
            [name]: value
          });
    }
  
    handleCancel = () => {
        this.setState({ redirect: `/maintenance/user`});
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }
    handleUpdate = (event) =>{
        event.preventDefault()
        const {userId, name, password, type, role} = this.state;

        let user = {}
        
        user["id"] = userId
        user["name"] = name
        user["password"] = password
        user["usertype"] = type
        user["userrole"] = role

        UserService.update(user).then(
            response => {
                this.setState({
                    showToast: true,
                    message:'Update successfully',
                    bgSucces: true
                })
            },
            error => {
              if(error.response && error.response.data){
                this.setState({
                    showToast: true,
                    message:error.response.data.message,
                    bgSucces: false
                })
              }
            
            }
          );
        
    }
    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
    }
    handleSubmit = (event) => {
       
        event.preventDefault();

        const {username, name, password, type, role} = this.state;
        let user = {}
        user["username"] = username
        user["name"] = name
        user["password"] = password
        user["usertype"] = type
        user["userrole"] = role
             
        UserService.add(user).then(
            response => {
                this.setState({
                    showToast: true,
                    message:'This user added successfully',
                    bgSucces: true
                })
            },
            error => {
              if(error.response && error.response.data){
                this.setState({
                    showToast: true,
                    message:error.response.data.message,
                    bgSucces: false
                })
              }
            
            }
          );
    }
    
    render(){
        if (this.state.redirect != null) {
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return(
            <>
            <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
                </div> 
                <div className="content">
                    <h2>{this.state.title}</h2>
                    <form className = "formClinic" onSubmit={this.state.editMode ?  this.handleUpdate : this.handleSubmit }>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name='name' autoComplete="off" placeholder="name" onChange={this.handleInputChange} value={this.state.name} required  />
                        </Form.Group>
                        <Form.Group controlId="formName">
                            <Form.Label>User Name</Form.Label>
                            {this.state.editMode ? (
                            <Form.Control type="text" name="username" autoComplete="off" placeholder="username" readOnly value={this.state.username}/>
                            ):(
                                <Form.Control type="text" name="username" autoComplete="off" placeholder="username" onChange={this.handleInputChange} value={this.state.username} required />
                            )
                            }
                        </Form.Group>
                        <Form.Group controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="text" name="password" autoComplete="off" placeholder="password" onChange={this.handleInputChange} value={this.state.password} required  />
                        </Form.Group>
                        <Form.Group controlId="formUserType">
                            <Form.Label>User Type</Form.Label>
                            <Form.Control as="select" name="type" onChange={this.handleSelect} value={this.state.type} required>
                                <option value=""></option>
                                {this.state.userType}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formRoles">
                            <Form.Label>Roles</Form.Label>
                            <Form.Control as="select" name="role" onChange={this.handleSelect} value={this.state.role} required>
                                <option value=""></option>
                                {this.state.roles}
                            </Form.Control>
                        </Form.Group>
                        {this.state.editMode ? (
                            <Button variant="primary" type="submit">
                                Update
                            </Button>) : (
                            <Button variant="primary" type="submit">
                            Add
                        </Button>)}
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="primary" type="button" onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    </form>
                </div>
            </>
           
        );
    }
}

export default Authorization(UserAdd, ['superadmin','doctor'])