import React, {Component} from 'react'
import { formatDate, totalMcDay } from '../../helpers/utility';

import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink  } from '@react-pdf/renderer';
import MyMc from '../document/mymc'
import McService from '../../services/mc.service'

export default class McPreview extends Component {
    constructor(props){
        super(props)
        var visitId = this.props.match.params.visitid
    
        this.state = {
            redirect: null,
            visitId,
            noSiri: 10001,
            name: '',
            icno:'',
            dateFrom: '',
            dateTo: '',
            show: false
        }

        
    }

    componentDidMount = () => {
        var visitid = this.state.visitId
        let data = {
            visitid
        }
        
        setTimeout(() => {
            this.setState({
                show: true
            })
        },5000)     

        McService.getMcDetails(data).then(result => {
            if(result.data.length > 0 ) {
            const { icno, name, dateFrom, dateTo, noSiri } =  result.data[0]
            if(result){
                this.setState({
                    icno,
                    name,
                    dateFrom: formatDate(dateFrom),
                    dateTo: formatDate(dateTo),
                    noSiri,
                    totalMcDay: totalMcDay(dateFrom, dateTo)              
                })
              }
            }
        })
    }

    render(){
        return (
            <>
                  
            <div className="content" style={{marginTop:"100px"}}>
                <div className="formMc">
                    <div className="row">
                        <div className="col-lg-12" style={{textAlign: "center"}}>                            
                            <div style={{fontWeight:"bold", fontSize:"20px"}}>
                                KLINIK DR. ENGKU BAHIYAH
                            </div>
                            <div>
                                No. 20-G, Jalan Puteri 2A/2, Bandar Puteri Bangi,
                            </div>
                            <div>
                                43000 Kajang. Tel: 03-89200548    
                            </div>  
                        </div>
                        <div className="col-lg-12">
                            <div style={{float: "right", color: "red"}}>
                                No: {this.state.noSiri}
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop:"20px"}}>
                        <div className="col-lg-12">
                            <div style={{fontWeight:"bold", textAlign: "center"}}>
                                SIJIL SAKIT
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div style={{fontWeight:"bold", textAlign: "center", textDecoration:"underline"}}>
                                SICK CERTIFICATE
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div style={{marginTop:"20px", fontSize:"small"}}>
                        Saya mengesahkan bahawa saya telah memeriksa
                        <div style={{fontStyle:"italic", margin:"0px 0px 10px 0px"}}>I hereby certify that I have examined </div>
                        {this.state.name}
                    </div>
                    </div>
                    <div className="row" style={{fontSize:"small"}}>                
                        <div style={{width:"50%"}}>
                            <div style={{marginTop:"10px"}}>No K/P</div>
                            <div style={{fontStyle:"italic", marginRight:"10px", width:"50%"}}>I/C No {this.state.icno}</div>
                        </div>
                        <div>
                            <div style={{marginTop:"10px"}}>dari Jabatan</div>
                            <div style={{fontStyle:"italic", marginRight:"10px"}}>from Department yang berkenaan</div>
                        </div>
                    </div>
                    <div className="row" style={{fontSize:"small"}}>
                        <div style={{width:"70%",marginTop:"12px"}}>   
                            <div>dan mendapati beliau tidak berupaya bertugas untuk selama</div> 
                            <div>and find that he/she will be unfit for duty for  </div>      
                        </div>
                        <div>
                            <div style={{marginTop:"10px"}}>hari</div>
                            <div style={{fontStyle:"italic", marginRight:"10px"}}>{this.state?.totalMcDay} days</div>
                        </div>   
                    </div>
                    <div className="row" style={{fontSize:"small"}}>
                        <div style={{width:"30%",marginTop:"12px"}}>   
                            <div>dari</div> 
                            <div>from  {this.state.dateFrom}</div>      
                        </div>
                        <div style={{width:"30%",marginTop:"12px"}}>
                            <div>hingga</div>
                            <div style={{fontStyle:"italic", marginRight:"10px"}}>to</div>
                        </div> 
                        <div style={{width:"30%",marginTop:"12px"}}>
                            <div>Tarikh:</div>
                            <div style={{fontStyle:"italic", marginRight:"10px"}}>Date: {this.state.dateTo}</div>
                        </div>     
                    </div>
                    <div style={{fontSize:"small"}}>
                        <div style={{marginTop:"50px", textAlign:"right"}}>   
                            <div>__________________________________</div> 
                            <div>Tandatangan/Cop Doktor/Cop Klinik</div>      
                            <div>Signature/ Doctor's Stamp/Clinic Stamp</div>      
                        </div>
                    </div>
                    <div lassName="row" style={{fontSize:"small"}}>
                        <div style={{marginTop:"12px"}}>   
                           
                            <div>Bahawa sijil ini tidak sah digunakan untuk kes mahkamah</div>      
                            <div>This certificate is not valid for court use.</div>      
                        </div>
                    </div>
                  

                    <div className="row" style={{marginTop:"40px"}}>
                        <div className={"col-lg-6 "+ ( this.state.show? "":"noclick")}>
                      
                        <PDFDownloadLink document={<MyMc visitId={this.state.visitId} />} fileName={this.state.icno}>
                             {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Print now!')}
                        </PDFDownloadLink>    
                      
                        </div>
                    </div>

                </div>
              
            </div>
            </>
        )
    }

}