import React, {Component} from 'react'
import NavBar from '../../nav-bar.component'
import {Accordion, Card, Form, Button} from 'react-bootstrap'
import ConsultationDescription from  '../../shared/consultation-description.component'
import ConsultationDocNotes from '../../shared/consultation-doctor-notes.component'
import MedicalCertification from "../../shared/consultation-medical-cert.component"
import MedicationAndCharges from "../../shared/consultation-medication-charges.component"
import ConsultationService from '../../../services/consultation.service'
import PatientService from '../../../services/patient.service'
import visitHistoryService from '../../../services/visit-history.service'
import McService from '../../../services/mc.service'
import ClinicToast from '../../shared/toast'
import paymentService from '../../../services/payment.service'
import axios from 'axios';
import VisitHistory from './visit-history.component'
import Authorization from '../../../helpers/auth'
import {isNotPanel,isRoleAuthorize,getStatusConsultation} from '../../../helpers/utility'
import PATIENT_STATUS from '../../../constants/patient-status.constant'
import history from '../../../history'
import {Redirect} from 'react-router-dom'

class DoctorAddConsultation extends Component {
    constructor(props){
        super(props)
       
        this.state = {
            datefrom: new Date(),
            dateto: new Date(),
            patientId: null,
            name: '' ,
            doctor: '',
            paymentId: 0,
            visitId: 0,
            fields: {},
            errors: {},
            section: "0",
            showToast: false,
            medications:[],
            mcs: [],
            vhistory: [],
            redirect: null
        }
    
        this.handleChange = this.handleChange.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCloseToast = this.handleCloseToast.bind(this)
        this.handleAddMedications = this.handleAddMedications.bind(this)
        this.handleNetAndTotal = this.handleNetAndTotal.bind(this)
        this.handleAddMcs = this.handleAddMcs.bind(this)
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.removeCert = this.removeCert.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this)
    }
    

    componentDidMount(){
        var visitId = this.props.visitId
        var patientId = this.props.icNo
        
        this.setState({
            visitId
        })

        let conditions = {
            visitId,
            patientId
        }

        const consultation =ConsultationService.getPatientConsultation(conditions)
        const medicines = ConsultationService.getPatientMedicines(conditions)
        const mc = McService.getMcs(conditions)
        const paymentMethod = paymentService.getPayments()
        const visitHistory = visitHistoryService.getVisitByIc(conditions)

        axios.all([consultation, medicines, mc, paymentMethod, visitHistory]).then(axios.spread((...responses) => {
            const lsConsult = responses[0].data
            const lsMed = responses[1].data
            const lsMc = responses[2].data
            const lsPayment = responses[3].data
            const lsVisit = responses[4].data.slice(0,4)

            const { visitId, name, patientId, points, loyalty, doctor, drfee, notes, diagnosis, discount, paymentId, cardType, status} = lsConsult[0]
            
            this.setState({
                 visitId,
                 patientId,
                 name,
                 doctor,
                 paymentId,
                 drfee,
                 notes,
                 diagnosis,
                 discount,
                 cardType,
                 status,
                 points,
                 loyalty,     
            })

            //var notPanel = isNotPanel(lsPayment, paymentId)
            
            if(paymentId != ""){
            let fields = this.state.fields
            fields['payment'] = paymentId
            }
            if(drfee !== ""){
            let fields = this.state.fields
            if(drfee == 0){
                fields['drfee'] = "0"
            }
            else{
                fields['drfee'] = drfee
            }
            
            }
            if(notes != ""){
            let fields = this.state.fields
            fields['cheifcomplaint'] = notes
            }
            if(diagnosis != ""){
            let fields = this.state.fields
            fields['diagnosis'] = diagnosis
            }

            let med = lsMed.map((data, index) =>{
                return {
                    drugName: data.name,
                    drugId: data.medicineId,
                    selectQty: data.quantity,
                    cost: data.cost,
                    amount: isNotPanel(lsPayment, this.state.paymentId) ? data.price : data.panelprice,
                    prescription: data.prescription,
                    notes: data.notes
                }
            })

            this.setState({
                medications: med,
                mcs: lsMc,
                vhistory: lsVisit

            })

            this.handleNetAndTotal(drfee, discount)

        })).catch(errors => {
            // react on errors.
            console.log(errors)
          })

    }

    handleRemove = () => {
        let visit = {
            visitId: this.state.visitId
        }
        visitHistoryService.remove(visit).then(() => {
            alert("success remove")
            window.location.href = '/doctor/consultation'
        })

    }

    handleChangeToPharmacy = () => {
        let info = {
            statusId: PATIENT_STATUS.Pharmacy,
            visitId: this.state.visitId
        }
        ConsultationService.setStatus(info).then(result => {
            if(result){
                this.setState({
                    showToast: true,
                    message:'Consultation is now change to pharmacy status',
                    bgSucces: true
                })
             }
        })
    }

    handleConfirm(){
        let conditions = {
            visitId: this.state.visitId
        }
 
         ConsultationService.confirm(conditions).then(result => {
             if(result){
                    this.setState({
                        showToast: true,
                        message:'Consultation is now confirm',
                        bgSucces: true,
                    })
                    setTimeout(()=> {
                        this.props.close();
                    },1000)
                   
                    
             }

         },(error)=>{
            this.setState({
                showToast: true,
                message: error.response.data.message,
                bgSucces: false
            })
        }
      )

    }

    removeCert(mcs){

        this.setState({
            mcs
        })
    }
    handleNetAndTotal(drFee, discount){
        //const {net, total} = this.state
        var total = 0;

        // Getting sum of numbers
        var medications = this.state.medications.filter(x => {
            return x.status != 'delete'
        })
        var sum = medications.map(x => { return x.amount*x.selectQty }).reduce(function(a, b){
        return a + b;
        }, 0);

        var cost = medications.map(x => { return x.cost }).reduce(function(a, b){
            return a + b;
            }, 0);

        if(discount != undefined){
            var i = (100 - discount)/100
            if(drFee == ""){
                total = sum  * i
            }
            else{
                total = (sum + parseInt(drFee)) * i
            }
           
        }
        else
        {
            if(drFee == undefined){
                total = sum;
            }
            else{
                total = sum + parseInt(drFee)
            }
            
        }
        this.setState({
            total,
            net: total - cost,
            discount
        })
       
    }

    handleAddMcs(mcs){
        this.setState({
            mcs
        })
    }

    handleAddMedications(medications){
        this.setState({
            medications
        })
    }

    handleCloseToast(){
        this.setState({
            showToast: false
        })
    }


    handleSubmit(event){

      event.preventDefault()
     
      if (this.validateForm()) {

        let consultation = {}
        let consult = this.state.fields
        consult.visitId = this.state.visitId
        consult.cardType = this.state.cardType
        consult.patientId = this.state.patientId
        consult.paymentmethod = consult.payment
        consult.doctorfee = consult.drfee
        consult.notes = consult.cheifcomplaint
        consult.discount = this.state.discount

        consultation["visithistory"] = consult
        consultation["medicine"] = this.state.medications.map(x =>{
            return {
                visitId: consult.visitId,
                medicineId: x.drugId,
                quantity: x.selectQty,
                status: x.status == null ? "" : x.status,
                cost: x.cost,
                price: x.amount,
                name: x.drugName,
                prescription: x.prescription
            }
        })
        consultation["cert"] = this.state.mcs.map(x => {
            return {
                mcno: x.mcno,
                visitId: consult.visitId,
                datefrom: x.datefrom,
                dateTo: x.dateto,
                status: x.status == null ? "" : x.status,
                nosiri: x.noSiri
            }

        })

        ConsultationService.add(consultation).then(response => {
            let mcs = this.state.mcs.filter(x => {
                return x.status != 'delete'
            })
            mcs.forEach(x => {
                x.status = ""
            })
            this.setState({
                showToast: true,
                message: response.data.message,
                bgSucces: response.data.message == 'Successfully save consultation' ? true : false,
                mcs
            })
        },(error)=>{
                this.setState({
                    showToast: true,
                    message: error.response.data.message,
                    bgSucces: false
                })
            }
        )
        
      }
        
    }
    
    handleChange(e) {

        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
          fields
        });


        if(e.target.name == 'cardType'){
            this.setState({
                cardType: e.target.value
            })
            let info = {
                visitId: this.state.visitId,
                cardType: parseInt(e.target.value)
            }
            ConsultationService.updCardType(info).then(result => {
              
            })

        }else if(e.target.name == 'payment'){
            this.setState({
                paymentId: e.target.value
            })
            let info = {
                visitId: this.state.visitId,
                paymentMethod: parseInt(e.target.value)
            }
            ConsultationService.updPaymentMethod(info).then(result => {
                if(result){
                    window.location.reload(false)
                }
            })
        }


        // if(e.target.name == 'cardType'){
        //     this.setState({
        //         cardType: e.target.value
        //     })
        // }
        // if(e.target.name == 'payment'){
        //     this.setState({
        //         paymentId: e.target.value
        //     })
        // }
        if(e.target.name == 'drfee'){
            this.setState({
                drfee: e.target.value
            })
        }
        if(e.target.name == 'discount'){
            this.setState({
                discount: e.target.value
            })
        }
        if(e.target.name == 'cheifcomplaint'){
            this.setState({
                notes: e.target.value
            })
        }
        if(e.target.name == 'diagnosis'){
            this.setState({
                diagnosis: e.target.value
            })
        }
    }

    handleFromDateChange(date){
        this.setState({
            datefrom: new Date(date)
        });
    }
    
    handleToDateChange(date){
        this.setState({
        dateto: new Date(date)
        });
    }

    validateForm() {
        let section = 0
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
  
        if (!fields["payment"]) {
          formIsValid = false;
          errors["payment"] = "*Please select payment.";
          section = "0"
        }
  
        // if (typeof fields["username"] !== "undefined") {
        //   if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
        //     formIsValid = false;
        //     errors["username"] = "*Please enter alphabet characters only.";
        //   }
        // }
  
        if (!fields["cheifcomplaint"]) {
          formIsValid = false;
          errors["cheifcomplaint"] = "*Please enter your cheif complaint.";
          section = "1" 
        }
  
        if (!fields["diagnosis"]) {
          formIsValid = false;
          errors["diagnosis"] = "*Please enter your diagnosis.";
          section = "1"
        }

        if (!fields["drfee"]){
            formIsValid = false;
            errors["drfee"] = "*Please enter your fee.";
            section = "3"
          }

        if(section == "1" || section == "3"){
            alert("Please fill in the mandatory fields")
        }

  
        this.setState({
          errors: errors,
          section
        });

        return formIsValid;

    }

    render(){
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
        }
        return(
            <>
            
            <Form onSubmit={this.handleSubmit} noValidate>
                <Accordion defaultActiveKey={this.state.section}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" className="consultHeader">
                            <h5>Patient Info</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="consultBody">
                            <ConsultationDescription 
                                    patientId={this.state.patientId} 
                                    name={this.state.name}
                                    points={this.state.points}
                                    loyalty={this.state.loyalty}
                                    handleChange={this.handleChange}
                                    errors={this.state.errors}
                                    payment={this.state.paymentId}
                                    cardType={this.state.cardType}>
                            </ConsultationDescription>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" className="consultHeader">
                            <h5>Visit history</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body className="consultBody">
                            <VisitHistory
                                 vhistory={this.state.vhistory}>
                            </VisitHistory>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2" className="consultHeader">
                            <h5>Consultation</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                        <Card.Body className="consultBody">
                            <ConsultationDocNotes
                                cheifcomplaint={this.state.fields["cheifcomplaint"]}
                                handleChange={this.handleChange}
                                notes={this.state.notes}
                                diagnosis={this.state.diagnosis}
                                errors={this.state.errors}>
                            </ConsultationDocNotes>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="3" className="consultHeader">
                            <h5>Medical Certificates</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                        <Card.Body className="consultBody" style={{minHeight:"400px"}}>
                            <MedicalCertification
                                handleAddMcs={this.handleAddMcs}
                                handleFromDateChange={this.handleFromDateChange}
                                handleToDateChange={this.handleToDateChange}
                                datefrom={this.state.datefrom}
                                dateto={this.state.dateto}
                                mcs={this.state.mcs}
                                removeCert={this.removeCert}>
                            </MedicalCertification>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="4" className="consultHeader">
                        <h5>Medication And Charges</h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                    <Card.Body className="consultBody">
                        <MedicationAndCharges  
                                handleChange={this.handleChange}
                                handleAddMedications={this.handleAddMedications}                              
                                drfee={this.state.drfee}
                                loyalty={this.state.loyalty}
                                points={this.state.points}
                                discount={this.state.discount}
                                medications={this.state.medications}
                                handleNetAndTotal={this.handleNetAndTotal}
                                total={this.state.total}
                                net={this.state.net}
                                payment={this.state.paymentId}
                                errors={this.state.errors}
                                drugs={this.state.drugs}>
                        </MedicationAndCharges>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                    <br></br>
                </Accordion>
                <div className="row">                  
                    <div style={{marginLeft:"50px", marginTop:"10px"}}>
                        <Button type="submit">Save</Button>
                    </div>
                    <div style={{marginLeft:"50px", marginTop:"10px"}}>
                        <Button type="button" onClick={this.handleConfirm}>Confirm</Button>
                    </div>                   
                        {(this.state.status== PATIENT_STATUS.Complete) && isRoleAuthorize(['superadmin']) ? (
                             <div style={{marginLeft:"50px", marginTop:"10px"}}>
                                <Button type="button" onClick={this.handleChangeToPharmacy}>Change To Pharmacy</Button>
                             </div>
                        ): <></>
                        }
                        {isRoleAuthorize(['superadmin']) ? (
                             <div style={{marginLeft:"50px", marginTop:"10px"}}>
                                <Button type="button" onClick={this.handleRemove}>Remove</Button>
                             </div>
                        ): <></>                            
                        }                    
                </div>
            </Form>

            <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
            </div>
            </>
        );
       
    }
}


export default Authorization(DoctorAddConsultation, ['superadmin','doctor','locum','staff'])