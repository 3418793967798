import { Redirect } from 'react-router-dom';
import CARD_TYPE from '../constants/card-type.constant'
import CARD_DEDUCT from '../constants/card-deduction.constant'
import ConsultationService from '../services/consultation.service'

 var jwtDecode = require('jwt-decode');


 export const getStatusConsultation = (visitId) => {

     if(visitId==0)return
    let info = {
        visitId 
    }
    ConsultationService.getStatusByVisitId(info).then(result => {
        if(result){
            return result.data
        }
    })
 }
 export const isNotPanel = (lsPayment, paymentId) => {

    if(paymentId==0) return false
    if(lsPayment.length > 0 && paymentId != null){
        let paymentMethod = lsPayment.filter(x => {
            return x.id == paymentId
        })
        if(paymentMethod.length == 0) return true
        const payment = ['Cash','CreditCard']
        let status = payment.includes(paymentMethod[0].name)
        return status
    }
    return false
}
export const totalMcDay = (startDate, toDate) =>{
     if(startDate == null || toDate == null) return 0
        var sDate = new Date(startDate)
        var eDate = new Date(toDate)
        // To calculate the time difference of two dates 
       return (eDate.getDate() - sDate.getDate() + 1 ); 
    }
export const  formatDate = (today) =>{
        if(today == undefined) return
        var pDate = new Date(today)
        let dd = pDate.getDate();
        let mm = pDate.getMonth()+1; 
        const yyyy = pDate.getFullYear();
        
        if(dd<10) 
        {
            dd=`0${dd}`;
        } 

        if(mm<10) 
        {
            mm=`0${mm}`;
        }

        return `${dd}-${mm}-${yyyy}`;
    }

    export const toDate = (today) =>{
        if(today == undefined) return
        var pDate = new Date(today)
        let dd = pDate.getDate();
        let mm = pDate.getMonth()+1; 
        const yyyy = pDate.getFullYear();
        
        if(dd<10) 
        {
            dd=`0${dd}`;
        } 

        if(mm<10) 
        {
            mm=`0${mm}`;
        }

        return `${yyyy}-${mm}-${dd}`;
    }

export const getUnique = (array) =>{
    var uniqueArray = [];
    
    // Loop through array values
    for(var i=0; i < array.length; i++){
        if(uniqueArray.indexOf(array[i].visitId) === -1) {
            uniqueArray.push(array[i].visitId);
        }
    }
    return uniqueArray;
}

export const getNameAndRole = () =>{
    if(localStorage.getItem('user') != null)
    {
      let token = localStorage.getItem('user')
      let t = JSON.parse(token)
      var decoded = jwtDecode(t.accessToken)
      var user = {
          name: decoded.sub[1],
          role: decoded.sub[2]
      }

      return user
    }
    else {
        return null
    }
}

export const isRoleAuthorize = (allowedRoles) => {
    let user = getNameAndRole()
    if(allowedRoles.includes(user.role)){
        return true
    }

    return false
    
}


export const isNumberKey = (evt) => {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    } else {
        // If the number field already has . then don't allow to enter . again.
        if (evt.target.value.search(/\./) > -1 && charCode == 46) {
            return false;
        }
        return true;
    }
}

export const renderCardDeduct = (cardType, amount) =>{
    let afterDeduct = 0

    if(cardType != null && amount != null){
        switch(cardType){
            case parseInt(CARD_TYPE.LocalDebit):
                afterDeduct = parseFloat(CARD_DEDUCT.LocalDebit) * amount;
            break
            case parseInt(CARD_TYPE.LocalCredit):
                afterDeduct = parseFloat(CARD_DEDUCT.LocalCredit) * amount
            break
            case parseInt(CARD_TYPE.ForeignCard):
                afterDeduct = parseFloat(CARD_DEDUCT.ForeignCard) * amount
            break
            case parseInt(CARD_TYPE.AmericanExpress):
                afterDeduct = parseFloat(CARD_DEDUCT.AmericanExpress) * amount
            break
            case parseInt(CARD_TYPE.JCB):
                afterDeduct = parseFloat(CARD_DEDUCT.JCB) * amount
            break
            case parseInt(CARD_TYPE.UnionPay):
                afterDeduct = parseFloat(CARD_DEDUCT.UnionPay) * amount
            break
        }

        afterDeduct = parseFloat(afterDeduct.toFixed(2))
    }
    return afterDeduct
}

export const numberAsString = (no) => {
    var number = ''
    switch(no){
        case 1:
            number = 'Satu'
            break;
        case 2:
            number =  'Dua'
            break;
        case 3:
            number =  'Tiga'
            break;
        case 4:
            number =  'Empat'
            break;
        case 5:
            number =  'Lima'
            break;
        case 6:
            number = 'Enam'
            break;
        case 7:
            number =  'Tujuh'
            break;
        case 8:
            number =  'Lapan'
            break;
        case 9:
            number =  'Sembilan'
            break;
        case 10:
            number =  'Sepuluh'
            break;
    }

    return `${number}(${no})`
}
      