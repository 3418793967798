import React, {Component} from 'react'
import NavBar from '../nav-bar.component'
import {Form, Button, Table} from 'react-bootstrap'
import ConsultationService from '../../services/consultation.service'
import visitHistoryService from '../../services/visit-history.service'
import McService from '../../services/mc.service'
import paymentService from '../../services/payment.service'
import axios from 'axios';
import {totalMcDay, formatDate } from '../../helpers/utility'

class PatientVisitHistory extends Component {
    constructor(props){
        super(props)
        this.state = {
            datefrom: new Date(),
            dateto: new Date(),
            patientId:  this.props.icNo,
            name: '' ,
            doctor: '',
            paymentId: 0,
            visitId:  this.props.visitId,
            fields: {},
            errors: {},
            section: "0",
            showToast: false,
            medications:[],
            mcs: [],
            vhistory: []
        }
    
        this.handleNetAndTotal = this.handleNetAndTotal.bind(this)
        this.isNotPanel = this.isNotPanel.bind(this)

       
    }
    

    componentDidMount(){

        //var visitId = this.props.visitId
        //var patientId = this.props.icNo
        //var paymentId = this.props.match.params.paymentId


        // this.setState({
        //     visitId
        // })

        let conditions = {
            visitId: this.state.visitId,
            patientId: this.state.patientId
        }

        const consultation =ConsultationService.getPatientConsultation(conditions)
        const medicines = ConsultationService.getPatientMedicines(conditions)
        const mc = McService.getMcs(conditions)
        const paymentMethod = paymentService.getPayments()
        const visitHistory = visitHistoryService.getVisitByIc(conditions)

        axios.all([consultation, medicines, mc, paymentMethod, visitHistory]).then(axios.spread((...responses) => {
            const lsConsult = responses[0].data
            const lsMed = responses[1].data
            const lsMc = responses[2].data
            const lsPayment = responses[3].data
            const lsVisit = responses[4].data

            const { visitId, visitDate, name, patientId, doctor, drfee, notes, diagnosis, discount, paymentId } = lsConsult[0]
            
            this.setState({
                 visitId,
                 visitDate,
                 patientId,
                 name,
                 doctor,
                 paymentId,
                 drfee,
                 notes,
                 diagnosis,
                 discount             
            })

            var notPanel = this.isNotPanel(lsPayment, paymentId)
            
          
            let med = lsMed.map((data, index) =>{
                return {
                    drugName: data.name,
                    drugId: data.medicineId,
                    selectQty: data.quantity,
                    cost: data.cost,
                    amount: notPanel ? data.price : data.panelprice,
                    prescription: data.prescription,
                    notes: data.notes
                }
            })

            this.setState({
                medications: med,
                mcs: lsMc,
                vhistory: lsVisit

            })

            this.handleNetAndTotal(drfee, discount)

        })).catch(errors => {
            // react on errors.
            console.log(errors)
          })

    }

    renderTableCert = () => {
       
        return this.state.mcs.map((data,index) => {
           
            return (
                <tr key={index}>
                    <td>{index}</td>
                    <td>{formatDate(data.datefrom)}</td>
                    <td>{formatDate(data.dateto)}</td>
                    <td>{totalMcDay(data.datefrom, data.dateto)}</td>
                </tr>
             )
            
        })  
    }

    renderTableMedication = () => {
      
        return this.state.medications.map((data,index) => {
         
                return (
                    <tr key={index}>
                    <td>{index}</td>
                    <td>{data.drugName}</td>
                    <td>{data.selectQty}</td>
                    <td>{data.cost}</td>
                    <td>{data.amount*data.selectQty}</td>
                    <td>{data.prescription}</td>
                    <td>{data.notes}</td>
                </tr>
                )
        })  
    }
    
    isNotPanel(lsPayment, paymentId){
        let paymentMethod = lsPayment.filter(x => {
            return x.id == paymentId
        })
        const payment = ['Cash','CreditCard']
        let status = payment.includes(paymentMethod[0].name)
        return status
    }
  
    handleNetAndTotal(drFee, discount){
        //const {net, total} = this.state
        var total = 0;

        // Getting sum of numbers
        var medications = this.state.medications.filter(x => {
            return x.status != 'delete'
        })
    
        var sum = medications.map(x => { return x.amount*x.selectQty }).reduce(function(a, b){
        return a + b;
        }, 0);

        var cost = medications.map(x => { return x.cost }).reduce(function(a, b){
            return a + b;
            }, 0);

        if(discount != undefined){
            var i = (100 - discount)/100
            if(drFee == ""){
                total = sum  * i
            }
            else{
                total = (sum + parseInt(drFee)) * i
            }
           
        }
        else
        {
            if(drFee == undefined){
                total = sum;
            }
            else{
                total = sum + parseInt(drFee)
            }
            
        }
        this.setState({
            total,
            net: total - cost
        })
       
    }


    render(){
        return(
            <>
                <div className="row">
                    <div className="col-lg-6">
                    <Form.Group controlId="formIcNo">
                        <Form.Label>Ic No</Form.Label>
                        <Form.Control type="text"  name="icno" value={this.state.patientId || ''}  readOnly />
                    </Form.Group>
                    </div>
                    <div className="col-lg-6">
                    <Form.Group controlId="formPatientName">
                        <Form.Label>Patient Name</Form.Label>
                        <Form.Control type="text"  name="name" value={this.state.name || ''} readOnly />
                    </Form.Group>
                
                    </div>
            </div>
                <div className="row">
                    <div className="col-lg-6">
                    <Form.Group controlId="formPayment">
                        <Form.Label>Payment</Form.Label>
                        <Form.Control type="text"  name="age" value={this.state.paymentId}  readOnly/>
                    </Form.Group>
                    </div>
            </div>   
                <div className="row">
                    <div className="col-lg-6">
                        <Form.Group controlId="formChiefComplaint">
                            <Form.Label>Chief Complaint</Form.Label>
                            <textarea name="cheifcomplaint" value={this.state.notes}
                                className="form-control"
                                rows="5"
                            />
                            </Form.Group>
                    </div>
                    <div className="col-lg-6">
                        <Form.Group controlId="formDiagnosis">
                            <Form.Label>Diagnosis</Form.Label>
                            <textarea name="diagnosis" value={this.state.diagnosis} 
                            className="form-control"
                            rows="5"/>
                        </Form.Group>
                    </div>
                </div>
                Medical Certificates: <br></br>
                <div className="row">
                    <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                        <tr>
                            <th key="0">No</th>
                            <th key="1">From</th>
                            <th key="2">To</th>
                            <th key="3">Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableCert()}
                        </tbody>
                    </Table>
            
                </div>
                Medicines:

                <Table responsive striped bordered hover size="sm" variant="dark">
                    <thead>
                    <tr>
                        <th key="0">No</th>
                        <th key="1">Drug Name</th>
                        <th key="2">Qty</th>
                        <th key="3">Cost</th>
                        <th key="4">Amount</th>
                        <th key="5">Prescription</th>
                        <th key="6">Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableMedication()}
                        
                    </tbody>
                </Table>
                Charges:
                <div className="row">
                    <div className="col-lg-4">
                        <Form.Group controlId="formDrFee">
                            <Form.Label>Dr Fee</Form.Label>
                            <Form.Control type="text"  name="drfee"  value={this.state.drfee || ''} readOnly />
                        </Form.Group>
                    </div>
                    <div className="col-lg-4">
                        <Form.Group controlId="formDiscount">
                            <Form.Label>Discount</Form.Label>
                            <Form.Control type="text"  name="discount" value={this.state.discount || ''} readOnly/>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <Form.Group controlId="formNet">
                            <Form.Label>Net</Form.Label>
                            <Form.Control type="text"  name="net"  value={this.state.net || ''} readOnly/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-4">
                        <Form.Group controlId="formTotal">
                            <Form.Label>Total</Form.Label>
                            <Form.Control type="text"  name="total" value={ 'RM ' + this.state.total || ''}  readOnly/>
                        </Form.Group>
                    </div>
                </div>
              
            </>
        );
       
    }
}


export default PatientVisitHistory
