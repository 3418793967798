import React, {Component} from 'react'
import {Form, Button, Label, Table} from 'react-bootstrap'
import ClinicToast from '../../shared/toast'
import Authorization from '../../../helpers/auth'
import NavBar from '../../nav-bar.component'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JournalService from '../../../services/journal.service'
import JournalDesriptionService from '../../../services/journal-description.service'
import { toDate } from '../../../helpers/utility'
import ClinicPagination from '../../shared/clinic-pagination.component'
import PAGINATION from '../../../constants/pagination.constant'
import BlockUI from '../../shared/BlockUI/blockUI'
import { Redirect } from 'react-router-dom';
import history from '../../../history'
import LookupAccountService from '../../../services/lookup-account.service'

class Ledger extends Component {
    constructor(props){
        super(props)

        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            showToast: false,
            journalData: [],
            cloneJ:[],
            accountTypeId: 1,
            accountName: '',
            show: false,
            journalId: null,
            editJournal: null,
            totalRecords: 0,
            credit: false,
            debit: false
        }      
    }
   
    componentDidMount() {
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate)
        }

        LookupAccountService.getAllAccount().then(result => {
            result.data.splice(0,0,{ id:0,accountname: ""})       
            if(result){ 
                this.setState({
                    accLookup: result.data
                })
            }
        })

    }

    handleClose = () => {
        this.setState({
            show: false
          });
  
    }
    handleSave = () =>{
        this.setState({
            show: false,
            showToast: true,
            message:'Successfully save transaction into journal',
            bgSucces: true
          });
    }


    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
     
    }
    handleSubmit = (event) => {
        event.preventDefault()
        this.setState({
            blocking: true
        })
        
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate),
            accountId: this.state.accId,
            pagination: {
                page: 1,
                records: PAGINATION.records
            }
        }
        JournalService.getLedgerBalance(condition).then(result => {
            if(result){
                const {credit, debit, balance} = result.data
                let b = balance.toString()
                let cr = credit ? " cr" : " dr"
                JournalService.getLedgerByAccount(condition).then(result => {
                    if(result){
                        let blnc = {
                            accountid: "",
                            credit: 0,
                            date: condition.fromDate,
                            debit: 0,
                            id: 0,
                            journal_Id: "",
                            balance: b + cr
                        }
                        result.data.records.splice(0,0,blnc)   
                        this.setState({
                            blocking: false,
                            journalData: result.data.records,
                            totalRecords: result.data.total,
                            credit,
                            debit,
                            balance
                        })
                    }
                })
            }
        })
        
    }

    addNew = () => {
        this.setState({
            show: true,
            action: "Add",
            accountName: ''
        })
    }
    delete = (e) => {
        const journalId = e.target.id
        let status = window.confirm("Are you sure to delete this transaction?")
        if(status){
           
            JournalService.delete({journalId}).then(result => {
                if(result){
                    JournalDesriptionService.delete({journalId}).then(result => {
                        let data = this.state.cloneJ
                        
                        data = data.filter(x=>{
                            return x.journal_Id != journalId
                        })
                        
                        this.setState({
                            journalData: this.groupResult(data),
                            cloneJ: data
                        })
                        
                    })
                }   
            })
        }
    }
    edit = (e) => {
        const id = e.target.id
        this.setState({
            editJournal: `/accounting/journal/edit/` + id
          });
    }

    handleStartDateChange = (date) => {
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            startDate: new Date(updDate)
        });
    }

    handleEndDateChange = (date) =>{
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            endDate: new Date(updDate)
        });
    }
    handleAccountType = (event) => {
        let value = event.target.value
        this.setState({
            accountTypeId: value
        })
    }

    handleChange = (event) => {
        let value = event.target.value
        this.setState({
            search: value
        })
    }

     handlePageChange = (pageNumber) => {
        
        //console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber,  blocking: true});
        
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate),
            accountId: this.state.accId,
            pagination: {
                page: pageNumber,
                records: PAGINATION.records
            }
        }
        JournalService.getLedgerByAccount(condition).then(result => {
            if(result){
                this.setState({
                    blocking:false,
                    journalData: result.data.records
                })
            }
        })


      }
      handleAddTransactions = () => {
          alert('sdas')
      }

      handleShow = (e) => {

        e.preventDefault()
    
        this.setState({
          show: true
        });
      };

      renderLookup = () => {
        if(this.state.accLookup == null) return                                     
        return this.state.accLookup.map(x =>{
              return (
                  <option value={x.id}>{x.accountname}</option>
                 
                  )
              })
     }
     handleInputChange = (event) =>{
    
        let name = event.target.name
        let value = event.target.value
    
        this.setState({
            [name]:value
        })
    }

    
    render(){
        let bal = this.state.balance
        if(this.state.editJournal){
            history.push(this.state.editJournal)
            return <Redirect to={this.state.editJournal} />
        }
        return(
           
            <div>
                <div className="menu">
                    <div className="mainToast">
                        <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                    </div>
                    <NavBar></NavBar>
                </div> 
                <div className="docMobile">
                    <Form style={{width:"80%",margin: "0% 10%", padding:"40px 30px", backgroundColor:"#e9edf3"}} onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                        
                            <div className="row">
                            
                                <div className="col-lg-4">
                                <Form.Group controlId="formAccount">
                                    <Form.Label>Account:</Form.Label>
                                    <select name="accId" onChange={this.handleInputChange}>
                                        {this.renderLookup()}
                                    </select>
                                </Form.Group>
                                </div>
                                
                            
                                <div className="col-lg-3">
                                    <Form.Label>Date From:</Form.Label>
                                    <DatePicker
                                    
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    dateFormat="dd-MM-yyyy"
                                />
                                </div>
                                <div className="col-lg-3">
                                <Form.Label>To:&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                                    <DatePicker
                                    
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    dateFormat="dd-MM-yyyy"
                                />
                                </div>
                                <div className="col-lg-2">
                                    <Button type="submit" onSubmit={this.handleSubmit}>Select</Button>
                                </div>
                            </div>
                        
                        </div>
                </div>
                
                <hr></hr>
        
                <div className="row">
                        <div className="col-lg-12">
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Dates</th>
                                        <th>Journal Id</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.journalData && (
                                            this.state.journalData.map((x,index) => {
                                                const { id, journal_Id, date, accountName, debit, credit, action,balance } = x //destructuring
                                                    if(this.state.debit){
                                                        if(debit == 0){
                                                            bal = bal-credit
                                                        }else{
                                                            bal = bal+debit
                                                        }
                                                    }else{
                                                        if(debit == 0){
                                                            bal = bal+credit
                                                        }else{
                                                            bal = bal-debit
                                                        }
                                                    }
                                            
                                                    return (
                                                        <tr key={index}>
                                                            <td>{date.replace("T00:00:00","")}</td>
                                                            <td>{journal_Id}</td>
                                                            <td>{parseFloat(debit).toFixed(2)}</td>
                                                            <td>{parseFloat(credit).toFixed(2)}</td>
                                                            {
                                                                (index == 0) && (
                                                                <td>{parseFloat(balance).toFixed( 2 )}{this.state.debit==true ? " dr" : " cr"}</td>
                                                            )
                                                            }
                                                            {
                                                                (index != 0) && (
                                                                <td>{parseFloat(bal).toFixed( 2 )}</td>
                                                            )
                                                            }    
                                                        </tr>
                                                    )
                                                })
                                        )
                                    }
                                </tbody>
                            
                            </Table>
                        
                        </div>
                </div>
                
                <br></br><br></br>
                
                <ClinicPagination totalItemsCount={this.state.totalRecords} onChange={this.handlePageChange}></ClinicPagination>
                    </Form>
                    <BlockUI blocking={this.state.blocking}></BlockUI>
               </div>
             
            </div>
        );
    }
}

export default Authorization(Ledger, ['superadmin','doctor'])