import React, {Component} from 'react'
import {Form} from 'react-bootstrap'
import NavBar from '../nav-bar.component'

export default class NoAccess extends Component {
    constructor(props){
        super(props)  
    }
   
    render(){
        return(
            <>
            <div className="menu">
           
            <NavBar></NavBar>
            </div>
        
        <div className="content">
            
                <div className="row">
                <div className="col-lg-6">
                    <Form.Group controlId="formChiefComplaint">
                        <h3 style={{color:"red"}}>Sorry, you had no access to this page. Please ask administrator for assistance!</h3>
                        
                    </Form.Group>
                </div>
               
            </div>
            </div>
           </>    
        );
    }
}