import React, {Component} from 'react'
import NavBar from '../../nav-bar.component'
import {Form, Button} from 'react-bootstrap'
import UserService from '../../../services/user.service'
import { Redirect} from 'react-router-dom'
import history from '../../../history'
import axios from 'axios';
import MedicineService from '../../../services/medicine.service'
import Authorization from '../../../helpers/auth'
import ClinicToast from '../../shared/toast'
import {isNumberKey} from '../../../helpers/utility'


class MedicineAdd extends Component {
    constructor(props){
        super(props)
        this.state = {
            redirect: null,
            userType: [],
            userRole: [],
            active: ' ',
            editMode: false,
            bgSucces: false,
            showToast: false
        }
    }

    componentDidMount = () =>{
        var id = this.props.match.params.id
        if(id > 0){
            MedicineService.getMedicineById({id}).then(result => {
                if(result){
                    const {name, qty, panelprice, price, cost, notes, inactive} = result.data
                    this.setState({
                        id: id,
                        name,
                        qty,
                        price,
                        panelprice,
                        cost,
                        notes,
                        inactive,
                        title: "edit medicine",
                        editMode: true
                    })
                }
            })
        }

      
    }
    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
    }

    handleType = () => {
        return this.state.userType.map((x, index)=> {
            return (
                <option key={index} value={x.id}>{x.typename}</option>
            )
        })
    }
    
    handleSelect = (event) => {

        var value= event.target.value
        var name = event.target.name

        this.setState({
            [name]: value
          });
    }
  
    handleCancel = () => {
        this.setState({ redirect: `/maintenance/medicine`});
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }
    handleUpdate = (event) =>{
        event.preventDefault()
        const {id, name, qty, panelprice, price, cost, notes, inactive} = this.state;

        let medicine = {}
        
        medicine["id"] = id
        medicine["name"] = name
        medicine["qty"] = qty
        medicine["panelprice"] = panelprice
        medicine["price"] = price
        medicine["cost"] = cost
        medicine["notes"] = notes
        medicine["inactive"] = inactive
     
        MedicineService.update(medicine).then(
            response => {
                this.setState({
                    showToast: true,
                    message:'Update successfully',
                    bgSucces: true
                })
            },
            error => {
              if(error.response && error.response.data){
                this.setState({
                    showToast: true,
                    message:error.response.data,
                    bgSucces: false
                })
              }
            
            }
          );
        
    }
    handleSubmit = (event) => {

        event.preventDefault();

        let medicine = this.state;
            
        MedicineService.add(medicine).then(
            response => {
              this.setState({
                showToast: true,
                message:'Update successfully',
                bgSucces: true
            })
            },
            error => {
                this.setState({
                    showToast: true,
                    message:error.response.data,
                    bgSucces: false
                })
            }
          );
    }
    
    render(){
        if (this.state.redirect != null) {
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return(
            <>
            <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
                </div> 
                <div className="content">
                    <h2>{this.state.title}</h2>
                    <Form className = "formClinic" onSubmit={this.state.editMode ?  this.handleUpdate : this.handleSubmit }>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" autoComplete="off" placeholder="name" value={this.state.name} onChange={this.handleInputChange} required />
                        </Form.Group>
                        <Form.Group controlId="formQuantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control type="number" name="qty" placeholder="quantity" value={this.state.qty} onChange={this.handleInputChange} required />
                        </Form.Group>
                        <Form.Group controlId="formPrice">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="text" onKeyPress={isNumberKey} name="price" placeholder="price" value={this.state.price} onChange={this.handleInputChange}  />
                        </Form.Group>
                        <Form.Group controlId="formPanelPrice">
                            <Form.Label>Panel Price</Form.Label>
                            <Form.Control type="text" onKeyPress={isNumberKey} name="panelprice" placeholder="panelprice" value={this.state.panelprice} onChange={this.handleInputChange}  />  
                        </Form.Group>
                        <Form.Group controlId="formCost">
                            <Form.Label>Cost</Form.Label>
                            <Form.Control type="text" onKeyPress={isNumberKey} name="cost" placeholder="cost" value={this.state.cost} onChange={this.handleInputChange}  />  
                        </Form.Group>
                        <Form.Group controlId="formNotes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control type="text" name="notes" placeholder="notes" value={this.state.notes} onChange={this.handleInputChange}  />  
                        </Form.Group>
                        <Form.Group controlId="formActive">
                            <Form.Label>Active ?</Form.Label>
                            <Form.Control as="select" name="inactive" onChange={this.handleSelect} value={this.state.inactive} required>
                                <option value=""></option>
                                <option value="0">active</option>
                                <option value="1">inactive</option>
                            </Form.Control>
                        </Form.Group>
                        {this.state.editMode ? (
                            <Button variant="primary" type="submit">
                                Update
                            </Button>) : (
                            <Button variant="primary" type="submit">
                            Add
                        </Button>)}
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="primary" type="button" onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    </Form>
                </div>
            </>
           
        );
    }
}

export default Authorization(MedicineAdd, ['superadmin','admin','doctor','staff'])

