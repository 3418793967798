import React, {Component} from 'react'
import NavBar from '../../nav-bar.component'
import {Form, Button, Table} from 'react-bootstrap'
import DatePicker from "react-datepicker";
import VisitService from '../../../services/visit-history.service'
import { Redirect} from 'react-router-dom'
import history from "../../../history";
import ConsultationService from '../../../services/consultation.service'
import paymentService from '../../../services/payment.service'
import axios from 'axios';
import Authorization from '../../../helpers/auth'
import AddConsultationModal from '../../modal/add-consultation-modal.component'
import './doctor.css'

class DoctorConsultation extends Component {
    constructor(props){
        super(props)
        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            redirect: null,
            pays:[],
            status: [],
            consultations: []
        }

        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.renderTableHeader = this.renderTableHeader.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartConsultation = this.handleStartConsultation.bind(this);
        this.startConsultation = this.startConsultation.bind(this)
        this.getDateFrom = this.getDateFrom.bind(this)
        this.getDateTo = this.getDateTo.bind(this)
    }

    componentDidMount() {
           

        const paymentMethod = paymentService.getPayments();

        let fDateTime = this.getDateFrom()
        let toDateTime = this.getDateTo()
        
        let conditions = {
            fromDate: fDateTime,
            toDate: toDateTime
        }
 
        const consult = ConsultationService.getPatientConsultation(conditions)
        const status = ConsultationService.getStatus()

        axios.all([paymentMethod, consult, status]).then(axios.spread((...responses) => {
        
            const lsPays = responses[0]
            const lsConsult = responses[1]
            const lsStatus = responses[2]

            this.setState({
                pays: lsPays.data,
                consultations: lsConsult.data,
                status: lsStatus.data
            })

            var pays = lsPays.data.map((data, index)=>
                <option key={index} value={data.id}>{data.name}</option>
            )

            this.setState({
                payments: pays
            })
    
            // use/access the results 
          })).catch(errors => {
            // react on errors.
            console.log(errors)
          })

    }

    handleStartConsultation(e){

        const visitId = e.currentTarget.getAttribute('data-visitid');
        const icno = e.currentTarget.getAttribute('data-ic');
        const payment = e.currentTarget.getAttribute('data-payment');
        // const cardType = e.currentTarget.getAttribute('data-cardType');
        let pay = this.state.pays.filter(x => {
            return x.name == payment
        })        
        this.setState({
            show: true,
            visitId,
            icno,
        })
    
    }
    handleSubmit(event){
        event.preventDefault();
        
        let fDateTime = this.getDateFrom()
        let toDateTime = this.getDateTo()

        let conditions = {
            fromDate: fDateTime,
            toDate: toDateTime
        }
 
        ConsultationService.getPatientConsultation(conditions).then(result => {
             
             this.setState({
                 consultations: result.data
             })
 
         },error => {
             console.log(error)
         })
     
    
    }

    getStatusName = (status) => {
        var patientStatus = this.state.status.filter(x => {
            return x.id == status
        })

        return patientStatus[0].statusname
    }
    getDateFrom(){

        let fY = this.state.fromDate.getFullYear()
        let m = this.state.fromDate.getMonth() + 1
        let fM = ('0' + m).slice(-2)
        let fd =  ('0' + this.state.fromDate.getDate()).slice(-2)
      
        let fDate = `${fY}-${fM}-${fd}`
       
        let fDateTime =  `${fDate} 00:00:00`

        return fDateTime
        
    }
    getDateTo(){

        let tY = this.state.toDate.getFullYear()
        let m =this.state.toDate.getMonth() + 1
        let tM = ('0' + m).slice(-2)
        let td = ('0' + this.state.toDate.getDate()).slice(-2)
        
        let toDate = `${tY}-${tM}-${td}`
       
        let toDateTime = `${toDate} 23:59:59`

        return toDateTime
    }
    renderTableHeader() {
    
    return (
        <tr>
            <th key="0">No</th>
            <th key="1">Patient Id</th>
            <th key="2">Name</th>
            <th key="3">Visit Date</th>
            <th key="4">Doctor</th>
            <th key="5">Payment</th>
            <th key="6">Status</th>
        </tr>
    );
    }

    renderTableData() {
    if(this.state.consultations == null) return
    return this.state.consultations.map((patient, index) => {
        const { visitId, name, patientId, visitDate, doctor, payment, status, cardType } = patient //destructuring
        return (
            <tr key={index} data-ic={patientId} data-visitid={visitId} data-payment={payment} data-cardtype={cardType} onClick={this.handleStartConsultation}>
                <td>{index}</td>
                <td>{patientId}</td>
                <td>{name}</td>
                <td>{visitDate.replace('T',' ')}</td>
                <td>{doctor}</td>
                <td>{payment}</td>
                <td style={ this.getStatusName(status)== 'complete' ? {backgroundColor:"green"} : {backgroundColor : "red"}}>{ this.getStatusName(status)}</td>
            </tr>
        )
    })
    }

    handleClose = () => {
        this.setState({
            show: false
          });
  
    }

    renderTableDataMobile= () => {
        if(this.state.consultations == null) return
        return this.state.consultations.map((patient, index) => {
            const { visitId, name, patientId, visitDate, doctor, payment, status, cardType } = patient //destructuring
            return (
                <tr key={index} data-ic={patientId} data-visitid={visitId} data-payment={payment} data-cardtype={cardType} onClick={this.handleStartConsultation}>
                    {/* <td>{index}</td> */}
                    <td>{patientId}</td>
                    <td>{name}</td>
                    {/* <td>{visitDate.replace('T',' ')}</td>
                    <td>{doctor}</td>
                    <td>{payment}</td> */}
                    <td style={ this.getStatusName(status)== 'complete' ? {backgroundColor:"green"} : {backgroundColor : "red"}}>{ this.getStatusName(status)}</td>
                </tr>
            )
        })
    }

    handleFromDateChange(date){
        this.setState({
            fromDate: new Date(date)
        });
      }

    handleToDateChange(date){
        this.setState({
            toDate: new Date(date)
        });
    }

    startConsultation(){
        history.push(this.state.redirect)
    }
    
    state = { redirect: null };
    render(){
        if (this.state.redirect != null) {
            
           history.push(this.state.redirect)
           return <Redirect to={this.state.redirect} />
        }
        return(
            <>
            <div className="menu">
                <NavBar></NavBar>
            </div>
            <div className="content">
                 <Form onSubmit={this.handleSubmit} className="formClinic">
                    <div className="row" style={{marginTop:"20px"}}>
                        <div className="col-lg-5">
                        <Form.Group controlId="formStartDate">
                            <Form.Label>Visit Date From</Form.Label>
                            <DatePicker
                                   
                                    selected={this.state.fromDate}
                                    onChange={this.handleFromDateChange}
                                    dateFormat="dd-MM-yyyy"
                    
                            />
                        </Form.Group>
                        </div>
                        <div className="col-lg-4">
                        <Form.Group controlId="formToDate">
                            <Form.Label>To          </Form.Label>
                            <DatePicker
                                   
                                   selected={this.state.toDate}
                                   onChange={this.handleToDateChange}
                                   dateFormat="dd-MM-yyyy"
                   
                           />
                        </Form.Group>
                    
                        </div>
                        <div className="col-lg-0">
                        <Button variant="primary" type="submit">
                            Search
                        </Button>
                    
                        </div>
                    </div>
                    <div className="row" style={{marginTop:"20px"}}>
                        {this.state.consultations.length > 0 ? 
                        (<Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.renderTableData()}
                        </tbody>
                        </Table>
                        )
                        : (<h5>No patient for this date, please be patience guys! </h5>)
                        }
                    </div>
                    <AddConsultationModal
                        show={this.state.show}
                        visitId={this.state.visitId}
                        visitDate={this.state.visitDate}
                        icNo={this.state.icno}
                        title={this.state.title}
                        body={this.state.body}
                        data={this.state.data}
                        onHide={this.handleClose} /> 
                </Form>
            </div>
            <div className="docMobile">
         
                <form onSubmit={this.handleSubmit}>
                      <label style={{margin:"10px", width:"20%"}}>From</label>
                      <div style={{width:"70%",margin:"0px 10px"}}>
                      <DatePicker
                            selected={this.state.fromDate}
                            onChange={this.handleFromDateChange}
                            dateFormat="dd-MM-yyyy"
                            withPortal
                           />
                       </div>
                        <label style={{margin:"10px", width:"20%"}}>To</label>
                        <div style={{width:"70%",margin:"0px 10px"}}>
                        <DatePicker                                   
                                   selected={this.state.toDate}
                                   onChange={this.handleToDateChange}
                                   dateFormat="dd-MM-yyyy"
                                   withPortal
                   
                           />
                        </div>

                        <Button variant="primary" type="submit" style={{margin:"10px", width:"100px"}}>
                            Search
                        </Button>
                </form>
              
                {this.state.consultations.length > 0 ? 
                        (
                            <>
                        <div style={{width:"98%",margin:"0px 5px"}}>
                        <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                        <tr>
                            <th key="1">Patient Id</th>
                            <th key="2">Name</th>
                        </tr>
                        </thead>
                        <tbody>
                            {this.renderTableDataMobile()}
                        </tbody>
                        </Table>
                        </div>
                        </>
                        )
                        : (<h5 style={{textAlign:"center"}}>No patient for this date, please be patience guys! </h5>)
                }
            </div>
            </>     
        );
    }
}

export default Authorization(DoctorConsultation, ['superadmin','doctor','locum','staff'])