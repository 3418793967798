import React, {Component} from 'react'
import NavBar from '../nav-bar.component'
import {Form, Button} from 'react-bootstrap'
import PatientService from '../../services/patient.service'
import ClinicToast from '../shared/toast'

export default class PatientAdd extends Component {
    constructor(props){
        super(props)
        this.state = {
            showToast: false,
            bgSucces: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }
    clearForm = () => {
        this.setState({
            icno: " ",
            name: " ",
            alamat1: " ",
            alamat2: " ",
            poskod: " ",
            bandar: " ",
            negeri: " ",
            emel: " ",
            telepon: " ",
            allergy: " "

        })
    }
    handleCloseToast=()=>{
        this.setState({
            showToast: false
        })
    }
    handleSubmit =(event) => {
        event.preventDefault();

        let patient = this.state;
        if(this.state.poskod == ""){
            patient.poskod = 0;
        }
        if(this.state.age == ""){
            patient.age = 0;
        }
      
        PatientService.add(patient).then(response => {
            this.setState({
                showToast: true,
                message:'Successfully added this new patient',
                bgSucces: true
            })
            this.clearForm()
            
            },
            error => {
                this.setState({
                    showToast: true,
                    message: error.response.data.message,
                    bgSucces: false
                })      
            }
          );
    }
    
    render(){
        return(
            <>
       
            <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
                </div> 
                <div className="content">
                    <h2>Add New Patient</h2>
                    <Form className = "formClinic" onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formIcNo">
                            <Form.Label>Identification No</Form.Label>
                            <Form.Control type="text" name="icno" placeholder="Ic No" onChange={this.handleInputChange} value={this.state.icno} required />
                        </Form.Group>
                        <Form.Group controlId="formNama">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="nama" placeholder="Name" onChange={this.handleInputChange} value={this.state.name} required />
                        </Form.Group>
                        <Form.Group controlId="formAlamat1">
                            <Form.Label>Address1</Form.Label>
                            <Form.Control type="text" name="alamat1" placeholder="Address" onChange={this.handleInputChange} value={this.state.alamat1}  />
                        </Form.Group>
                        <Form.Group controlId="formAlamat2">
                            <Form.Label>Address2</Form.Label>
                            <Form.Control type="text" name="alamat2" placeholder="Address" onChange={this.handleInputChange} value={this.state.alamat2}  />
                        </Form.Group>
                        <Form.Group controlId="formPoskod">
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control type="text" name="poskod" placeholder="Postcode" onChange={this.handleInputChange} value={this.state.poskod}  />
                        </Form.Group>
                        <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" name="bandar" placeholder="City" onChange={this.handleInputChange} value={this.state.bandar}  />
                        </Form.Group>
                        <Form.Group controlId="formState">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" name="negeri" placeholder="State" onChange={this.handleInputChange} value={this.state.negeri}  />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" name="emel" placeholder="Email" onChange={this.handleInputChange}  value={this.state.emel}/>
                        </Form.Group>
                        <Form.Group controlId="formTelephone">
                            <Form.Label>Telephone</Form.Label>
                            <Form.Control type="text" name="telepon" placeholder="Telephone" onChange={this.handleInputChange}  value={this.state.telepon} required />
                        </Form.Group>
                        <Form.Group controlId="formAllergy">
                            <Form.Label>Allergy</Form.Label>
                            <Form.Control type="text" name="allergy" placeholder="Allergy" onChange={this.handleInputChange} value={this.state.allergy} />
                        </Form.Group>
                        
                        <Button variant="primary" type="submit">
                            Add
                        </Button>
                    </Form>
                </div>
            </>
           
        );
    }
}