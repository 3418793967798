import React, {Component} from 'react'
import {Form, Button, Table} from 'react-bootstrap'
import NavBar from '../../nav-bar.component'
import UserService from '../../../services/user.service'
import { Redirect} from 'react-router-dom'
import history from '../../../history'
import Authorization from '../../../helpers/auth'

class User extends Component {
    constructor(props){
        super(props)

        this.state = {
            isLoaded: false,
            redirect: null
        }
    }


    edit = (e) => {
        const id = e.target.id
        this.setState({ redirect: `/user/add/${id}`});
    }

    delete = (e) => {
        const id = e.target.id
        let status = window.confirm("Are you sure to delete this user?")
        if(status){
            UserService.delete({id}).then(result => {
                if(result){
                let newItems =  this.state.items.filter(x => {
                    return x.id != id
                    })
                    this.setState({
                        items : newItems
                    })
                }
            })
        }
    }
    renderTableHeader() {
        return (
            <tr>
                <th key="1">Name</th>
                <th key="2">User Name</th>
                <th key="3">Password</th>
                <th key="4">User Type</th>
                <th key="5">User Role</th>
                <th key="6">Action</th>
            </tr>
        );
     }
    renderTableData() {
        return this.state.items.map((user, index) => {
           const { id, name, icNo, username, password, userrole, usertype} = user //destructuring
           return (
              <tr key={id}>
                 <td>{name}</td>
                 <td>{username}</td>
                 <td>{password}</td>
                 <td>{usertype}</td>
                 <td>{userrole}</td>
                 <td><i id={id} className="fa fa-pencil-square-o" aria-hidden="true" onClick={this.edit} title="edit"></i>&nbsp;&nbsp;&nbsp;
                 <i  id={id} className="fa fa-times" aria-hidden="true" onClick={this.delete} title="delete"></i></td>
                 
              </tr>
           )
        })
     }
    
    handleInputChange = (event) => {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

         this.setState({
          [name]: value
        });    
    }
    
    addUser = () => {
        this.setState({ redirect: `/user/add/0`});
    }

    findUser = (e) => {
        if(this.state.search === undefined || this.state.search.trim().length === 0 ) {
            alert('Please type in search')
            return
        }
        let condition = {
            name: ''
        }
       
        condition.name = this.state.search;
      
        this.setState({
            blocking: true
        })
        UserService.search(condition).then(response => {
      
               this.setState({
                   items: response.data,
                   isLoaded: true,
                   blocking: false
               })
          
        })

    }
   handleSubmit= (event)=>{
   }

    render(){
        var result = this.state.isLoaded;
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
            <div className="menu">
                <NavBar></NavBar>
            </div>
            <div className="content">
                <h2>User maintenance</h2>
            <Form className = "formClinic" onSubmit={this.handleSubmit}>
            <div className="row">
                <div className="col-lg-3">
                    <Form.Group controlId="formType">
                        <Form.Label>Name:</Form.Label>
                    </Form.Group>
                </div>
                <div className="col-lg-7">
                    <Form.Group controlId="formSearchValue">
                        <Form.Control  type="text" name="search" placeholder="search" onChange={this.handleInputChange} />
                    </Form.Group>
                </div>
                <div className="col-lg-2">
                    <Form.Group controlId="formSearchValue">
                    <Button className="fa fa-search-plus" aria-hidden="true" style={{ float:"right"}} onClick={this.findUser} >Search</Button>
                    </Form.Group>
                </div>
                <Button className="fa fa-plus" aria-hidden="true" style={{margin:"30px 0px", float:"right"}} onClick={this.addUser} >Add</Button>
            </div>

            <h3 id='title'>Users Information</h3> 
            <hr></hr>
            {result ?( 
                <>
                              
                <Table responsive striped bordered hover size="sm" variant="dark">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
                </Table> </> )  : <div style={{margin: "0% 43%"}}>No records</div>
            } 
                  
            </Form>
            </div>
            </>
        )
    }

}

export default Authorization(User, ['superadmin','doctor'])

