import React, {Component} from 'react'
import NavBar from '../../nav-bar.component'
import {Form, Button, Table} from 'react-bootstrap'
import ConsultationService from '../../../services/consultation.service'
import visitHistoryService from '../../../services/visit-history.service'
import McService from '../../../services/mc.service'
import paymentService from '../../../services/payment.service'
import axios from 'axios';
import {totalMcDay, formatDate } from '../../../helpers/utility'
import Authorization from '../../../helpers/auth'
import PatientVisitHistory from '../../shared/patient-visit-history.component'


export default class VisitHistoryDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            datefrom: new Date(),
            dateto: new Date(),
            patientId: this.props.match.params.icno,
            name: '' ,
            doctor: '',
            paymentId: 0,
            visitId: this.props.match.params.id,
            fields: {},
            errors: {},
            section: "0",
            showToast: false,
            medications:[],
            mcs: [],
            vhistory: []
        }
    
        this.handleNetAndTotal = this.handleNetAndTotal.bind(this)
        this.isNotPanel = this.isNotPanel.bind(this)
    }
    

    componentDidMount(){
        var visitId = this.state.visitId
        var patientId = this.state.patientId
        //var paymentId = this.props.match.params.paymentId


        this.setState({
            visitId
        })

        let conditions = {
            visitId,
            patientId
        }

        const consultation =ConsultationService.getPatientConsultation(conditions)
        const medicines = ConsultationService.getPatientMedicines(conditions)
        const mc = McService.getMcs(conditions)
        const paymentMethod = paymentService.getPayments()
        const visitHistory = visitHistoryService.getVisitByIc(conditions)

        axios.all([consultation, medicines, mc, paymentMethod, visitHistory]).then(axios.spread((...responses) => {
            const lsConsult = responses[0].data
            const lsMed = responses[1].data
            const lsMc = responses[2].data
            const lsPayment = responses[3].data
            const lsVisit = responses[4].data

            const { visitId, visitDate, name, patientId, doctor, drfee, notes, diagnosis, discount, paymentId } = lsConsult[0]
            
            this.setState({
                 visitId,
                 visitDate,
                 patientId,
                 name,
                 doctor,
                 paymentId,
                 drfee,
                 notes,
                 diagnosis,
                 discount             
            })

            var notPanel = this.isNotPanel(lsPayment, paymentId)
            
          
            let med = lsMed.map((data, index) =>{
                return {
                    drugName: data.name,
                    drugId: data.medicineId,
                    selectQty: data.quantity,
                    cost: data.cost,
                    amount: notPanel ? data.price : data.panelprice,
                    prescription: data.prescription,
                    notes: data.notes
                }
            })

            this.setState({
                medications: med,
                mcs: lsMc,
                vhistory: lsVisit

            })

            this.handleNetAndTotal(drfee, discount)

        })).catch(errors => {
            // react on errors.
            console.log(errors)
          })

    }

    renderTableCert = () => {
       
        return this.state.mcs.map((data,index) => {
           
            return (
                <tr key={index}>
                    <td>{index}</td>
                    <td>{formatDate(data.datefrom)}</td>
                    <td>{formatDate(data.dateto)}</td>
                    <td>{totalMcDay(data.datefrom, data.dateto)}</td>
                </tr>
             )
            
        })  
    }

    renderTableMedication = () => {
      
        return this.state.medications.map((data,index) => {
         
                return (
                    <tr key={index}>
                    <td>{index}</td>
                    <td>{data.drugName}</td>
                    <td>{data.selectQty}</td>
                    <td>{data.cost}</td>
                    <td>{data.amount*data.selectQty}</td>
                    <td>{data.prescription}</td>
                    <td>{data.notes}</td>
                </tr>
                )
        })  
    }
    
    isNotPanel(lsPayment, paymentId){
        let paymentMethod = lsPayment.filter(x => {
            return x.id == paymentId
        })
        const payment = ['Cash','CreditCard']
        let status = payment.includes(paymentMethod[0].name)
        return status
    }
  
    handleNetAndTotal(drFee, discount){
        //const {net, total} = this.state
        var total = 0;

        // Getting sum of numbers
        var medications = this.state.medications.filter(x => {
            return x.status != 'delete'
        })
    
        var sum = medications.map(x => { return x.amount*x.selectQty }).reduce(function(a, b){
        return a + b;
        }, 0);

        var cost = medications.map(x => { return x.cost }).reduce(function(a, b){
            return a + b;
            }, 0);

        if(discount != undefined){
            var i = (100 - discount)/100
            if(drFee == ""){
                total = sum  * i
            }
            else{
                total = (sum + parseInt(drFee)) * i
            }
           
        }
        else
        {
            if(drFee == undefined){
                total = sum;
            }
            else{
                total = sum + parseInt(drFee)
            }
            
        }
        this.setState({
            total,
            net: total - cost
        })
       
    }


    render(){
        return(
            <>
            <div className="menu">
                <NavBar></NavBar>
            </div>
            <div className="content">
            <Form>
                <h5 style={{padding:"10px", backgroundColor:"black", color:"#FFFFFF"}}>Visit history for {this.state.name} on {this.state.visitDate}</h5>
                <div className="formClinic">
                <PatientVisitHistory visitId={this.state.visitId} icNo={this.state.patientId}></PatientVisitHistory>
                </div>
            </Form>
            </div>
            </>
        );
       
    }
}