import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class ReceiptService {

  load(data) {
    return axios.post(`${API_URL.receipt.load}`, data, { headers: authHeader() });
  }

}

export default new ReceiptService();
