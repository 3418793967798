import React, {Component} from 'react'
import {Form, Button, Label} from 'react-bootstrap'
import PatientService from '../../services/patient.service'
import axios from 'axios';



export default class SearchPatient extends Component {
    constructor(props){
        super(props)

        this.state = {
            payment: 0,
            name: ' ',
            patientId: ' '

        }
     
    }

    handleChange = (event) => {
        this.props.handleChange(event)
    }
    
    render(){
        return(
            
            <div className="row">
                <div className="col-lg-3">
                    <Form.Group controlId="formType">
                        <Form.Label>Search by:</Form.Label>
                        <Form.Control as="select" name="searchType" onChange={this.handleChange}>
                        <option value="1">Name</option>
                        <option value="2">Ic No</option>
                    </Form.Control>
                    </Form.Group>
                </div>
                <div className="col-lg-7">
                    <Form.Group controlId="formSearchValue">
                        {/* <Form.Label>Doctor</Form.Label> */}
                        <Form.Control style={{marginTop:"32px"}} type="text" name="search" placeholder="search" onChange={this.handleChange} />
                    </Form.Group>
                </div>
                <div className="col-lg-2">
                    <Form.Group controlId="formSearchValue">
                    <Button  style={{marginTop:"32px", float:"right"}} onClick={this.props.findPatient} >Search</Button>
                    </Form.Group>
                </div>
            </div>
    
        )
    }
}