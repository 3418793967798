import React, {Component} from 'react'
import {Form, Button, Label, Table} from 'react-bootstrap'
import ClinicToast from '../../shared/toast'
import Authorization from '../../../helpers/auth'
import NavBar from '../../nav-bar.component'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JournalService from '../../../services/journal.service'
import JournalDesriptionService from '../../../services/journal-description.service'
import { toDate } from '../../../helpers/utility'
import ClinicPagination from '../../shared/clinic-pagination.component'
import PAGINATION from '../../../constants/pagination.constant'
import BlockUI from '../../shared/BlockUI/blockUI'
import AddTransactionModal from '../../modal/add-transaction-modal.component'
import { Redirect } from 'react-router-dom';
import history from '../../../history'

class Journal extends Component {
    constructor(props){
        super(props)

        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            showToast: false,
            journalData: [],
            cloneJ:[],
            accountTypeId: 1,
            accountName: '',
            show: false,
            journalId: null,
            editJournal: null,
            totalRecords: 0
        }      
    }
   
    componentDidMount() {
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate)
        }
        JournalService.getJournal(condition).then(result => {
            if(result){
                console.log(result.data)
            }
        })

    }

    handleClose = () => {
        this.setState({
            show: false
          });
  
    }
    handleSave = () =>{
        this.setState({
            show: false,
            showToast: true,
            message:'Successfully save transaction into journal',
            bgSucces: true
          });
    }


    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
     
    }
    handleSubmit = (event) => {
        event.preventDefault()
        this.setState({
            blocking: true
        })
        
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate),
            pagination: {
                page: 1,
                records: PAGINATION.records
            }
        }
        JournalService.getJournal(condition).then(result => {
            if(result){
                let cloneJ = [ ...result.data.records ];
                this.setState({
                    blocking: false,
                    cloneJ,
                    journalData: this.groupResult(result.data.records),
                    totalRecords: result.data.total
                })
            }
        })
    }

    groupResult = (result) => {
        let res = []
        let data = []
        const uniqueArr = [... new Set(result.map(data => data.journal_Id))]
        uniqueArr.forEach(x => {
            let res = result.filter(r => {
                return x == r.journal_Id
            })
            data.push(res)
        });

        data.forEach(x => {
            let desc 
            x.forEach((y, index) => {
                desc = y.journalDesc
                if(index==0){
                    res.push({
                        date: y.date,
                        journal_Id: y.journal_Id,
                        accountName: y.accountName,
                        debit: parseFloat(y.debit).toFixed(2),
                        credit: parseFloat(y.credit).toFixed(2),
                        action: true
                    })
                }
                else{
                    res.push({
                        date: "",
                        journal_Id: "",
                        accountName: "&nbsp;&nbsp;" + y.accountName + "&nbsp;&nbsp;",
                        debit:  parseFloat(y.debit).toFixed(2),
                        credit:  parseFloat(y.credit).toFixed(2),
                        action: false
                    })
                }
            })
            res.push({
                date: "",
                journal_Id: "",
                accountName: "&nbsp;&nbsp;&nbsp;&nbsp;(" + desc + ")&nbsp;&nbsp;&nbsp;&nbsp;",
                debit: "",
                credit: "",
                action: false
            })
            res.push({
                date: "",
                journal_Id: "",
                accountName: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
                debit: "",
                credit: "",
                action: false
            })
        })
        return res
    }

    addNew = () => {
        this.setState({
            show: true,
            action: "Add",
            accountName: ''
        })
    }
    delete = (e) => {
        const journalId = e.target.id
        let status = window.confirm("Are you sure to delete this transaction?")
        if(status){
           
            JournalService.delete({journalId}).then(result => {
                if(result){
                    JournalDesriptionService.delete({journalId}).then(result => {
                        let data = this.state.cloneJ
                        
                        data = data.filter(x=>{
                            return x.journal_Id != journalId
                        })
                        
                        this.setState({
                            journalData: this.groupResult(data),
                            cloneJ: data
                        })
                        
                    })
                }   
            })
        }
    }
    edit = (e) => {
        const id = e.target.id
        this.setState({
            editJournal: `/accounting/journal/edit/` + id
          });
    }

    handleStartDateChange = (date) => {
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            startDate: new Date(updDate)
        });
    }

    handleEndDateChange = (date) =>{
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            endDate: new Date(updDate)
        });
    }
    handleAccountType = (event) => {
        let value = event.target.value
        this.setState({
            accountTypeId: value
        })
    }

    handleChange = (event) => {
        let value = event.target.value
        this.setState({
            search: value
        })
    }

     handlePageChange = (pageNumber) => {
        
        //console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber,  blocking: true});
        
        let condition = {
            fromDate: toDate(this.state.startDate),
            toDate: toDate(this.state.endDate),
            pagination: {
                page: pageNumber,
                records: PAGINATION.records
            }
        }
        JournalService.getJournal(condition).then(result => {
            if(result){
                this.setState({
                    blocking:false,
                    journalData: this.groupResult(result.data.records)
                })
            }
        })


      }
     

      handleShow = (e) => {

        e.preventDefault()
    
        this.setState({
          show: true
        });
      };
    
    render(){
        if(this.state.editJournal){
            history.push(this.state.editJournal)
            return <Redirect to={this.state.editJournal} />
        }
        return(
            <div>
                <div className="menu">
                    <div className="mainToast">
                        <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                    </div>
                    <NavBar></NavBar>
                </div> 
                <div className="docMobile">
                <Form className="formClinic" onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-lg-10">
                        <div>
                        <Form.Label>Transactions</Form.Label>
                        </div>
                        <div className="row">
                            <div className="col-lg-5">
                                <Form.Label>Date From:</Form.Label>
                                <DatePicker
                                   
                                   selected={this.state.startDate}
                                   onChange={this.handleStartDateChange}
                                   dateFormat="dd-MM-yyyy"
                               />
                            </div>
                            <div className="col-lg-5">
                            <Form.Label>To:</Form.Label>
                                <DatePicker
                                   
                                   selected={this.state.endDate}
                                   onChange={this.handleEndDateChange}
                                   dateFormat="dd-MM-yyyy"
                               />
                            </div>
                            <div className="col-lg-2">
                                <Button type="submit" onSubmit={this.handleSubmit}>Select</Button>
                            </div>
                        </div>
                    
                    </div>
               </div>
            
               <hr></hr>
    
               <div className="row">
                    <div className="col-lg-12">
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Dates</th>
                                    <th>Journal Id</th>
                                    <th>Account Titles And Explanation</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                   
                                    this.state.journalData && (
                                        this.state.journalData.map((x,index) => {
                                            const { id, journal_Id, date, accountName, debit, credit, action } = x //destructuring
                                                return (
                                                    <tr key={index}>
                                                        <td>{date.replace("T00:00:00","")}</td>
                                                        <td>{journal_Id}</td>
                                                        <td><div dangerouslySetInnerHTML={  {__html: accountName} }/></td>
                                                        <td>{debit}</td>
                                                        <td>{credit}</td>
                                                        {
                                                            x.action 
                                                            ? <td><i id={x.journal_Id} className="fa fa-pencil-square-o" aria-hidden="true" onClick={this.edit} title="edit"></i>
                                                              &nbsp;&nbsp;&nbsp;<i  id={x.journal_Id} className="fa fa-times" aria-hidden="true" onClick={this.delete} title="delete"></i></td>
                                                            : <td></td>
                                                        }
                                                        
                                                    </tr>
                                                )
                                            })
                                    )
                                }
                            </tbody>
                           
                        </Table>
                      
                    </div>
               </div>
               <Button variant="primary" type="submit" onClick={this.handleShow}>Add Transactions</Button>
               <br></br><br></br>
               <AddTransactionModal
                    journalId={this.state.journalId}
                    show={this.state.show}
                    title={this.state.title}
                    onSave={this.handleSave}
                    onHide={this.handleClose} />
                    
               <ClinicPagination totalItemsCount={this.state.totalRecords} onChange={this.handlePageChange}></ClinicPagination>
               </Form>
               <BlockUI blocking={this.state.blocking}></BlockUI>
              
               </div>
             
            </div>
        );
    }
}

export default Authorization(Journal, ['superadmin','doctor'])