import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class PatientService {
  add(data) {
    return axios.post(API_URL.patient.add, data,{ headers: authHeader() });
  }

  addLoyalty(data) {
    return axios.post(API_URL.patient.registerLoyalty, data,{ headers: authHeader() });
  }
  updatePoints(data) {
    return axios.post(API_URL.patient.updatePoints, data,{ headers: authHeader() });
  }
  update(data) {
    return axios.post(API_URL.patient.update, data,{ headers: authHeader() });
  }

  getPatient() {
    return axios.get(`${API_URL.patient.getAll}`, { headers: authHeader() });
  }

  searchPatient(condition){
    return axios.post(API_URL.patient.search, condition,{ headers: authHeader() });
  }

  getPatientByIcNo(condition){
    return axios.post(API_URL.patient.getPatient, condition,{ headers: authHeader() });
  }
}

export default new PatientService();
