import React, {Component} from 'react'
import NavBar from '../nav-bar.component'
import SearchPatient from '../shared/search-patient.component'
import {Table, Form} from 'react-bootstrap'
import visitHistoryService from '../../services/visit-history.service'
import history from '../../history'
import { Redirect} from 'react-router-dom'
import PatientHistoryModal from '../modal/patient-history-modal.component'
import Authorization from '../../helpers/auth'

class PatientHistory extends Component {

    constructor(props){
        super(props)
        this.state = {
            items: [],
            searchType: 1,
            show: false,
            showToast: false,
            title: '',
            body: '',
            data: []
        }
    }
    handleChange = (event) => {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }
    findPatient = () => {
        if(this.state.search === undefined || this.state.search.trim().length === 0 ) {
            alert('Please type in search')
            return
        }
        let condition = {
            name: '',
            patientId: ''
        }
        if(this.state.searchType == 1){
            condition.name = this.state.search;
        }
        if(this.state.searchType == 2){
            condition.patientId = this.state.search;
        }
        this.setState({
            blocking: true
        })

        visitHistoryService.getVisit(condition).then(result => {
            this.setState({
                items: result.data,
                isLoaded: true,
                blocking: false
            })
        })
    }
    tableHeader = () => {
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">Visit Date</th>
                <th key="2">Ic No</th>
                <th key="3">Name</th>
            </tr>
        );
    }

    tableData = () => {
       
    return this.state.items.map((v, index) => {
        const { visitId, visitDate, nama, patientId } = v //destructuring
        return (
            <tr key={index} data-ic={patientId} data-visitdate={visitDate} data-visitid={visitId} onClick={this.handleVisitHistory}>
                <td>{index}</td>
                <td>{visitDate}</td>
                <td>{patientId}</td>
                <td>{nama}</td>
            </tr>
        )
    })
    }

    handleClose = () => {
        this.setState({
            show: false
          });
  
    }

    handleVisitHistory = (e) => {
        const visitId = e.currentTarget.getAttribute('data-visitid');
        const icno = e.currentTarget.getAttribute('data-ic');
        const payment = e.currentTarget.getAttribute('data-payment');
        const visitDate = e.currentTarget.getAttribute('data-visitdate')
          
        this.setState({
            show: true,
            visitId,
            icno,
            visitDate
        })
        //this.setState({ redirect: `/visit/history/detail/${visitId}/${icno}`});
    }
    render(){
        
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
            <div className="menu">
            <NavBar></NavBar>        
            </div>
            <div className="content">
                    <h2>Patient History</h2>
                    <Form className = "formClinic">
                    <SearchPatient findPatient={this.findPatient} handleChange={this.handleChange}></SearchPatient>
                    {
                        this.state.items && (
                           <Table responsive striped bordered hover size="sm" variant="dark">
                               <thead>
                                  {this.tableHeader()}
                               </thead>
                               <tbody>
                                   {this.tableData()}
                               </tbody>
                           </Table>
                           
                        )
                    }
                    <PatientHistoryModal
                        show={this.state.show}
                        visitId={this.state.visitId}
                        visitDate={this.state.visitDate}
                        icNo={this.state.icno}
                        title={this.state.title}
                        body={this.state.body}
                        data={this.state.data}
                        onHide={this.handleClose} /> 
                </Form>
            </div>
            </>
        )
    }
}

export default Authorization(PatientHistory, ['superadmin','doctor','locum','staff'])