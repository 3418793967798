const CARD_DEDUCT = {
    LocalDebit: 0.005,
    LocalCredit : 0.015,
    ForeignCard : 0.0175,
    AmericanExpress : 0.023,
    UnionPay:0.019,
    JCB : 0.019

}

export default CARD_DEDUCT