import React, {Component} from 'react'
import {Form, Button, Table} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {totalMcDay, formatDate } from '../../helpers/utility'
import CounterService from '../../services/counter.service'

export default class MedicalCertification extends Component {
    constructor(props){
        super(props)
        this.state = {
            days: 1,
            index:0,
            MC: []
        }

        this.renderTableHeader = this.renderTableHeader.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
        this.handleAddMc = this.handleAddMc.bind(this);
        // this.formatDate = this.formatDate.bind(this);
        // this.totalMcDay = this.totalMcDay.bind(this);
        this.remove = this.remove.bind(this)
      
    }

    remove(event){
        var id = event.target.id;
        if(this.props.mcs.length > 0){
            this.props.mcs[id].status = "delete"
            this.props.removeCert(this.props.mcs)
        }
    }
    

    renderTableHeader() {
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">From</th>
                <th key="2">To</th>
                <th key="3">Days</th>
                <th key="4">Printed?</th>
                <th key="5">Action</th>
            </tr>
        );
    }
    
    renderTableData() {
       
            return this.props.mcs.map((data,index) => {
                let status = data.status != "delete" == true
                if(status){
                return (
                    <tr key={index}>
                        <td>{index}</td>
                        <td>{formatDate(data.datefrom)}</td>
                        <td>{formatDate(data.dateto)}</td>
                        <td>{totalMcDay(data.datefrom, data.dateto)}</td>
                        <td><input id={index} checked={data.printed} type="checkbox" value={data.printed} name="patient"/></td>
                        <td><Button id={index} onClick={this.remove}>Remove</Button></td>
                    </tr>
                 )
                }
            })  
      
           
        
    }
    
    handleAddMc(){
        var mc = this.props.mcs
        var datefrom = this.props.datefrom
        var dateto = this.props.dateto
        var noSiri = ""
        //var count = index;
        let days = totalMcDay(datefrom, dateto)
        
        CounterService.getNoSiriMc().then((response) => {
            noSiri= response.data;
            mc.push({datefrom, dateto, days, status: "new", noSiri})
            this.setState({
            mcs: mc,
            })
            this.props.handleAddMcs(mc)   
        });
    }

    isChecked = (id) => {
        let selectedId = this.state.selectedId;
        if(selectedId == id){
            return true;
        }

        return false;
    }
    render(){
        return (
                
                <div>
                {/* <div className="row">
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="MC" style={{marginLeft:'18px'}} />
                    </Form.Group>
                </div> */}
                <div className="row">
                    <div className="col-lg-3">
                        <Form.Group controlId="formLblStartDate">
                            <Form.Label>MC Date From</Form.Label>
                            
                                <DatePicker
                                    selected={this.props.datefrom}
                                    onChange={this.props.handleFromDateChange}
                                    dateFormat="dd-MM-yyyy"
                                    popperPlacement="bottom"
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                           
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                    <Form.Group controlId="formToDate">
                            <Form.Label>To</Form.Label>
                            <DatePicker       
                                selected={this.props.dateto}
                                onChange={this.props.handleToDateChange}
                                dateFormat="dd-MM-yyyy"
                                popperPlacement="bottom"
                                popperModifiers={{
                                    flip: {
                                        behavior: ["bottom"] // don't allow it to flip to be above
                                    },
                                    preventOverflow: {
                                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                    },
                                    hide: {
                                        enabled: false // turn off since needs preventOverflow to be enabled
                                    }
                                }}
                           />
                        </Form.Group>
                    </div>
                    <div className="col-lg-1">
                        <Button variant="primary" onClick={this.handleAddMc} style={{marginTop: "30px", float:"right"}}>
                            Add
                        </Button>
                    
                    </div>
                
                </div>
                
                <div className="row">
                    <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.renderTableData()}
                        </tbody>
                        </Table>
                </div>
                </div>
                
        );
    }
}