import React, {Component} from 'react'
import Authorization from '../../helpers/auth'
import PatientVisitHistory from '../shared/patient-visit-history.component'
import { Modal, Button, Form} from 'react-bootstrap';


class PatientHistoryModal extends Component {
    constructor(props){
        super(props)
    
    }
    

    render(){
        return (
            <Modal  size="lg" backdrop="static" show={this.props.show} onHide={this.props.onHide}>

                <Modal.Header closeButton>
                    <Modal.Title>
                    <h5 style={{padding:"10px", backgroundColor:"black", color:"#FFFFFF"}}>Visit history for {this.props.icNo} on {this.props.visitDate}</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{backgroundColor: "#e9edf3"}}>

                <div className="row" >
                    <div className="modalClinic">
                    <Form>
                        <PatientVisitHistory visitId={this.props.visitId} icNo={this.props.icNo}></PatientVisitHistory>
                    </Form>
                    </div>
                    
                </div>
      
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide} >Close</Button>
                </Modal.Footer>
            </Modal>
        
    )
       
    }
}

export default Authorization(PatientHistoryModal, ['superadmin','doctor','staff'])