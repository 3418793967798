import React, {Component} from 'react'
import NavBar from '../../components/nav-bar.component'
import {Form, Button} from 'react-bootstrap'
import PatientService from '../../services/patient.service'
import { Redirect} from 'react-router-dom'
import history from '../../history'
import axios from 'axios';
import ClinicToast from '../shared/toast'
import Authorization from '../../helpers/auth'

export default class PatientEdit extends Component {
    constructor(props){
        super(props)
        this.state = {
            icno: '',
            nama: '',
            alamat1: '',
            alamat2: '',
            poskod: '',
            bandar: '',
            negeri: '',
            emel: '',
            telepon: '',
            allergy: '',
            title: 'Edit Patient',
            showToast: false,
            points: 0,
        }
    }

    componentDidMount = () =>{
        var id = this.props.match.params.id
        if(id){
            let condition = {
                icno: id
            }
            PatientService.getPatientByIcNo(condition).then(result => {
                if(result){
                    const {id, icNo, nama,alamat1,alamat2, poskod,bandar,negeri,emel,telepon, allergy, points} = result.data
                    this.setState({
                        id,
                        icNo,
                        nama,
                        alamat1,
                        alamat2,
                        poskod,
                        bandar,
                        negeri,
                        emel,
                        telepon,
                        allergy,
                        points,
                    })
                }
            })
        }
    }
      
    // handleSelect = (event) => {
    
    //     var value= event.target.value
    //     var name = event.target.name

    //     this.setState({
    //         [name]: value
    //       });
    // }
  
    handleCancel = () => {
        this.setState({ redirect: `/patient/list`});
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }
    handleUpdate = (event) =>{
        event.preventDefault()
        const {id,icNo, nama,alamat1,alamat2, poskod,bandar,negeri,emel,telepon, allergy, points} =  this.state

        let patient = {}
        
        patient["id"] = id
        patient["icNo"] = icNo
        patient["nama"] = nama
        patient["alamat1"] = alamat1
        patient["alamat2"] = alamat2
        patient["poskod"] = poskod
        patient["bandar"]= bandar
        patient["negeri"] = negeri
        patient["emel"] = emel
        patient["telepon"] = telepon
        patient["allergy"] = allergy
        patient["points"] = points

        PatientService.update(patient).then(
            response => {
                this.setState({
                    showToast: true,
                    message:'Update successfully',
                    bgSucces: true
                })
            },
            error => {
              if(error.response && error.response.data){
                this.setState({
                    showToast: true,
                    message:error.response.data.message,
                    bgSucces: false
                })
              }
            
            }
          );
        
    }
    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
    }
    
    render(){
        if (this.state.redirect != null) {
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return(
            <>
            <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
                </div> 
                <div className="content">
                    <h2>{this.state.title}</h2>
                    <Form className = "formClinic" onSubmit={this.handleUpdate}>
                        <Form.Group controlId="formIcNo">
                            <Form.Label>Identification No</Form.Label>
                            <Form.Control type="text" name="icNo" placeholder="Ic No" onChange={this.handleInputChange} value={this.state.icNo || ''} required />
                        </Form.Group>
                        <Form.Group controlId="formNama">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="nama" placeholder="Name" onChange={this.handleInputChange} value={this.state.nama} required />
                        </Form.Group>
                        <Form.Group controlId="formAlamat1">
                            <Form.Label>Address1</Form.Label>
                            <Form.Control type="text" name="alamat1" placeholder="Address" onChange={this.handleInputChange} value={this.state.alamat1 || ''}  />
                        </Form.Group>
                        <Form.Group controlId="formAlamat2">
                            <Form.Label>Address2</Form.Label>
                            <Form.Control type="text" name="alamat2" placeholder="Address" onChange={this.handleInputChange} value={this.state.alamat2 || ''}  />
                        </Form.Group>
                        <Form.Group controlId="formPoskod">
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control type="text" name="poskod" placeholder="Postcode" onChange={this.handleInputChange} value={this.state.poskod || ''}  />
                        </Form.Group>
                        <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" name="bandar" placeholder="City" onChange={this.handleInputChange} value={this.state.bandar || ''}  />
                        </Form.Group>
                        <Form.Group controlId="formState">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" name="negeri" placeholder="State" onChange={this.handleInputChange} value={this.state.negeri || ''}  />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" name="emel" placeholder="Email" onChange={this.handleInputChange}  value={this.state.emel || ''}/>
                        </Form.Group>
                        <Form.Group controlId="formTelephone">
                            <Form.Label>Telephone</Form.Label>
                            <Form.Control type="text" name="telepon" placeholder="Telephone" onChange={this.handleInputChange}  value={this.state.telepon || ''} required />
                        </Form.Group>
                        <Form.Group controlId="formAllergy">
                            <Form.Label>Allergy</Form.Label>
                            <Form.Control type="text" name="allergy" placeholder="Allergy" onChange={this.handleInputChange} value={this.state.allergy || ''} />
                        </Form.Group>
                        <Form.Group controlId="formPoints">
                            <Form.Label>Points</Form.Label>
                            <Form.Control type="text" name="points" placeholder="Points" onChange={this.handleInputChange} value={this.state.points || ''} />
                        </Form.Group>
                        
                        <Button variant="primary" type="submit">
                            Update
                        </Button>
                        &nbsp;&nbsp;
                        <Button variant="primary" type="Button" onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    </Form>
                </div>
            </>
           
        );
    }
}