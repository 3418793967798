import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class CounterService {

  getJournalId(data) {
    return axios.post(API_URL.counter.getJournalId, { headers: authHeader() });
  }

  getNoSiriMc(){
    return axios.post(API_URL.counter.getNoSiriMc, { headers: authHeader() });
  }

}

export default new CounterService();
