import React, { Component, useImperativeHandle } from "react";
// import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form, Table} from 'react-bootstrap';
import PatientService from '../../services/patient.service'
import BlockUI from '../shared/BlockUI/blockUI'

class FindPatientModal extends Component {

    constructor(props){
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.findPatient = this.findPatient.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
        this.renderTableHeader = this.renderTableHeader.bind(this);
        this.onClose = this.onClose.bind(this);
        this.clearState = this.clearState.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
       
        this.state = {
            error: null,
            isLoaded: false,
            items: null,
            searchType: 1,
            name: null,
            icno: null,
            selectedId: 0,
            foundPatient: null,
            blocking: false
            };
    }

    isChecked = (id) => {
        let selectedId = this.state.selectedId;
        if(selectedId == id){
            return true;
        }

        return false;
    }

    changeHandler = (value, id, icno, name) => {
        if (value) {
          //this.props.onSelect(id);
          this.setState({
            selectedId: id,
            icno: icno,
            name: name
          });
        } else {
            this.setState({
                selectedId: 0
              });
        }
      };
    renderTableHeader() {
        // let header = Object.keys(this.state.items[0])
        // return header.map((key, index) => {
        //    return <th key={index}>{key.toUpperCase()}</th>
        // })
        return (
            <tr>
                <th key="0">Select</th>
                <th key="1">Ic No</th>
                <th key="2">Name</th>
                <th key="3">Phone No</th>
            </tr>
        );
     }
    renderTableData() {
        return this.state.items.map((patient, index) => {
           const { id, nama, icNo, telepon } = patient //destructuring
           return (
              <tr key={id}>
                 <td><input id={id} checked={this.isChecked(id)} type="checkbox" value={icNo} name="patient" onChange={e => this.changeHandler(e.target.checked, id, icNo, nama)}/></td>
                 <td>{icNo}</td>
                 <td>{nama}</td>
                 <td>{telepon}</td>
               
              </tr>
           )
        })
     }
    
     handleChange = (state) => {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        console.log('Selected Rows: ', state.selectedRows);
      };
    
    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

         this.setState({
          [name]: value
        });    
    }

    clearState() {
        this.setState({
            searchType: 1,
            search: '',
            icno: '',
            name: '',
            items: [],
            selectedId:0
        })
    }
    onClose() {
        this.clearState()
        this.props.onHide({ msg: 'Modal Closed!' });
    }
    onSubmit(){
        if(this.state.selectedId == 0){
            alert('Please select patient to register')
            return
        }
        this.props.onClick({ name: this.state.name, icno: this.state.icno });
        this.clearState();
    }
    handleSelect(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

        this.setState({
            searchType: value
        })
        
    }

    findPatient(){
        if(this.state.search === undefined || this.state.search.trim().length === 0 ) {
            alert('Please type in search')
            return
        }
        let condition = {
            nama: '',
            icno: ''
        }
        if(this.state.searchType == 1){
            condition.nama = this.state.search;
        }
        if(this.state.searchType == 2){
            condition.icno = this.state.search;
        }
        this.setState({
            blocking: true
        })
        PatientService.searchPatient(condition).then(response => {
      
               this.setState({
                   items: response.data,
                   isLoaded: true,
                   blocking: false
               })
          
        })


    }

    render() {
       var result = this.state.isLoaded;
     
        return (
                <Modal  size="lg" backdrop="static" show={this.props.show} onHide={this.onClose}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{backgroundColor: "#e9edf3"}}>

                    <div className="row">
                            <div className="col-lg-3">
                                <Form.Group controlId="formType">
                                    <Form.Label>Search by:</Form.Label>
                                    <Form.Control as="select" onChange={this.handleSelect}>
                                    <option value="1">Name</option>
                                    <option value="2">Ic No</option>
                                </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-lg-7">
                                <Form.Group controlId="formSearchValue">
                                    {/* <Form.Label>Doctor</Form.Label> */}
                                    <Form.Control style={{marginTop:"32px"}} type="text" name="search" placeholder="search" onChange={this.handleInputChange} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-2">
                                <Form.Group controlId="formSearchValue">
                                <Button  style={{marginTop:"32px", float:"right"}} onClick={this.findPatient} >Search</Button>
                                </Form.Group>
                            </div>
            
                    </div>
          
                    <h3 id='title'>Patients Information</h3>
                    <hr></hr>
                    {result ?( 
                        <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.renderTableData()}
                        </tbody>
                        </Table> ) : <div style={{margin: "0% 43%"}}>No records</div>
                    } 
                               

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.onClose} >Close</Button>
                        <Button variant="primary" onClick={this.onSubmit } >Select</Button>
                    </Modal.Footer>
                    <BlockUI blocking={this.state.blocking}></BlockUI>
                </Modal>
            
        )
     
    };
}

export default FindPatientModal;