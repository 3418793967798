var apiUrl;

if(process.env.NODE_ENV == 'development'){
  apiUrl = process.env.REACT_APP_DEV_BASE_API_URL
}
else{
  apiUrl = process.env.REACT_APP_PROD_BASE_API_URL;
}
const API_URL = {
  account: {
    login: `${apiUrl}/api/user/login`
  },
  patient: {
    getAll: `${apiUrl}/api/patient`,
    add: `${apiUrl}/api/patient`,
    registerLoyalty: `${apiUrl}/api/patient/registerloyalty`,
    updatePoints: `${apiUrl}/api/patient/updatepoints`,
    search: `${apiUrl}/api/patient/search`,
    getPatient: `${apiUrl}/api/patient/getpatient`,
    update: `${apiUrl}/api/patient/update`
  },
  visithistory: {
    register: `${apiUrl}/api/visithistory`,
    getDispensaryList: `${apiUrl}/api/visithistory/getDispensaryList`,
    getDispensaryListForPatientBuyOverCounter: `${apiUrl}/api/visithistory/getDispensaryListForPatientBuyOverCounter`,
    getVisitByIc: `${apiUrl}/api/visithistory/getVisitByIc`,
    getVisit: `${apiUrl}/api/visithistory/getVisit`,
    remove: `${apiUrl}/api/visithistory/delete`,

  },
  user: {
    getDoctors: `${apiUrl}/api/user/getDoctors`,
    getUsers: `${apiUrl}/api/user/getUsers`,
    getRoles: `${apiUrl}/api/user/getRoles`,
    getUserType: `${apiUrl}/api/user/getUserType`,
    add: `${apiUrl}/api/user`,
    getUserById: `${apiUrl}/api/user/getUserById`,
    update: `${apiUrl}/api/user/update`,
    delete:  `${apiUrl}/api/user/delete`,
    getProfile: `${apiUrl}/api/user/getProfile`
    
  },
  payment: {
    getPayments: `${apiUrl}/api/payment/getPayments`
  },
  medicine: {
    getMedicines: `${apiUrl}/api/medicine`,
    getMedicineById: `${apiUrl}/api/medicine/getmedicinebyid`,
    search: `${apiUrl}/api/medicine/search`,
    add: `${apiUrl}/api/medicine/add`,
    update: `${apiUrl}/api/medicine/update`,
    delete: `${apiUrl}/api/medicine/delete`
  },
  consultation: {
    add: `${apiUrl}/api/consultation/update`,
    updateMedicineOverCounter: `${apiUrl}/api/consultation/updateMedicineOverCounter`,
    getConsultation: `${apiUrl}/api/consultation/getConsultation`,
    getMedicines:  `${apiUrl}/api/patient-medicine/getMedicine`,
    complete: `${apiUrl}/api/visithistory/complete`,
    confirm: `${apiUrl}/api/consultation/confirm`,
    getStatus: `${apiUrl}/api/consultation/getStatus`,
    getStatusByVisitId: `${apiUrl}/api/consultation/getStatusByVisitId`,
    setStatus: `${apiUrl}/api/consultation/setStatus`,
    updCardType: `${apiUrl}/api/consultation/updateCardType`,
    updPaymentMethod: `${apiUrl}/api/consultation/updatePaymentMethod`
    
  },
  mc: {
    add: `${apiUrl}/api/mc`,
    update: `${apiUrl}/api/mc/update`,
    getMcs: `${apiUrl}/api/mc/getMc`,
    getMcToPrint: `${apiUrl}/api/mc/getMcToPrint`,
    getMcDetails: `${apiUrl}/api/mc/getMcDetails`
  },
  receipt: {
    load: `${apiUrl}/api/consultation/loadReceipt`,
    getReceipt : `${apiUrl}/api/consultation/getReceipt`,
    getInvoiceNo : `${apiUrl}/api/consultation/getInvoiceNo`,
    updInvoiceCounter: `${apiUrl}/api/consultation/updInvoiceCounter`,
    add: `${apiUrl}/api/receipt/add`,
    getInvoiceNoByVisitId: `${apiUrl}/api/receipt/getInvoiceNoByVisitId`

  },
  lookupAccount: {
    add: `${apiUrl}/api/lookupaccount`,
    update:  `${apiUrl}/api/lookupaccount/update`,
    delete:  `${apiUrl}/api/lookupaccount/delete`,
    getAllAccountByType: `${apiUrl}/api/lookupaccount/getAllAccountByType`,
    getAllAccount: `${apiUrl}/api/lookupaccount/getAllAccount`

  },
  journal: {
    add: `${apiUrl}/api/journal/add`,
    update: `${apiUrl}/api/journal/update`,
    delete: `${apiUrl}/api/journal/delete`,
    getJournal: `${apiUrl}/api/journal/getJournal`,
    getJournalByJournalId: `${apiUrl}/api/journal/getJournalByJournalId`,
    getLedgerByAccount:`${apiUrl}/api/journal/getLedgerByAccount`,
    getLedgerBalance: `${apiUrl}/api/journal/getLedgerBalance`,
    getIncomeStatement: `${apiUrl}/api/journal/getIncomeStatement`
  },
  journalDescription: {
    add: `${apiUrl}/api/journaldescription/add`,
    update: `${apiUrl}/api/journaldescription/update`,
    delete: `${apiUrl}/api/journaldescription/delete`,
    getJournal: `${apiUrl}/api/journaldescription/getJournal`
  },
  counter: {
    getJournalId: `${apiUrl}/api/counter/getJournalNo`,
    getNoSiriMc: `${apiUrl}/api/counter/getNoSiriMc`
  },
  test: {
    getToken: `https://apihssehira.petronas.com/api/v1/token`
  }
}

export default API_URL
  