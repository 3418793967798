import React, {Component} from 'react'
import {Form, Button, Label, Table} from 'react-bootstrap'
import ClinicToast from '../../shared/toast'
import Authorization from '../../../helpers/auth'
import NavBar from '../../nav-bar.component'
import DatePicker from "react-datepicker";
import { toDate } from '../../../helpers/utility'
import "react-datepicker/dist/react-datepicker.css";
import JournalService from '../../../services/journal.service'
import JournalDescriptionService from '../../../services/journal-description.service'
import LookupAccountService from '../../../services/lookup-account.service'
import BlockUI from '../../shared/BlockUI/blockUI'


class EditJournal extends Component {
    constructor(props){
        super(props)

        this.state = {
            startDate: new Date(),
            showToast: false,
            debit:[0,0],
            credit:[0,0],
            journalData:[],
            accId:[],
            accLookup: null

        }      
    }
   
    componentDidMount() {
        var journalId = this.props.match.params.id
        this.setState({
            journalId
        })
        let condition = {
            journalId
        }
        JournalService.getJournalByJournalId(condition).then(result => {
            if(result){
                this.setState({
                    journalData: result.data
                })

                let debit = result.data.map(x => {
                    return x.debit
                })
                let credit = result.data.map(x => {
                    return x.credit
                })
                let accId = result.data.map(x => {
                    return x.accountId
                })
                let ids = result.data.map(x => {
                    return x.id
                })
                this.setState({
                    debit,
                    credit,
                    accId,
                    ids
                })
                let desc = result.data.map(x => {
                    return x.journalDesc
                })
                
                if(desc.length > 0 ){
                    this.setState({
                        description: desc[0]
                    })
                }
                let date = result.data.map(x => {
                    return x.date
                })
               
                if(date.length > 0 ){
                    this.setState({
                        startDate: new Date(date[0].replace("T00:00:00",""))
                    })
                }
            }
        })

        LookupAccountService.getAllAccount().then(result => {
            if(result){
                result.data.splice(0,0,{ id:0,accountname: ""})        
                this.setState({
                    accLookup: result.data
                })
            }
        })
    }

  
    handleSave = async () =>{
        this.setState({
            showToast: true,
            message:'Successfully save transaction into journal',
            bgSucces: true
          });
          let startDate = this.state.startDate
          let desc = this.state.description
          let accId = this.state.accId
          let len = accId.length
          
          if(len==0){
              alert('Please select acccount to continue')
              return
          }  
      
            let journalId = this.state.journalId
                    
            for(var i=0;i<len;i++){
                let journal = {
                    journal_id: journalId,
                    date: startDate,
                    accountId: 0,
                    debit: 0,
                    credit: 0
                }

                journal.accountId = accId[i]
                journal.debit = this.state.debit[i]
                journal.credit = this.state.credit[i]
                journal.id = this.state.ids[i]
                const cloneJ = { ...journal };
                JournalService.update(cloneJ).then(result => {
                    console.log('update transaction to journal')
                })
            }
            let description = {
                journal_id: journalId,
                description: desc
            }
            JournalDescriptionService.update(description).then(result => {
                //this.clearData()
                console.log('add description to journaldescription')
            })
    }


    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
     
    }
   
    handleStartDateChange = (date) => {
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
            startDate: new Date(updDate)
        });
    }


    handleChange = (event) => {
        let value = event.target.value
        this.setState({
            search: value
        })
    }
    renderLookup = () => {
        if(this.state.accLookup == null) return                                     
        return this.state.accLookup.map(x =>{
              return (
                  <option value={x.id}>{x.accountname}</option>
                 
                  )
              })
    }
    handleInputChange = (event) =>{
        let index = event.target.id
        let name = event.target.name
        let value = event.target.value

        if(name=='debit'){
            let debit = this.state.debit
            debit[index] = value
            this.setState({
                debit
            })
        }
        else if(name=='credit'){
            let credit = this.state.credit
            credit[index] = value
            this.setState({
                credit
            })
        }
        else if(name=='accId'){
            let accId = this.state.accId
            accId[index] = value
            this.setState({
                accId
            })
        }
        else{
            this.setState({
                [name]:value
            })
        }
       
    }

    render(){
        return(
            <div>
                <div className="menu">
                    <div className="mainToast">
                        <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                    </div>
                    <NavBar></NavBar>
                </div> 
                <div className="content">
                <Form className = "formClinic" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Group controlId="formStartDate">
                                    <Form.Label>Date: </Form.Label>
                                    <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    dateFormat="dd-MM-yyyy"
                                    />
                                 </Form.Group>
                            </div>
                            <div className="col-lg-12">
                                <Form.Group controlId="formDesc">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="text" value={this.state.description} name="description" placeholder="description" onChange={this.handleInputChange} required />
                                </Form.Group>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Account</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    
                                        this.state.journalData && (
                                            this.state.journalData.map((x,index) => {
                                                //const { debit, credit } = x //destructuring
                                                    return (
                                                            <tr key={index}>
                                                            <td>
                                                            <select name="accId" id={index} onChange={this.handleInputChange} value={this.state.accId[index]}>
                                                             {this.renderLookup()}
                                                             </select>
                                                            </td>
                                                            <td><input type="text" id={index} value={this.state.debit[index]} name="debit" onChange={this.handleInputChange}></input></td>
                                                            <td><input type="text" id={index} value={this.state.credit[index]} name="credit" onChange={this.handleInputChange}></input></td>
                                                            
                                                        </tr>
                                                    )    
                                            })
                                        )
                                    }
                                    </tbody>
                                </Table>
                      
                                </div>
                            </div>
                            
                            <div className="col-lg-5">
                                <Button style={{marginTop: "30px"}} variant="primary" onClick={this.handleSave}>
                                   Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                
                <BlockUI blocking={this.state.blocking}></BlockUI>
              
                </div>
             
            </div>
        );
    }
}

export default Authorization(EditJournal, ['superadmin','doctor'])