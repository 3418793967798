import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '../constants/api-urls.constant';


class LookupAccountService {
  add(acc){
    return axios.post(`${API_URL.lookupAccount.add}`, acc, { headers: authHeader() });
  }
  update(acc){
    return axios.post(`${API_URL.lookupAccount.update}`, acc, { headers: authHeader() });
  }
  delete(acc){
    return axios.post(`${API_URL.lookupAccount.delete}`, acc, { headers: authHeader() });
  }

  getAllAccountByType(acc){
    return axios.post(`${API_URL.lookupAccount.getAllAccountByType}`, acc, { headers: authHeader() });
  }
  getAllAccount(){
    return axios.post(`${API_URL.lookupAccount.getAllAccount}`, { headers: authHeader() });
  }
}

export default new LookupAccountService();
