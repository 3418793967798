import React, { Component } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer  } from '@react-pdf/renderer';
import McService from '../../services/mc.service'
import { formatDate, totalMcDay, numberAsString } from '../../helpers/utility';


// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      margin:"5 0",
      fontSize: 10
    },
    main: {
      flexDirection: 'column',
      flex: 1
    },
    section: {
      backgroundColor: '#ffffff',
      margin:"0 20",
      flexDirection: 'column',
      padding: 2,
      flex: 0
    },
    name: {
      margin: "0 2",
      flex: 0,
      color: '#000000',
      fontSize:10,
    },
    italic: {
      fontStyle: 'italic',
      fontSize:8,
    },
    boldcenter: {
      fontWeight:'bold',
      textAlign: 'center'
    },
    underline: {
      textDecoration: 'underline'
    },
    noSiri: {
      fontSize:18,
      margin: 2,
      color: 'red',
      textAlign: 'right'
    },
    address: {
      margin: 2,
      flex: 0,
      color: '#000000',
      textAlign: 'center'
    },
    sign: {
      margin: 2,
      padding:'2 4',
      flex: 0,
      color: '#000000',
      textAlign: 'right'
    },
    realsign: {
      marginTop: 20,
      padding:'2 4',
      textAlign: 'right'
    },
    sectionData: {
      margin:"0 20",
      padding: 2,
      flex: 0,
      flexDirection: 'row'
    }
  });

export default class MyMc extends Component {
    constructor(props){
      super(props)
    }

    componentDidMount = ()=>{
      var visitid = this.props.visitId
      let data = {
          visitid
      }

    
      
      McService.getMcDetails(data).then(result => {
        if(result.data.length > 0 ) {
        const { icno, name, dateFrom, dateTo, noSiri, mcNo } =  result.data[0]
        
        this.setState({
            mcNo,
            icno,
            name,
            dateFrom: formatDate(dateFrom),
            dateTo: formatDate(dateTo),
            noSiri,
            totalMcDay: totalMcDay(dateFrom, dateTo)
        })
      }
        
    })
      
    }

  render(){ 
    {
      let data = {
        mcNo: this.state?.mcNo
      }

      McService.update(data).then(response => {
        console.log(response.data)
      })
    }
    
     return (

      <Document>
        <Page size="A4" style={styles.page}>
         <View style={styles.main}>
            <View style={styles.section}>
                <View style={[styles.name, styles.boldcenter]}>
                    <Text>KLINIK DR. ENGKU BAHIYAH</Text>
                </View>
                <View style={styles.address}>
                    <Text>No. 20-G, Jalan Puteri 2A/2, Bandar Puteri Bangi,</Text>
                    <Text>43000 Kajang. Tel: 03-89200548  </Text>
                </View>
                <View style={styles.sectionData}>
                <View style={{textAlign:"right", marginRight:"15"}}>
                  <Text >No:</Text>
                </View>
                <View style={styles.noSiri}>
                  <Text>{this.state?.noSiri}</Text>
                </View> 
                
                </View>
                
              
            </View>
            <View style={styles.section}>
              <View style={styles.boldcenter}>
                      <Text>SIJIL SAKIT</Text>
              </View>
            
              <View style={[styles.boldcenter, styles.underline]}>
                      <Text>SICK CERTIFICATE</Text>
              </View>
            </View>
            <View style={styles.section}>
             <Text>Saya mengesahkan bahawa saya telah memeriksa</Text>
             <Text style={styles.italic}>I hereby certify that I have examined</Text>
             <Text style={{marginTop:"2"}}>{this.state?.name}</Text>
            </View>
           
             <View style={styles.sectionData}>
                <View style={{width:"100"}}>
                  <Text>No. K/P</Text>
                  <Text style={styles.italic}>I/C. No </Text>
                </View>
                <View style={{width:"100"}}>
                  <Text style={{marginRight:"30"}}> {this.state?.icno}</Text>
                
                </View>
                <View style={{width:"100"}}>
                <Text>dari Jabatan</Text>
                <Text style={styles.italic}>from Department</Text>
                </View>
                <View style={{width:"200"}}>
                  <Text style={{marginRight:"30"}}>YANG BERKENAAN</Text>
                  </View>
                
             </View>
             <View style={styles.sectionData}>
             <View style={{width:"300"}}>
                  <Text>dan mendapati beliau tidak berupaya bertugas untuk selama</Text>
                  <Text style={styles.italic}>and find that he/she will be unfit for duty for </Text>
                </View>
                <View style={{width:"100"}}>
                  <Text>{numberAsString(this.state?.totalMcDay)}</Text>
                </View>
                <View style={{width:"100"}}>
                  <Text style={{marginRight:"50"}}>hari</Text>
                  <Text style={{marginRight:"30"}}>days</Text>
                
                </View>
             </View>
             <View style={styles.sectionData}>
                <View style={{width:"50"}}>
                  <Text>dari</Text>
                  <Text style={styles.italic}>from</Text>
                </View>
                <View style={{width:"150"}}>
                  <Text>{this.state?.dateFrom}</Text>
                </View>
                <View style={{width:"100"}}>
                  <Text style={{marginRight:"50"}}>hingga</Text>
                  <Text style={{marginRight:"30"}}>to</Text>
                
                </View>
                <View style={{width:"50"}}>
                  <Text>Tarikh:</Text>
                  <Text style={styles.italic}>Date:</Text>
                </View>
                <View style={{width:"100"}}>
                  <Text>{this.state?.dateTo}</Text>
                </View>
             </View>
              <Text style={styles.realsign}>________________________________</Text>
              <Text style={styles.sign}>Tandatangan/Cop Doktor/Cop Klinik</Text>
             <Text style={styles.sign}>Signature/Doctor's Stamp/Clinic Stamp</Text>
        
              <Text style={{margin: "0 16"}}>Bahawa sijil ini tidak sah digunakan untuk kes mahkamah</Text>     
              <Text style={[styles.italic,{margin: "0 16"}]}>This certificate is not valid for court use.</Text>
         </View>
        
         
        </Page>
      </Document>
      )
    }  

  }


