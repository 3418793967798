import React, {Component} from 'react'
import {Form, Button, Label, Table} from 'react-bootstrap'
import ClinicToast from '../../shared/toast'
import Authorization from '../../../helpers/auth'
import NavBar from '../../nav-bar.component'
import LookupAccountService from '../../../services/lookup-account.service'
import AccountEditModal from '../../modal/account-edit-modal.component'


class LookupAccounting extends Component {
    constructor(props){
        super(props)

        this.state = {
            payment: 0,
            name: ' ',
            patientId: ' ',
            showToast: false,
            account: [],
            accountTypeId: 1,
            accountName: '',
            show: false

        }      
    }
   
    componentDidMount() {
        

    }

    handleClose = () => {
        this.setState({
            show: false
          });
  
    }


    handleCloseToast = () =>{
        this.setState({
            showToast: false
        })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        let condition = {
            Accountname:this.state.search,
            Accounttypeid: this.state.accountTypeId
        }

        LookupAccountService.getAllAccountByType(condition).then(result => {
            if(result){
                this.setState({
                    account: result.data
                })
            }
        })
    }

    addNew = () => {
        this.setState({
            show: true,
            action: "Add",
            accountName: ''
        })
    }
    delete = (e) => {
        const id = e.currentTarget.id
        let status = window.confirm("Are you sure to delete this account?")
        if(status){
            LookupAccountService.delete({id}).then(result => {
                if(result){
                let newItems =  this.state.account.filter(x => {
                    return x.id != id
                    })
                    this.setState({
                        account : newItems
                    })
                }
            })
        }
    }
    edit = (e) => {
        const id = e.currentTarget.id
        const name = e.currentTarget.getAttribute('data-name')
       
          
        this.setState({
            show: true,
            action: "Edit",
            accountTypeId: this.state.accountTypeId,
            accountName: name,
            id
        })
    }
    handleAccountType = (event) => {
        let value = event.target.value
        this.setState({
            accountTypeId: value
        })
    }

    handleChange = (event) => {
        let value = event.target.value
        this.setState({
            search: value
        })
    }
    
    render(){
        return(
            <div>
                <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
                </div> 
                <div className="content">
                <Form className="formClinic" onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-lg-4">
                        <Form.Label>Account Type</Form.Label>
                        <Form.Control as="select" onChange={this.handleAccountType}>
                            <option value="1">Asset</option>
                            <option value="2">Liability</option>
                            <option value="3">Equity</option>
                            <option value="4">Revenue</option>
                            <option value="5">Expense</option>
                        </Form.Control>
                    </div>
                    <div className="col-lg-4">
                    <Form.Group controlId="formAccountName">
                        <Form.Label>Account Name</Form.Label>
                        <Form.Control type="text"  name="accountName" placeholder="account name" onChange={this.handleChange} />
                     </Form.Group>
                    </div>
                    <div className="col-lg-4">
                         <Button type="submit" onSubmit={this.handleSubmit} style={{marginTop:"32px"}}>Search</Button>
                    </div>
               </div>
               <div className="row">
                   <div className="col-lg-12">
                       <Button type="button" onClick={this.addNew}>Add</Button>
                   </div>
               </div>
              
               <hr></hr>
    
               <div className="row">
                    <div className="col-lg-12">
                        <Table responsive striped bordered hover size="sm" variant="dark">
                            <thead>
                                <tr>
                                    <th>Account Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.account && (
                                        this.state.account.map((x,index) => {
                                            const { id, accountname } = x //destructuring
                                            return (
                                                <tr key={index}>
                                                    <td>{accountname}</td>
                                                    <td><i id={id} className="fa fa-pencil-square-o" aria-hidden="true"  title="edit" data-name={accountname} onClick={this.edit} ></i>&nbsp;&nbsp;&nbsp;
                                                        <i id={id} className="fa fa-times" aria-hidden="true" onClick={this.delete} title="delete"></i></td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                            </tbody>
                           
                        </Table>
                      
                    </div>
               </div>
               {
                   this.state.show && (
                    <AccountEditModal
                    show={this.state.show}
                    accountTypeId={this.state.accountTypeId}
                    accountName={this.state.accountName}
                    action={this.state.action}
                    id={this.state.id}
                    title={this.state.title}
                    body={this.state.body}
                    onHide={this.handleClose} /> 
                   )
               }
              
               </Form>
               </div>
             
            </div>
        );
    }
}

export default Authorization(LookupAccounting, ['superadmin','doctor'])