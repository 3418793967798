import React, {Component} from 'react'
import {Table} from 'react-bootstrap'
import NavBar from '../nav-bar.component'
import visitHistoryService from '../../services/visit-history.service'
import { Redirect} from 'react-router-dom'
import history from "../../history"

export default class PatientPharmacy extends Component {
    constructor(props){
        super(props)
        this.state = {
            dispensary: [],
            redirect: null
        }
    }

    componentDidMount = () => {
        visitHistoryService.getDispensaryList().then(result => {
            if(result){
                this.setState({
                    dispensary: result.data
                })
            }
        })
    }

    handleDispensary = (e) => {
        const visitId = e.currentTarget.getAttribute('data-item');
        this.setState({ redirect: `/patient/pharmacy/detail/${visitId}`});
    }
    renderTableHeader() {
        
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">Patient Id</th>
                <th key="2">Name</th>
            </tr>
        );
    }

    renderTableData() {
    if(this.state.dispensary == null) return
    return this.state.dispensary.map((patient, index) => {
        const { name, icno, visitId } = patient //destructuring
        return (
            <tr key={index} data-item={visitId} onClick={this.handleDispensary}>
                <td>{index}</td>
                <td>{icno}</td>
                <td>{name}</td>
            </tr>
        )
    })
    }

    render(){
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
            <div className="menu">
                <NavBar></NavBar>
            </div>
            
            <div className="content">
            <h2>Patient List for Dispensary</h2>
                <div className="formClinic">
                    <div className="row" style={{marginTop:"20px"}}>
                    {this.state.dispensary.length > 0 ?
                    (<Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.renderTableData()}
                        </tbody>
                    </Table>)
                    : ( <h5>All patient have taken their medicines, good jobs! </h5>)
                    }
                    </div>
                </div>
              
            </div>
            </>
        )
    }

}