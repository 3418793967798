import React, {Component} from 'react'
import {Table} from 'react-bootstrap'
import { Redirect} from 'react-router-dom'
import history from '../../../history'
import {totalMcDay } from '../../../helpers/utility'
import paymentService from '../../../services/payment.service'
import axios from 'axios';

export default class VisitHistory extends Component {
    constructor(props){
        super(props)
        this.state = {
            vhistory: [],
            redirect: null
        }
    }

    componentDidMount() {

        const paymentMethod = paymentService.getPayments();

       
        // let fDateTime = this.getDateFrom()
        // let toDateTime = this.getDateTo()
        
        // let conditions = {
        //     fromDate: fDateTime,
        //     toDate: toDateTime
        // }
 
        // const consult = ConsultationService.getPatientConsultation(conditions)
        // const status = ConsultationService.getStatus()

        axios.all([paymentMethod]).then(axios.spread((...responses) => {
        
            const lsPays = responses[0]
         
            this.setState({
                pays: lsPays.data
            })

            // var pays = lsPays.data.map((data, index)=>
            //     <option key={index} value={data.id}>{data.name}</option>
            // )
            // use/access the results 
          })).catch(errors => {
            // react on errors.
            console.log(errors)
          })

    }

    handleVisitHistory = (e) => {
        const visitId = e.currentTarget.getAttribute('data-visitid');
        const icno = e.currentTarget.getAttribute('data-ic');
        const payment = e.currentTarget.getAttribute('data-payment');
          
        this.setState({ redirect: `/visit/history/detail/${visitId}/${icno}/${payment}`});
    }
    renderTableHeader() {
        
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">Visit Date</th>
                <th key="2">Doctor</th>
                <th key="3">Status</th>
                <th key="4">Mc</th>
            </tr>
        );
    }

    renderTableData(vhistory) {
    if(vhistory == null) return
    
    return vhistory.map((v, index) => {
        const { visitId, visitDate, doctor, status, mc, dateFrom, dateTo, patientId, payment } = v //destructuring
        return (
            <tr key={index} data-ic={patientId} data-visitdate={visitDate} data-visitid={visitId} data-payment={payment} onClick={this.handleVisitHistory}>
                <td>{index}</td>
                <td>{visitDate}</td>
                <td>{doctor}</td>
                <td>{status}</td>
                <td>{totalMcDay(dateFrom, dateTo)}</td>
            </tr>
        )
    })
    }

    render(){

        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
                    <div className="row" style={{marginTop:"20px"}}>
                        <Table responsive striped bordered hover size="sm" variant="dark">
                            <thead>
                                {this.renderTableHeader()}
                            </thead>
                            <tbody>
                                {this.props.vhistory.length > 0 ? this.renderTableData(this.props.vhistory):
                                this.renderTableData(null)
                                }
                            
                            </tbody>
                        </Table>
                    </div>        
            </>
        )
    }

}