import React, {Component} from 'react'
import {Form, Table, Button} from 'react-bootstrap'
import NavBar from '../../nav-bar.component'
import { Redirect} from 'react-router-dom'
import history from '../../../history'
import MedicineService from '../../../services/medicine.service'
import Authorization from '../../../helpers/auth'
import { isRoleAuthorize } from '../../../helpers/utility'


class Medicine extends Component {
    constructor(props){
        super(props)
        this.state = {
            redirect: null,
            isLoaded: false
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

         this.setState({
          [name]: value
        });    
    }

   addMedicine = () => {
    this.setState({ redirect: `/medicine/add`});
   }

   findMedicine = () => {
    if(this.state.search === undefined || this.state.search.trim().length === 0 ) {
        alert('Please type in search')
        return
    }
    let condition = {
        name: ''
    }
   
    condition.name = this.state.search;
  
    this.setState({
        blocking: true
    })
    MedicineService.search(condition).then(response => {
  
           this.setState({
               items: response.data,
               isLoaded: true,
               blocking: false
           })
      
    })
   }

   renderTableHeader() {
    return (
        <tr>
            <th key="1">no</th>
            <th key="2">Name</th>
            <th key="3">Quantity</th>
            <th key="4">Panel Price</th>
            <th key="5">Price</th>
            <th key="6">cost</th>
            <th key="7">notes</th>
            <th key="8">inactive</th>
        </tr>
    );
 }
 renderTableData() {
    return this.state.items.map((user, index) => {
       const { id, name, qty, panelprice, price, cost, notes, inactive} = user //destructuring
       return (
          <tr key={id}>
             <td>{index}</td>
             <td width="20%">{name}</td>
             <td>{qty}</td>
             <td>{panelprice}</td>
             <td>{price}</td>
             <td>{cost}</td>
             <td width="20%">{notes}</td>
             <td>{inactive}</td>
             <td><i id={id} className="fa fa-pencil-square-o" aria-hidden="true" onClick={this.edit} title="edit"></i>&nbsp;&nbsp;&nbsp;            
             {
                     isRoleAuthorize(['superadmin','doctor']) ? (
                        <i  id={id} className="fa fa-times" aria-hidden="true" onClick={this.delete} title="delete"></i>
                     ) : <></>
             }
             </td>
             
          </tr>
       )
    })
 }
 edit = (e) => {
    const id = e.target.id
    this.setState({ redirect: `/medicine/add/${id}`});
}

delete = (e) => {
    const id = e.target.id
    let status = window.confirm("Are you sure to delete this medicine?")
    if(status){
        MedicineService.delete({id}).then(result => {
            if(result){
            let newItems =  this.state.items.filter(x => {
                return x.id != id
                })
                this.setState({
                    items : newItems
                })
            }
        })
    }
}

   handleSubmit= (event)=>{
   }

    render(){
        var result = this.state.isLoaded;
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
        return (
            <>
            <div className="menu">
                <NavBar></NavBar>
            </div>
            <div className="content">
                <h2>Medicine maintenance</h2>
            <Form className = "formClinic" onSubmit={this.handleSubmit}>

                <div className="row">
                    <div className="col-lg-3">
                        <Form.Group controlId="formType">
                            <Form.Label>Search by:</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="col-lg-7">
                        <Form.Group controlId="formSearchValue">
                            {/* <Form.Label>Doctor</Form.Label> */}
                            <Form.Control style={{marginTop:"32px"}} type="text" name="search" placeholder="search" onChange={this.handleInputChange} />
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group controlId="formSearchValue">
                        <Button  style={{marginTop:"32px", float:"right"}} onClick={this.findMedicine} >Search</Button>
                        </Form.Group>
                    </div>
                    <Button className="fa fa-plus" aria-hidden="true" style={{margin:"30px 0px", float:"right"}} onClick={this.addMedicine} >Add</Button>
                </div>
                <h3 id='title'>Medicines Information</h3> 
            <hr></hr>
            {result ?( 
                <>
                              
                <Table responsive striped bordered hover size="sm" variant="dark">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
                </Table> </> )  : <div style={{margin: "0% 43%"}}>No records</div>
            } 
                
            </Form>
            </div>
            </>
        )
    }

}

export default Authorization(Medicine, ['superadmin','admin','doctor','staff'])