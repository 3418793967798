import React, {Component} from 'react'
import Login from '../components/login.component';
import Home from '../components/home.component';
import Profile from '../components/profile.component'
import {Route, Switch, Redirect} from 'react-router-dom'
import isLoggedIn from '../helpers/isLoggin';
import PatientRegister from '../components/patient/patient-register.component'
import PatientLoyalty from '../components/patient/patient-loyalty.component'
import PatientHistory from '../components/patient/patient-history.component'
import PatientList from '../components/patient/patient-list.component'
import PatientBuyMedicine from '../components/patient/patient-buy-medicine.component'
import DoctorConsultation from '../components/doctor/consultation/doctor-consultation.component'
import DoctorAddConsultation from '../components/doctor/consultation/consultation-add.component'
import PatientAdd from '../components/patient/patient-add.component';
import PatientEdit from '../components/patient/patient-edit.component';
import PatientPharmacy from '../components/patient/patient-pharmacy.component';
import PatientPharmacyDetail from '../components/patient/patient-pharmacy-detail.component';
import PatientBuyMedicineDetail from '../components/patient/patient-buy-medicine-detail.component'
import Medicine from '../components/maintenance/medicine';
import User from '../components/maintenance/user';
import VisitHistoryDetail from '../components/doctor/consultation/visit-history-detail.component'
import UserAdd from '../components/maintenance/user/user-add.component';
import MedicineAdd from '../components/maintenance/medicine/medicine-add.component';
import ReceiptPreview from '../components/receipt/receipt-preview.component'
import McPreview from '../components/receipt/mc-preview.component'
import PrintReceipt from '../components/print/print-receipt.component'
import PrintMc from '../components/print/print-mc.component'
import LookupAccounting from '../components/maintenance/accounting/lookup-accounting.component'
import Journal from '../components/accounting/journal/journal.component'
import Ledger from '../components/accounting/ledger/ledger.component'
import EditJournal from '../components/accounting/journal/edit-journal.component'
import IncomeStatement from '../components/accounting/income-statement/income-statement.component'

export default class Routes extends Component {


render(){
    let result = isLoggedIn()
    return(
        result ? (
                <Switch>
                  
                    <Route path="/home" component={Home} />
                    <Route path="/login" component={Login} />
                    <Route path="/patient/register" component={PatientRegister} />
                    <Route path="/patient/loyalty" component={PatientLoyalty} />
                    <Route path="/patient/add" component={PatientAdd} />
                    <Route path="/patient/edit/:id" component={PatientEdit} />
                    <Route path="/patient/history" component={PatientHistory} />
                    <Route exact path="/patient/buymedicine" component={PatientBuyMedicine} />
                    <Route path="/patient/buymedicine/detail/:id" component={PatientBuyMedicineDetail} />
                    <Route path="/visit/history/detail/:id/:icno" component={VisitHistoryDetail} />
                    <Route path="/patient/list" component={PatientList} />
                    <Route path="/maintenance/medicine" component={Medicine} />
                    <Route path="/maintenance/user" component={User}/>
                    <Route path="/maintenance/accounting" component={LookupAccounting}/>
                    <Route exact path="/patient/pharmacy" component={PatientPharmacy} />
                    <Route path="/patient/pharmacy/detail/:id" component={PatientPharmacyDetail} />
                    <Route exact path="/doctor/consultation" component={DoctorConsultation} />
                    <Route path="/doctor/addconsultation/:id/:icno/:paymentId" component={DoctorAddConsultation} />
                    <Route path="/user/add/:id" component={UserAdd} />
                    <Route path="/user/add" component={UserAdd} />
                    <Route path="/medicine/add/:id" component={MedicineAdd} />
                    <Route path="/medicine/add" component={MedicineAdd} />
                    <Route path="/receipt/:id/:receiptno/:details" component={ReceiptPreview} />
                    <Route path="/mc/:visitid" component={McPreview} />
                    <Route path="/print/receipt" component={PrintReceipt} />
                    <Route path="/print/mc" component={PrintMc} />
                    <Route exact path="/accounting/ledger" component={Ledger} />
                    <Route exact path="/accounting/journal" component={Journal} />
                    <Route exact path="/accounting/incomestatement" component={IncomeStatement} />
                    <Route path="/accounting/journal/edit/:id" component={EditJournal} />

                    <Route exact path="/" component={Home} />
                   
                    {/* <Route path="/login" render={() => (
                        result ? (
                        <Redirect to="/home"/>
                        ) : (
                                <Login />
                            )
                    )}/> */}
                </Switch>
              
            
           
        ) :
        (
            <Login></Login>
        )

    )
   }
}