import React, {Component, useState} from 'react'
import NavBar from '../nav-bar.component'
import {Form, Button} from 'react-bootstrap'
import FindPatientModal from '../modal/find-patient-modal.component'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PatientService from '../../services/patient.service';
import PATIENT_STATUS from '../../constants/patient-status.constant'
import PAYMENT_METHOD from '../../constants/payment-method.constant'
import VISIT_STATUS from '../../constants/visit-status.constant'
import userService from '../../services/user.service'
import paymentService from '../../services/payment.service'
import BlockUI from '../shared/BlockUI/blockUI'
import axios from 'axios';
import ClinicToast from '../shared/toast'
import Footer from '../footer.component'


// const ExampleCustomTimeInput = ({ value, onChange }) => (
//     <input
//     value={value}
//     onChange={e => onChange(e.target.value)}
//     style={{ border: "solid 1px pink" }}
//     />
// );
export default class PatientLoyalty extends Component {
    constructor(props){
        super(props)
        this.state = {
            action: 1,
            show: false,
            showToast: false,
            title: '',
            body: '',
            data: [],
            icno: '',
            name: '',
            blocking: false,
            bgSucces: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseToast = this.handleCloseToast.bind(this);
    } 


      handleShow = () => {
    
        this.setState({
          show: true,
          title: 'Find Patient',
          data: null
        });
      };
      
      handleFindPatient = (formData) => {

        var formValues = Object.assign({}, formData);
        
        let {name,icno} = formValues;

        this.setState({
            show: false,
            icno, 
            name
        });

    };

    handleClose = () => {
    
    this.setState({
        show: false,
        searchType: 1
    });

    };

    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }

    
    handleCloseToast(){
        this.setState({
            showToast: false
        })
    }
    handleSubmit(event){
        event.preventDefault();
 
        if(this.state.name== '' && this.state.icno == '')
        {
            alert('Ic no and name is empty, please find patient')
            return
        }
        

         let patient = {
            icNo : this.state.icno
        }
       
        PatientService.addLoyalty(patient).then(result => {
         if(result.data){
             if(result.data.loyalty == 1){
                //alert('This patient already registered')       
                this.setState({
                    showToast: true,
                    message:'successfully registered loyalty program',
                    bgSucces: true
                })        
             }
             else{
                //alert('This patient registered successfully')
                this.setState({
                    showToast: true,
                    message:'loyalty program not success to register',
                    bgSucces: false
                })
             }
         }
        },
        error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
  
            this.setState({
              loading: false,
              message: resMessage
            });
          }
        )
        
    }

    render(){
           
        return(
            <>
                <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
                </div> 

                <div className="content">
                    <h2>Register Loyalty Program</h2>
                    <Form className = "formClinic" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-8">
                                <Form.Group controlId="formIcNo">
                                    <Form.Label>Identification No</Form.Label>
                                    <Form.Control type="text" value={this.state.icno} name="icno" readOnly placeholder="Ic No" onChange={this.handleInputChange} required />
                                </Form.Group>
                            </div>
                            <div className="col-lg-4">
                                <Button style={{marginTop: "30px"}} variant="primary" onClick={this.handleShow}>
                                    Find Patient
                                </Button>
                            </div>
                        </div>
                    
                        <FindPatientModal
                            show={this.state.show}
                            title={this.state.title}
                            // body={this.state.body}
                            // data={this.state.data}
                            onClick={this.handleFindPatient}
                            onHide={this.handleClose} />
                    
                        
                        <Form.Group controlId="formNama">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={this.state.name} name="name" readOnly placeholder="Name" onChange={this.handleInputChange} required />
                        </Form.Group>
                                
                        <Button variant="primary" type="submit">
                            Register
                        </Button>
                    </Form>
                     <BlockUI blocking={this.state.blocking}></BlockUI>
                   
                     
                </div>
               

                 <Footer></Footer>

            
            </>
        );
    }
}