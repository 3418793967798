import React, {Component, useState} from 'react'
import NavBar from '../nav-bar.component'
import {Form, Button} from 'react-bootstrap'
import PatientService from '../../services/patient.service'
import FindReceiptModal from '../modal/find-receipt-modal.component'
import "react-datepicker/dist/react-datepicker.css";
import visitHistoryService from '../../services/visit-history.service'
import PATIENT_STATUS from '../../constants/patient-status.constant'
import PAYMENT_METHOD from '../../constants/payment-method.constant'
import VISIT_STATUS from '../../constants/visit-status.constant'
import userService from '../../services/user.service'
import paymentService from '../../services/payment.service'
import BlockUI from '../shared/BlockUI/blockUI'
import axios from 'axios';
import ClinicToast from '../shared/toast'
import Footer from '../footer.component'
import history from '../../history'
import { Redirect} from 'react-router-dom'

export default class PrintReceipt extends Component {
    constructor(props){
        super(props)
        this.state = {
            action: 1,
            show: false,
            showToast: false,
            title: '',
            body: '',
            data: [],
            icno: '',
            visitDate: new Date(),
            doctorId: 0,
            payment: 1,
            blocking: false,
            bgSucces: false,
            redirect: null,
            details: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleDoctor = this.handleDoctor.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleVisitDateChange = this.handleVisitDateChange.bind(this);
        this.convertUTCDateToLocalDate = this.convertUTCDateToLocalDate.bind(this);
        this.handleCloseToast = this.handleCloseToast.bind(this);
    } 

    componentDidMount() {
        this.setState({
            blocking: true
        })
        const doctor =userService.getDoctors();
        const payment = paymentService.getPayments();

        axios.all([doctor, payment]).then(axios.spread((...responses) => {
            this.setState({
                blocking: false
            })
            const lsDocs = responses[0]
            const lsPays = responses[1]

            var docs = lsDocs.data.map((data, index) =>
                    <option key={index} value={data.id}>{data.name}</option>
                    )

            this.setState({
                doctors: docs
            })
    
            var pays = lsPays.data.map((data, index)=>
                <option key={index} value={data.id}>{data.name}</option>
            )

            this.setState({
                payments: pays
            })

            // use/access the results 
          })).catch(errors => {
            // react on errors.
            console.log(errors)
          })

    }

    handleDetails = (event) =>{
        var check = event.target.checked
        this.setState({
            details: check
        })
    }

      handleShow = () => {
    
        this.setState({
          show: true,
          title: 'Find Patient',
          data: null
        });
      };
      
      handleFindPatient = (formData) => {

        var formValues = Object.assign({}, formData);
        
        let {visitId, receiptNo} = formValues;

        this.setState({
            redirect: `/receipt/${visitId}/${receiptNo}/${this.state.details}`
        })

    };

      handleClose = () => {
       
        this.setState({
          show: false,
          searchType: 1
        });

      };

    handleAction(event){
        this.setState({action :event.target.value});
    }
    handlePayment(event){
        this.setState({payment :event.target.value});
    }
    handleDoctor(event){
        this.setState({doctorId :event.target.value});
    }
    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
    }

    handleVisitDateChange(date){
        let n = new Date();
        let h = n.getHours();
        let m = n.getMinutes();
        let s = n.getSeconds();

        let updDate = new Date(date);
        updDate.setHours(h);
        updDate.setMinutes(m);
        updDate.setSeconds(s);
      
        this.setState({
          visitDate: new Date(updDate)
        });
      }
    convertUTCDateToLocalDate(date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),  date.getHours(), date.getMinutes(), date.getSeconds()));
    }
    handleCloseToast(){
        this.setState({
            showToast: false
        })
    }
    handleSubmit(event){

        event.preventDefault();
        if(this.state.name== '' && this.state.icno == '')
        {
            alert('Ic no and name is empty, please find patient')
            return
        }
        var visitDate = this.state.visitDate.toISOString();

        if(new Date(visitDate).getDate() == new Date().getDate()){
            let updDt = new Date()
            this.setState({
                visitDate: updDt
            })
            visitDate = updDt.toISOString()
        }
       
        let visit = {
            patientId : this.state.icno,
            datevisit : this.convertUTCDateToLocalDate(new Date(visitDate)),
            doctorId : this.state.doctorId,
            status : PATIENT_STATUS.Waiting,
            paymentmethod : this.state.payment
        }
       
        visitHistoryService.register(visit).then(result => {
         if(result.data){
             if(result.data.statusId == VISIT_STATUS.AlreadyRegistered){
                //alert('This patient already registered')       
                this.setState({
                    showToast: true,
                    message:'This patient already registered',
                    bgSucces: false
                })        
             }
             else{
                //alert('This patient registered successfully')
                this.setState({
                    showToast: true,
                    message:'This patient registered successfully',
                    bgSucces: true
                })
             }
         }
        },
        error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
  
            this.setState({
              loading: false,
              message: resMessage
            });
          }
        )
        
    }

    render(){
        if (this.state.redirect != null) {
            
            history.push(this.state.redirect)
            return <Redirect to={this.state.redirect} />
         }
           
        return(
            <>
                <div className="menu">
                <div className="mainToast">
                    <ClinicToast bgSucces={this.state.bgSucces} show={this.state.showToast} content={this.state.message} close={this.handleCloseToast}></ClinicToast>
                </div>
                <NavBar></NavBar>
                </div> 

                <div className="content">
                    <h2>Print Receipt</h2>
                    <Form className = "formClinic" onSubmit={this.handleSubmit}>
                        <div className="row">
                           
                            <div className="col-lg-2">
                                <Button variant="primary" onClick={this.handleShow}>
                                    Find Patient
                                </Button>
                            </div>
                            <div className="col-lg-2">
                                <Form.Check style={{marginTop:"10px"}} type="checkbox" label="details?" name="detail" onClick={this.handleDetails} />
                            </div>

                            
                        </div>
                    
                        <FindReceiptModal
                            show={this.state.show}
                            title={this.state.title}
                            body={this.state.body}
                            data={this.state.data}
                            onClick={this.handleFindPatient}
                            onHide={this.handleClose} />
                    
                        
                       
                    </Form>
                     <BlockUI blocking={this.state.blocking}></BlockUI>
                   
                     
                </div>
               

                 <Footer></Footer>

            
            </>
        );
    }
}