import React, {Component} from 'react'
import {Form, Button, Label} from 'react-bootstrap'
import paymentService from '../../services/payment.service'
import axios from 'axios';
import CARD_TYPE from '../../constants/card-type.constant';
import PAYMENT_METHOD from '../../constants/payment-method.constant';


export default class ConsultationDescription extends Component {
    constructor(props){
        super(props)

        this.state = {
            payment: 0,
            name: ' ',
            patientId: ' ',
            points: '0',
            loyalty: 0,

        }
     
        this.handleChange = this.handleChange.bind(this)
      
    }
   
    componentDidMount() {
        const paymentMethod = paymentService.getPayments();

        axios.all([paymentMethod]).then(axios.spread((...responses) => {
        
            const lsPays = responses[0]

            var pays = lsPays.data.map((data, index)=>
                <option key={index} value={data.id}>{data.name}</option>
            )

            this.setState({
                payments: pays
            })
    
            // use/access the results 
          })).catch(errors => {
            // react on errors.
            console.log(errors)
          })

    }

    handleChange(event){
        this.props.handleChange(event)
    }
    
    render(){
        return(
            <div>
                <div className="row">
                    <div className="col-lg-6">
                    <Form.Group controlId="formIcNo">
                        <Form.Label>Ic No</Form.Label>
                        <Form.Control type="text"  name="icno" readOnly placeholder="ic no" value={this.props.patientId || ''} />
                     </Form.Group>
                    </div>
                    <div className="col-lg-6">
                    <Form.Group controlId="formPatientName">
                        <Form.Label>Patient Name</Form.Label>
                        <Form.Control type="text"  name="name" readOnly placeholder="name" value={this.props.name || ''}  />
                    </Form.Group>
                
                    </div>
               </div>
               <div className="row">
                    <div className="col-lg-6">
                    <Form.Group controlId="formPayment">
                        <Form.Label>Payment</Form.Label>
                        <Form.Control name="payment" as="select" value={this.props.payment} onChange={this.handleChange}>
                            <option value=""></option>
                            {this.state.payments}
                        </Form.Control>
                       
                        <div className="errorMsg">{this.props.errors.payment}</div>
                     </Form.Group>
                    </div>
                    <div className="col-lg-6">
                    {this.props.payment==PAYMENT_METHOD.CreditCard ? (
                        <Form.Group controlId="formCardType">
                        <Form.Label>CardType</Form.Label>
                        <Form.Control name="cardType" as="select" value={this.props.cardType} onChange={this.handleChange} required>
                            <option value=""></option>
                            <option value={CARD_TYPE.LocalDebit}>Local Debit</option>
                            <option value={CARD_TYPE.LocalCredit}>Local Credit</option>
                            <option value={CARD_TYPE.ForeignCard}>Foreign Card</option>
                            <option value={CARD_TYPE.AmericanExpress}>American Express</option>
                            <option value={CARD_TYPE.UnionPay}>Union Pay</option>
                            <option value={CARD_TYPE.JCB}>JCB</option>
                            
                        </Form.Control>
                        </Form.Group>
                    ) : <></>}
                  
                
                    </div>
                    {this.props.loyalty == 1 ? (
                    <div className="col-lg-6">
                    <Form.Group controlId="formPatientName">
                        <Form.Label>Points</Form.Label>
                        <Form.Control type="text"  name="points" readOnly placeholder="points" value={this.props.points }  />
                    </Form.Group>                
                    </div>
                    ) : <></>}
               </div>
            </div>
        );
    }
}