import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import Header from './components/header.component';
import Routes from './routes/routes';
import Footer from './components/footer.component';
import history from "./history";

function App() {
  return (
    <Router history={history}>
      <div className="main">
         <Header></Header>
         <Routes></Routes>
         {/* <Footer></Footer> */}
      </div>
    </Router>
  );
}
export default App;
